<template>
  <div class="wallet">
    <div class="wallet_warp" style="margin-top: 0" ref="wallet_warpBsv">
      <div class="wallet_receive">
        <div class="receive">
          <h4>
            <!-- 你的收款地址 -->
            {{ $t("receivablesAddress") }}
          </h4>
          <img :src="receiveEVM" alt />
          <p>{{ myAddress }}</p>
          <!-- <span class="paymail">
            paymail: {{ $store.state.userData.paymail }}
          </span> -->
          <button @click="copyAdd()">
            <!-- 复制地址 -->
            {{ $t("copyAddress") }}
          </button>

          <!-- <i class="iconfont el-icon-refresh-left" title="使用新地址" @click="NewAddress()"></i> -->
        </div>
        <i
          class="iconfont icon-icon-arrow-left2"
          @click="transitionBoxBsv('-100%')"
        >
          <i
            class="el-icon-arrow-left"
            :style="{ fontSize: '20px', fontWeight: 'bold' }"
          ></i>
        </i>
      </div>
      <div class="wallet_data">
        <div class="wallet_show">
          <div
            class="wallet_text"
            :class="[$store.state.chain === 'BSV' ? 'bsv-bg' : '']"
          >
            <div>
              <h4>
                <!-- {{
                  $utils.getAmount(
                    balance.bsv,
                    $utils.getCurrency() == "CNY" ? 2 : 4
                  )
                }} -->
                --
                {{ $utils.getCurrency() }}
              </h4>
              <p>{{ balance.bsv }} SPACE</p>
            </div>
            <div class="currency">
              <span>MVC</span>
              <s>({{ $store.state.BSVname }})</s>
            </div>
          </div>
        </div>
        <div class="wallet_btn">
          <button @click="transitionBoxBsv(0)">
            <i class="iconfont icon-jia2"> </i>
            <span>
              <!-- 收款 -->
              {{ $t("receivables") }}
            </span>
          </button>
          <button @click="transitionBoxBsv('-200%')">
            <i class="iconfont icon-youjiantou"></i>
            <span>
              <!-- 转账 -->
              {{ $t("Transfer") }}
            </span>
          </button>
        </div>
        <!-- <p>1 SPACE ≈ {{ $utils.getAmount(1, 4) }} {{ $utils.getCurrency() }}</p> -->
      </div>
      <div class="wallet_receive">
        <i class="iconfont icon-you" @click="transitionBoxBsv('-100%')">
          <i
            class="el-icon-arrow-right"
            :style="{ fontSize: '20px', fontWeight: 'bold' }"
          ></i>
        </i>
        <div class="receive">
          <h4>
            <!-- 转账 -->
            {{ $t("Transfer") }}
          </h4>
          <div class="transfer">
            <div class="add" :class="{ addShow: addShow }">
              <input
                type="text"
                v-model="add"
                :placeholder="$t('PleaseAddressBSV')"
                @focus="addShow = true"
                @blur="addShow = false"
              />
            </div>
            <div class="money" :class="{ addShow: moneyShow }">
              <input
                type="number"
                v-enter-number2
                v-model="money"
                min="0.0001"
                :placeholder="$t('TransferAmount')"
                @focus="moneyShow = true"
                @blur="
                  moneyShow = false;
                  setAmount();
                "
              />
              <el-select class="rewardSelect" v-model="rewardSelect">
                <el-option
                  v-for="(item, index) in rewardOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- <span v-if="Number(money)">
              {{ money ? money : 0 }} {{ "SPACE" }}
              {{
                rewardSelect !== "BSV"
                  ? " = " + cutNumberNoZero(amount, 9) + "SPACE"
                  : ""
              }}
              <br v-if="rewardSelect !== 'BSV'" />
              ≈ {{ $utils.getAmount(amount, 4) }} {{ $utils.getCurrency() }}
            </span> -->
            <button
              @click="submitPay()"
              v-loading="submitShow"
              class="transfer_btn"
            >
              <!-- 转账 -->
              {{ $t("Transfer") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('ConfirmTransfer')"
      style="margin-top: 15vh"
      :visible.sync="payShow"
      class="centerDialogVisible"
      center
    >
      <div class="payShow">
        <!-- 登录后才可以进行此操作 -->
        <h4>
          <!-- 金额: -->
          {{ $t("amount") }}:
        </h4>
        <p>
          <span> {{ amount | changeUnit }} </span>
          <!-- <s>
            ≈ {{ $utils.getAmount(amount, 4) }} {{ $utils.getCurrency() }}
          </s> -->
        </p>

        <h4>
          <!-- 转账地址 -->
          {{ $t("transferAddress") }}
        </h4>
        <p>
          {{ add }}
        </p>
        <h4>
          <!-- 矿工费 -->
          {{ $t("minerFee") }}
        </h4>
        <span> {{ fee | changeUnit }} </span>
      </div>
      <div class="queding">
        <button @click="payShow = false">
          <!-- 关闭 -->
          {{ $t("close") }}
        </button>
        <button class="pay" @click="goPay()" v-loading="transactionShow">
          <!-- 确定 -->
          {{ $t("determine") }}
        </button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('Transfer')"
      style="margin-top: 15vh"
      :visible.sync="success"
      class="centerDialogVisible"
      center
    >
      <h2 class="success">
        <!-- 转账成功 -->
        {{ $t("successfulTransfer") }}
      </h2>
      <div
        class="queding"
        @click="
          success = false;
          add = '';
          money = 0;
        "
      >
        <!-- 确定 -->
        {{ $t("determine") }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import store from '@/store'
// import { sendRawTx } from '@/api/api'
import { checkUserToken } from "@/api/api";
import jrQrcode from "jr-qrcode";
import { Decimal } from "decimal.js";
export default {
  data() {
    return {
      userData: "",
      receiveEVM: "",
      myAddress: "",
      // 转账
      add: "",
      addShow: false,
      money: undefined,
      moneyShow: false,
      payShow: false,
      transactionShow: false,
      submitShow: false,
      balance: {
        bsv: 0,
        satoshis: 0,
      },
      moneyRate: "",
      bsvrate: {
        BSV: 1,
        SATS: 100000000,
      },
      fee: 0,
      rewardOptions: [
        {
          value: "BSV",
          label: "SPACE",
        },
        {
          value: "SATS",
          label: "SATS",
        },
      ],
      rewardSelect: "BSV",
      timer: null,
      amount: 0,
      tx: "",
      success: false,
      walletToken: "",
      myTokenList: "",
      BSVtimer: "",
      minMsg: false,
      balanceTimer: null,
    };
  },
  filters: {
    changeUnit(value) {
      value = new Decimal(value).mul(Math.pow(10, 8));
      if (+value >= 1000000 || +value <= -1000000) {
        return ` ${new Decimal(value).div(Math.pow(10, 8))} SPACE`;
      }
      return ` ${value} SATS`;
    },
  },
  async mounted() {
    await this.$utils.getBalance();
    // console.log(this.$store.state.wallet, 45646546)
    this.getEVM();
    // await this.$utils.getBalance();
    this.transitionBoxBsv = function (nub) {
      this.$refs.wallet_warpBsv.style.marginLeft = nub;
    };
    this.transitionBoxBsv2 = function (nub, index) {
      // console.log(this.$refs)
      this.$refs["wallet_warpBsv" + index][0].style.marginLeft = nub;
    };
    this.balanceTimer = setInterval(async () => {
      if (!this.$store.state.isBusying) {
        await this.$utils.getBalance();
      }
    }, 5000);
  },
  methods: {
    async userCheck() {
      const r = await checkUserToken(this.userData.token)
        .then((result) => {
          return true;
        })
        .catch((err) => {
          return false;
        });
      return r;
    },
    async determine() {
      this.balance = this.$utils.getBalance();
      this.success = false;
      this.add = "";
      this.money = 0;
      this.transitionBoxBsv("-100%");
    },
    getfocus(type) {
      this[type] = 2;
    },
    getBlur(type) {
      if (!this[type]) {
        this[type + "Show"] = 3;
      } else {
        this[type + "Show"] = 1;
      }
    },
    setAmount() {
      if (!this.money && this.money !== 0) {
        return;
      }

      if (this.money < parseInt(0.00001 * this.bsvrate[this.rewardSelect])) {
        this.money = 0.00001 * this.bsvrate[this.rewardSelect];
        console.log("money", this.money);
        if (this.rewardSelect === "SATS" && Number.isFinite(this.money)) {
          this.money = Math.floor(this.money);
        }
        // if (this.rewardSelect)
        this.minMsg = true;
        setTimeout(() => {
          this.minMsg = false;
        }, 500);
        if (this.rewardSelect === "BSV") {
          this.$message({
            message: this.$t("unitTips1"),
            type: "warning",
          });
        }

        if (this.rewardSelect === "SATS") {
          this.$message({
            message: this.$t("unitTips3"),
            type: "warning",
          });
        }
      }
    },
    // NewAddress () {
    //   this.myAddress = this.$store.state.wallet.getReceiveAddressNext()
    //   // console.log(this.$store.state.wallet.getReceiveAddress(), 'BSV')
    //   this.receiveEVM = jrQrcode.getQrBase64(this.myAddress)
    // },
    // 获取矿工费并且弹窗
    async submitPay() {
      if (this.minMsg) {
        return;
      }
      if (this.submitShow) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.submitShow = true;
      }
      const authCheckResult = await this.userCheck();
      if (!authCheckResult) {
        this.$message.info(this.$t("loginLostTip"));
        this.submitShow = false;
        this.$store.commit("logout");
        this.$router.replace("login");
        location.reload();
        return;
      }
      // if (typeof this.money === '')
      console.log(this.add);
      if (this.add && this.money) {
        await this.$utils.getBalance();
        if (this.balance.bsv < this.amount) {
          this.submitShow = false;
          return this.$message.error("可转账余额不足");
        }
        let option = {
          to: this.add,
          amount: this.amount,
          currency: "bsv",
          opReturn: process.env.VUE_APP_OPRETURN,
          checkOnly: true,
        };
        console.log(option, "option");
        this.$utils
          .getFee(option)
          .then((res) => {
            console.log(+new Decimal(+res.fee).div(1e8));
            this.fee = new Decimal(res.fee).div(1e8).toFixed();
            this.tx = res.tx;
            this.submitShow = false;
            this.payShow = true;
          })
          .catch((err) => {
            this.submitShow = false;
            // console.log(err)
            return this.$message.error("VisionMoney:" + err);
          });
      } else {
        this.submitShow = false;
        return this.$message.error(this.$t("submitTips"));
      }
    },
    goPay() {
      if (this.transactionShow) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.transactionShow = true;
      }
      let MetanetSDK = this.$store.state.metanet;
      let that = this;
      MetanetSDK.sendTxAuto(that.tx)
        .then(async (res) => {
          console.log(res, "新广播");
          if (res.code === 200) {
            that.transactionShow = false;
            that.tx = "";
            that.payShow = false;
            await that.$utils.getBalance();
            that.success = true;
          } else {
            that.transactionShow = false;
            that.payShow = false;
            that.tx = "";
            return that.$notify.error(this.$t("Error") + res.error);
          }
        })
        .catch((err) => {
          // console.log(err)
          that.transactionShow = false;
          return that.$message.error(
            this.$t("Error") + err.data.message || err.error
          );
        });
      // walletService.send(that.tx.toString('hex')).then(res => {
      //   if (res.code === 0) {
      //     sendRawTx(that.tx.toString('hex'))
      //     that.transactionShow = false
      //     that.tx = ''
      //     that.payShow = false
      //     that.balance = that.$utils.getBalance()
      //     that.success = true
      //   } else {
      //     that.transactionShow = false
      //     that.payShow = false
      //     that.tx = ''
      //     return that.$notify.error(this.$t('Error') + res.error)
      //   }
      // }).catch(err => {
      //   // console.log(err)
      //   that.transactionShow = false
      //   return that.$message.error(this.$t('Error') + err.error)
      // })
    },
    copyAdd() {
      this.$copyText(this.myAddress);
      this.$message({
        type: "success",
        message: this.$t("coypSuccess"),
      });
    },
    // 将收款地址转二维码
    getEVM() {
      this.myAddress = this.$store.state.metanet.rootAddress.address;
      // console.log(this.$store.state.wallet.getReceiveAddress(), 'BSV')
      this.receiveEVM = jrQrcode.getQrBase64(this.myAddress);
    },
  },
  watch: {
    rewardSelect() {
      this.setAmount();
      if (this.money) {
        this.amount = Number(this.money) / this.bsvrate[this.rewardSelect];
      }
    },
    "$store.state.myTokenList"() {
      this.myTokenList = this.$store.state.myTokenList;
    },
    money() {
      this.amount = Number(this.money) / this.bsvrate[this.rewardSelect];
    },
    "$store.state.balance": {
      handler(newVal, oldVal) {
        this.balance = newVal;
        // console.log('rrrrrrrr',this.balance)
      },
      deep: true,
      immediate: true,
    },

    //   // this.getEVM()
    // },
    // balance:{
    //   handler(oldVal,newVal){

    //   }
    // },
    // payShow () {
    //   if (this.tx && !this.payShow) {
    //     let walletService = this.$store.state.wallet
    //     walletService.removeChangeUtxo(this.tx)
    //   }
    // },
    // "$store.state.dealIn": async function () {
    //   // this.setWallet()
    //   this.balance = this.$utils.getBalance();
    //   this.$forceUpdate();
    //   // console.log('有钱进来了')
    // },
    "$store.state.userData": function () {
      this.userData = this.$store.state.userData;
      this.$forceUpdate();
    },
  },
  destroyed() {
    // clearInterval(this.BSVtimer);
    clearInterval(this.balanceTimer);
  },
  async created() {
    this.userData = this.$store.state.userData;
    this.$forceUpdate();
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  max-width: 405px;
  overflow: hidden;
  width: 100%;
  margin-top: 45px;
  border-radius: 23px;
  .wallet_warp {
    margin-top: 20px;
    height: 375px;
    width: 300%;
    display: flex;
    margin-left: -100%;
    transition: 0.3s all ease-in-out;
  }
  .wallet_data {
    padding: 30px;
    display: inline-block;
    max-width: 405px;
    height: 375px;
    width: calc(100% / 3);
    overflow: hidden;
    border-radius: 23px;
    background: rgba(255, 255, 255, 1);
    > p {
      margin-top: 24px;
      font-size: 12px;
      color: #606266;
      text-align: right;
    }
    .wallet_show {
      width: 100%;
      height: 188px;
      background: linear-gradient(
        -30deg,
        rgba(234, 179, 0, 1),
        rgba(234, 198, 0, 1)
      );
      border-radius: 11px;
    }
    .BTC {
      background: linear-gradient(
        -30deg,
        rgba(77, 77, 77, 1),
        rgba(142, 142, 142, 1)
      );
    }
    .BCH {
      background: linear-gradient(
        -30deg,
        rgba(50, 189, 83, 1),
        rgba(45, 184, 111, 1)
      );
    }

    .bsv-bg {
      background: url("https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/ic_BSV%402x.png")
        no-repeat;
    }

    .wallet_text {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;

      background-size: 98px 103px;
      color: #fff;
      text-align: right;
      h4 {
        font-size: 36px;
        line-height: 1;
        font-weight: bold;
      }
      p {
        font-size: 20px;
        font-weight: medium;
      }
      span {
        font-size: 26px;
        font-family: "DIN";
        font-weight: bold;
      }
      .currency {
        text-align: left;
        // justify-content: space-between;
        s {
          font-size: 16px;
          opacity: 0.6;
        }
      }
    }
    .wallet_btn {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .icon-jia2 {
        font-size: 20px;
      }
      button {
        width: 45%;
        font-size: 16px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeff0;
        border-radius: 9px;
        .icon-jia1 {
          font-size: 22px;
        }
        i {
          margin-right: 5px;
        }
        .icon-youjiantou {
          font-size: 22px;
        }
      }
    }
  }
  .wallet_receive {
    max-width: 405px;
    width: calc(100% / 3);
    display: inline-block;
    height: 375px;
    display: inline-flex;
    overflow: hidden;
    border-radius: 23px;
    background: #eab300;
    .receive {
      width: calc(100% - 60px);
      height: 100%;
      padding: 23px;
      background: #fff;
      border-radius: 23px;
      text-align: center;
      h4 {
        font-size: 26px;
      }
      img {
        width: 150px;
        height: 150px;
        margin: 15px 0;
      }
      p {
        font-size: 12px;
      }
      .paymail {
        height: 30px;
        display: block;
        line-height: 30px;
      }
      button {
        width: 120px;
        margin-top: 15px;
        height: 38px;
        background: rgba(234, 179, 0, 1);
        border-radius: 19px;
        color: #fff;
        line-height: 38px;
      }
      .transfer_btn {
        margin-top: 30px;
      }
    }
    i {
      color: #fff;
      line-height: 375px;
      width: 60px;
      text-align: center;
    }
    .el-icon-refresh-left {
      color: #000;
      border: 1px solid #999;
      font-size: 18px;
      height: 30px;
      width: 30px;
      line-height: 27px;
      border-radius: 50%;
      text-align: center;
      margin-left: 10px;
    }
  }
}
.rewardSelect {
  ::v-deep .el-input__inner {
    text-align: right;
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: unse t;
  }
  button {
    font-size: 15px;
    color: #f9f9f9;
    width: 96px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
  .yzmShow {
    background: #eab300;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
@media only screen and (max-width: 768px) {
  .wallet_box {
    padding: 10px;
  }
  .wallet_box .wallet_data {
    padding: 15px;
    border-radius: 15px;
    width: calc(100% / 3);
  }
  .wallet_box .wallet_receive {
    width: calc(100% / 3);
  }
}
.transfer {
  background: #fff;
  padding: 2rem;
  border-radius: 2rem;
  text-align: center;
  span {
    display: block;
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: right;
    color: #909399;
  }
  button {
    min-width: 10.42rem;
    color: #fff;
    line-height: 3.75rem;
    height: 3.75rem;
    margin-top: 4rem;
    background: rgba(234, 179, 0, 1);
    border-radius: 2rem;
  }
  h6 {
    font-size: 1.4rem;
    font-weight: normal;
    color: #909399;
  }
  h4 {
    margin-top: 3.2rem;
    font-size: 3.4rem;
    color: #303133;
  }
}
.add,
.money {
  height: 5.21rem;
  border-bottom: 1px solid #f2f2f2;
  line-height: 5.21rem;
  transition: 0.3s all ease-in-out;
  text-align: left;
  input {
    border: none;
    outline: none;
    // font-size: 1.4rem;
    height: 5rem;
    width: 100%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.money {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.addShow {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #eab300;
}
::v-deep .el-input__inner {
  border: none;
}
::v-deep .el-dialog {
  border-radius: 1rem;
  width: 50%;
  max-width: 450px;
  overflow: hidden;
  .el-dialog__body {
    padding: 0;
  }
}
.centerDialogVisible {
  ::v-deep .el-dialog__header {
    padding-top: 30px;
  }
  ::v-deep .el-dialog__title {
    font-weight: bold;
  }
  .centerDialog {
    padding: 0 2rem;
  }
  .payShow {
    display: block;
    margin: 0 auto;
    color: #303133;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: 0 2rem;
    border-radius: 2.1rem;
    h4 {
      font-size: 1.4rem;
      margin-top: 2rem;
      color: #bfc2cc;
      text-align: left;
      font-weight: normal;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p {
      margin-top: 1rem;
      display: flex;
      text-align: left;
      font-size: 1.3rem;
      justify-content: space-between;
      i {
        color: #bfc2cc;
      }
    }
    > span {
      display: block;
      margin-top: 1rem;
      font-size: 1.3rem;
      text-align: left;
    }
  }
  h2 {
    max-height: 25.5rem;
    padding: 0 2rem;
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: 500;
    overflow-x: auto;
    color: #909399;
  }
  .success {
    color: #303133;
    text-align: center;
  }
  h2::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 10px;
  }
  h2::-webkit-scrollbar-track {
    box-shadow: none;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  h2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
  .checked {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .queding {
    height: 5.2rem;
    line-height: 5.2rem;
    text-align: center;
    color: #303133;
    font-size: 1.7rem;
    margin-top: 3rem;
    font-weight: 500;
    border-top: 1px solid #e9e9e9;
    button {
      width: calc(50% - 1px);
      border-right: 1px solid #e9e9e9;
      line-height: 5.2rem;
      text-align: center;
      background: #fff;
      outline: none;
      color: #909399;
      cursor: pointer;
      font-size: 1.7rem;
      &:nth-last-child(1) {
        border-right: none;
      }
    }
    .pay {
      color: #303133;
      font-weight: bold;
    }
    .blue {
      color: rgb(42, 153, 246);
    }
  }
}
@media (max-width: 768px) {
  .wallet {
    margin: 0 auto;
    margin-top: 45px;
  }
  ::v-deep .el-dialog {
    width: 70%;
  }
}
</style>
