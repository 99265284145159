var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class: {
          item_R: !_vm.itemData.type,
          item_B: _vm.itemData.type,
          item_0: _vm.itemData.tokenShow || _vm.itemData.cochain,
        },
      },
      [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "bsvContainer" }, [
            _c("div", { staticClass: "left" }, [
              _c("h5", [
                _c("i"),
                _c("u", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.itemData.createTime === 0 ? "Unconfirmed" : ""
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _c("p", { staticStyle: { "text-align": "right" } }, [
                _vm._v("\n            --\n            "),
                _c("s", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$utils.getCurrency()) +
                      "\n            "
                  ),
                ]),
              ]),
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm._f("changeUnit")(_vm.itemData.Value)) + " "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "foot" }, [
          _vm.itemData.blockTime
            ? _c("div", { staticClass: "data" }, [
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("data")))]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm
                        .$moment(_vm.itemData.blockTime)
                        .format("YYYY-MM-DD HH:mm:ss")
                    ) +
                    "\n      "
                ),
              ])
            : _vm.itemData.time
            ? _c("div", { staticClass: "data" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("data")))]),
                _vm._v(
                  "\n         \n        " +
                    _vm._s(_vm.itemData.time) +
                    "\n      "
                ),
              ])
            : _c("div", { staticClass: "data" }, [
                _c("span", [_vm._v(" " + _vm._s(_vm.$t("data")))]),
                _vm._v(" " + _vm._s(_vm.$t("unconfirmed")) + "\n      "),
              ]),
          _c("div", { staticClass: "tx" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.txBrowserHost + _vm.itemData.txId,
                  target: "_blank",
                },
              },
              [_vm._v("#tx")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }