<template>
  <div class="wallet">
    <div class="wallet_warp" style="margin-top: 0" ref="wallet_warpBsv">
      <div class="wallet_data">
        <div class="tabWrap">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <div
              class="mySaleNft"
              @click.stop="toMySaleNft"
              v-if="activeName == 'mynft'"
            >
              <span>{{ $t("myNFTonSale") }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
            <el-tab-pane
              :label="ftLabel"
              name="myft"
              v-loading="ftloading"
              class="ftLoading"
            >
              <template v-if="FTlist.length">
                <ul
                  class="coinlist"
                  v-for="(item, index) in FTlist"
                  :key="index"
                >
                  <li @click.stop="openFtDetail(item)">
                    <div class="contain">
                      <img :src="item | ftImgUrl" alt="" />
                      <div class="info">
                        <h5>{{ item.ftName }}</h5>
                        <p>{{ item.ftBalance | toFix }} {{ item.ftSymbol }}</p>
                      </div>
                    </div>
                    <i class="el-icon-arrow-right"></i>
                  </li>
                </ul>
              </template>
              <div class="noRecord" v-else>
                {{ $t("noRecord") }}
              </div>
            </el-tab-pane>
            <el-tab-pane
              :label="nftLabel"
              name="mynft"
              v-loading="nftloading"
              class="nftLoading"
            >
              <!---NFT 列表-->
              <div class="container">
                <template v-if="seriesList.length">
                  <div
                    class="game"
                    v-for="(series, index) of seriesList"
                    :key="index"
                  >
                    <div class="title" @click.stop="toDetail(series)">
                      <div class="left">
                        <!-- <img :src="val.metaId | metaidUrl" alt="" /> -->
                        <div>{{ series.nftSeriesName }}</div>
                      </div>
                      <div class="right">
                        <!-- <span>{{ val.amount }}</span> -->
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                    <div class="card">
                      <div
                        class="cardWrap"
                        v-for="(nftItem, index) in series.nftDetailItemList"
                        :key="index"
                      >
                        <img
                          :src="nftItem.nftIcon | nftImgUrl"
                          alt=""
                          @click.stop="openNftDetail(nftItem)"
                        />
                        <div class="gameName">{{ nftItem.nftName }}</div>
                        <div
                          v-if="nftItem.nftHasCompound"
                          class="compound-status"
                        >
                          {{ $t("hasCompound") }}
                        </div>
                        <!-- <div class="gameName">
                        {{ `数量*${item.nftBalance}` }}
                      </div> -->
                      </div>
                    </div>
                    <!-- <div class="splitLine"></div> -->
                  </div>
                </template>
                <div class="noRecord" v-else>
                  {{ $t("noRecord") }}
                </div>
              </div>
            </el-tab-pane>

            <!-- <el-tab-pane
                :label="mySaleNftLabel"
                name="mySaleNft"
                v-loading="mySaleNftLoading"
                class="mySaleNftLoading"
              >
              <div class="saleContainer">
                <div class="card" v-if='saleNftList.length'>
                  <div
                    class="cardWrap"
                    v-for="(item, index) in saleNftList"
                    :key="index"
                  >
                    <img
                      :src="item.nftIcon | nftImgUrl"
                      alt=""
                      @click.stop="openSaleNftDetail(item)"
                    />
                    <div class="gameName">{{ item.nftName }}</div>
                  </div>
                </div>
                  <div class="noRecord" v-else>
                    {{ $t("noRecord") }}
                  </div>
                </div>
              </el-tab-pane> -->
          </el-tabs>
        </div>
      </div>
      <!--v-if="currentnftlist.length > 0-->
      <div class="nftDetailWrap" v-if="currentNFTListShow" :key="i">
        <div class="detailTop">
          <div class="leftIcon">
            <i class="el-icon-arrow-left" @click.stop="toHome"></i>
          </div>
          <div class="content">
            <!-- <img :src="currentMetaid | metaidUrl" alt="" /> -->
            <span>{{ currentSeries.nftSeriesName }}</span>
            <!-- <span>{{ currentnftlist.length }}</span> -->
          </div>
        </div>
        <div class="blank"></div>
        <div class="nftDetailListWrap">
          <div
            class="detailContainer"
            @scroll="debounce(loadingMoreNft, 400, false)"
            ref="detailContainer"
          >
            <div class="detailItem" v-for="(v, i) of currentnftlist" :key="i">
              <img
                :src="v.nftIcon | nftImgUrl"
                alt=""
                @click.stop="openNftDetail(v)"
              />
              <span>{{ v.nftName }}</span>
              <div v-if="v.nftHasCompound" class="compound-status">
                {{ $t("hasCompound") }}
              </div>
            </div>
          </div>
          <!-- <div class="noData" v-if='nftListNoData'>{{$t("nftNoData")}}</div> -->
        </div>
      </div>
      <div class="saleNftWrap" v-else-if="saleNftShow">
        <div class="detailTop">
          <div class="leftIcon">
            <i class="el-icon-arrow-left" @click.stop="toHome"></i>
          </div>
          <div class="title">
            {{ $t("myNFTonSale") }}
          </div>
        </div>
        <div class="saleContainer">
          <div class="card" v-if="saleNftList.length">
            <div
              class="cardWrap"
              v-for="(item, index) in saleNftList"
              :key="index"
            >
              <img
                :src="item.nftIcon | nftImgUrl"
                alt=""
                @click.stop="openSaleNftDetail(item)"
              />
              <div class="gameName">{{ item.nftName }}</div>
              <div v-if="item.nftHasCompound" class="compound-status">
                {{ $t("hasCompound") }}
              </div>
            </div>
          </div>
          <div class="noRecord" v-else>
            {{ $t("noRecord") }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="detailShow"
      class="coininfo"
      width="90vw"
      :show-close="isShowClose"
      center
      :append-to-body="true"
    >
      <div v-if="activeName === 'mynft' && !toMySaleNftDetail" class="isMyNft">
        <div class="nftContentWrap" v-if="nftDetailShow === 'detail'">
          <div class="nftContenTop">
            <img
              :src="nftDetialItem.nftIcon | nftImgUrl"
              @click="imgShow = true"
              alt=""
            />
            <span class="cardName">{{ CurNftItem.nftName }}</span>
          </div>
          <div class="nftContentCenter">
            <div class="nftContentItem">
              <span class="left">{{ $t("nftName") }}</span
              ><span class="right">{{ CurNftItem.nftName }}</span>
            </div>
            <div class="nftContentItem" v-if="isArticle">
              <span class="left">{{ $t("artilDetail") }}</span
              ><span
                class="right blue cursorP"
                @click="goArticleDetail(CurNftItem)"
                >{{ $t("clickDetail") }}</span
              >
            </div>
            <div class="nftContentItem">
              <span class="left">{{ $t("issuer") }}</span>
              <div class="right">
                <img
                  :src="CurNftItem.nftIssueAvatarTxId | nftIssuerAvatar"
                  alt=""
                /><span>{{ CurNftItem.nftIssuer }}</span>
              </div>
            </div>
            <div class="nftContentItem">
              <span class="left">{{ $t("releaseTime") }}</span
              ><span class="right">{{
                CurNftItem.nftTimestamp | dateStr("YYYY-MM-DD")
              }}</span>
            </div>
            <div class="nftContentItem">
              <span class="left">{{ $t("curReleaseAmount") }}</span
              ><span class="right">{{ CurNftItem.nftTotalSupply }}</span>
            </div>
            <!-- <div class="nftContentItem">
              <span class="left">获取渠道</span
              ><span class="right">游戏《荒岛》中成就奖励</span>
            </div> -->
          </div>
          <div class="nftContenfooter">
            <div class="confirmBtn" @click.stop="nftPayFor">
              {{ $t("nftTransfer") }}
            </div>
            <div class="confirmBtn sellbtn" @click.stop="nftSell">
              {{ $t("nftSell") }}
            </div>
          </div>
        </div>
        <div v-else-if="nftDetailShow === 'transition'" class="transitionWrap">
          <div class="title">
            <i
              class="el-icon-arrow-left fallback"
              @click.stop="nftDetailShow = 'detail'"
            ></i>
            <span>{{ $t("nftTransfer") }}</span>
            <i class="el-icon-close" @click.stop="detailShow = false"></i>
          </div>
          <div class="nftContent" v-if="CurNftItem">
            <div class="payAcount">
              <div>{{ $t("otherAccount") }}</div>
              <input
                type="text"
                v-model="payNftAdress"
                :placeholder="transferAddress"
                @input="validate"
              />
              <div class="tips" v-if="tipsAddress">{{ $t("tipsAddress") }}</div>
              <div class="tips" v-if="tipsInput">{{ $t("tipsInput") }}</div>
            </div>
            <div class="nftName">
              <div>{{ $t("passCard") }}</div>
              <div>
                <img :src="CurNftItem.nftIcon | nftImgUrl" alt="" /><span>{{
                  CurNftItem.nftName
                }}</span>
              </div>
            </div>
            <div class="nftTotal">
              <div class="top">{{ $t("tokenAmount") }}</div>
              <div class="foot">
                <input
                  type="text"
                  v-model="payNftVal"
                  @keyup="return (payNftVal = 1);"
                />
              </div>
            </div>
          </div>
          <div class="payBtn">
            <el-button
              :disabled="payToNftDisabled"
              :class="[payToNftDisabled ? 'confirmBtn' : 'allowConfirmBtn']"
              :loading="nftSendLoding"
              @click.stop="confirmSendNft"
            >
              {{ $t("confirmTransfer") }}
            </el-button>
          </div>
        </div>
        <div v-else class="transitionWrap">
          <div class="title">
            <i
              class="el-icon-arrow-left fallback"
              @click.stop="nftDetailShow = 'detail'"
            ></i>
            <span>{{ $t("sales") }}</span>
            <i class="el-icon-close" @click.stop="detailShow = false"></i>
          </div>
          <div class="nftContent" v-if="CurNftItem">
            <div class="nftName">
              <div class="saleTitle">{{ $t("saleDetail") }}</div>
              <van-field
                v-model="salesIntro"
                rows="2"
                autosize
                type="textarea"
                maxlength="4000"
                :placeholder="$t('nftDesc')"
                show-word-limit
              />
              <div class="saleDate">
                <van-field
                  readonly
                  :value="$utils.dataFormat(saleTime)"
                  :placeholder="setShowTime"
                  :right-icon="rightIcon"
                  @click-right-icon="calenderShow = !calenderShow"
                />
              </div>
              <div class="datePicker">
                <van-datetime-picker
                  v-if="calenderShow"
                  v-model="currentDate"
                  type="datetime"
                  :title="cancelSaleTime"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="confirmDate"
                  @cancel="cancelChoose"
                  :columns-order="['year', 'month', 'day', 'hour', 'minute']"
                  :formatter="formatter"
                />
              </div>
              <!-- <el-date-picker
                  v-if='calenderShow'
                    class="el-datetime"
                  
                    type="datetime"
                    :editable="false"
                    :clearable="false"
                    :picker-options="pickerOptions"
                    >
                  </el-date-picker> -->
            </div>
            <div class="nftTotal">
              <!-- <div class="top">{{$t("tokenAmount")}}</div> -->
              <div class="foot">
                <el-input
                  :placeholder="pricePH"
                  v-model.trim="nftSellPrice"
                  class="sellPrice"
                  @blur="validatePrice()"
                  @keyup.native="
                    nftSellPrice = nftSellPrice.replace(/[^\d.]/g, '')
                  "
                >
                  <el-dropdown
                    trigger="click"
                    slot="append"
                    @command="handleCommand"
                  >
                    <span class="el-dropdown-link">
                      {{ units[unitIndex].unit }}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="(unit, index) in units"
                        :key="index"
                        :command="index"
                        >{{ unit.unit }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-input>
              </div>
            </div>
          </div>
          <div class="payBtn">
            <el-button
              :disabled="saleBtnDisabled"
              :class="[saleBtnDisabled ? 'confirmBtn' : 'allowConfirmBtn']"
              :loading="nftSendLoding"
              @click.stop="confirmSellNft(true)"
            >
              {{ $t("nftSell") }}
            </el-button>
          </div>
        </div>
      </div>
      <div v-else-if="toMySaleNftDetail" class="isMySaleNft">
        <div class="nftSaleContentWrap">
          <div class="nftSaleContenTop">
            <img
              :src="currentSaleNftItem.nftIcon | nftImgUrl"
              @click="imgShow = true"
              alt=""
            />
            <span class="cardName">{{ currentSaleNftItem.nftName }}</span>
          </div>
          <div class="nftSaleContentCenter">
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("nftName") }}</span
              ><span class="right">{{ currentSaleNftItem.nftName }}</span>
            </div>
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("tokenDesc") }}</span
              ><span class="rightBtn" @click.stop="nftDescShow = true">{{
                $t("clickDetail")
              }}</span>
            </div>
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("tokenIssuer") }}</span>
              <div class="right">
                <img
                  :src="currentSaleNftItem.nftIssueMetaId | nftIssuerAvatar"
                  alt=""
                /><span>{{ currentSaleNftItem.nftIssuer }}</span>
              </div>
            </div>
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("tokenIssuer") }}MetaID</span
              ><span class="right">{{
                currentSaleNftItem.nftIssueMetaId.slice(0, 6)
              }}</span>
            </div>
            <div class="nftSaleContentItem" v-if="isSaleArticle">
              <span class="left">{{ $t("artilDetail") }}</span
              ><span
                class="right blue cursorP"
                @click="goArticleDetail(currentSaleNftItem)"
                >{{ $t("clickDetail") }}</span
              >
            </div>
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("issuerTime") }}</span
              ><span class="right">{{
                currentSaleNftItem.nftIssueTimestamp | dateStr("YYYY-MM-DD")
              }}</span>
            </div>
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("issuerAmount") }}</span
              ><span class="right">{{ currentSaleNftItem.nftBalance }}</span>
            </div>
            <div class="nftSaleContentItem">
              <span class="left">{{ $t("issueSeries") }}</span
              ><span class="right">{{
                currentSaleNftItem.nftTotalSupply
              }}</span>
            </div>
          </div>
          <div class="nftSaleContenfooter">
            <el-button
              :disabled="cancelNftLoading"
              :class="[cancelNftLoading ? 'confirmBtn' : 'allowConfirmBtn']"
              :loading="cancelNftLoading"
              @click.stop="nftCancel(true)"
            >
              {{ $t("cancelNft") }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="isMyFt" v-else>
        <div class="ftContentWrap" v-if="ftDetailShow">
          <div class="ftContenTop">
            <img :src="CurFtItem | ftImgUrl" alt="" />
            <span class="ftType">{{ CurFtItem.ftName }}</span>
          </div>
          <div class="ftAcountDetail">
            <div class="top">
              <span>{{ CurFtItem.ftBalance | toFix }}</span>
            </div>
            <span class="bottom"
              >{{ $t("me") }}{{ CurFtItem.ftName + $t("number")
              }}{{ CurFtItem.ftBalance | toFix }}{{ CurFtItem.ftSymbol }}</span
            >
          </div>
          <div class="ftContentCenter">
            <div class="ftContentItem">
              <span class="left">{{ $t("ftName") }}</span
              ><span class="right">{{ CurFtItem.ftName }}</span>
            </div>
            <div class="ftContentItem">
              <span class="left">{{ $t("issuer") }}</span>
              <div class="right">
                <img :src="CurFtItem | ftImgUrl" alt="" /><span>{{
                  CurFtItem.ftIssuer
                }}</span>
              </div>
            </div>
            <div class="ftContentItem">
              <span class="left">{{ $t("releaseTime") }}</span
              ><span class="right">{{
                CurFtItem.ftTimestamp | dateStr("YYYY-MM-DD")
              }}</span>
            </div>
            <div class="ftContentItem">
              <span class="left">{{ $t("curReleaseAmount") }}</span
              ><span class="right">{{
                `${CurFtItem.ftTotalSupplyStr} ${CurFtItem.ftSymbol}`
              }}</span>
            </div>
            <div class="ftContentItem">
              <span class="left">{{ $t("releasAddress") }}</span
              ><span class="right"
                ><a
                  :href="CurFtItem.ftGenesisTxId | toDetailUrl"
                  target="_blank"
                  >{{ $t("clickDetail") }}</a
                ></span
              >
            </div>
            <!-- <div class="ftContentItem">
              <span class="left">获取渠道</span
              ><span class="right">创建 MetaNetTX</span>
            </div>
            <div class="ftContentItem">
              <span class="left">估值</span
              ><span class="right">1 MC = 0.1 USD</span>
            </div> -->
          </div>
          <div class="ftContenfooter">
            <div class="confirmBtn" @click.stop="ftPayFor">
              {{ $t("ftTransfer") }}
            </div>
          </div>
        </div>
        <div v-else class="transitionWrap">
          <div class="title">{{ $t("ftTransfer") }}</div>
          <div class="ftContent" v-if="CurFtItem">
            <div class="payAcount">
              <div>{{ $t("otherAccount") }}</div>
              <input
                type="text"
                v-model="payFtAdress"
                :placeholder="transferAddress"
                required
                @input="validate"
              />
              <div class="tips" v-if="tipsAddress">{{ $t("tipsAddress") }}</div>
              <div class="tips" v-if="tipsInput">{{ $t("tipsInput") }}</div>
            </div>
            <div class="ftName">
              <div>{{ $t("passCard") }}</div>
              <div>
                <img :src="CurFtItem | ftImgUrl" alt="" /><span>{{
                  CurFtItem.ftName
                }}</span>
              </div>
            </div>
            <div class="ftTotal">
              <div class="top">{{ $t("tokenAmount") }}</div>
              <div class="foot">
                <input
                  type="text"
                  v-model="payFtVal"
                  required
                  :placeholder="`${$t(
                    'curHave'
                  )} ${$options.filters.ThousandSeparator(
                    CurFtItem.ftBalance
                  )} ${CurFtItem.ftSymbol}`"
                  @keyup="payFtVal = payFtVal.replace(/[^\d.]/g, '')"
                  @input="validateNull"
                />
                <div class="tipsNum" v-if="tipsNum">{{ $t("tipsNum") }}</div>
              </div>
            </div>
            <p class="error" v-if="transferFtNeedTime > 2">
              <!-- {{ $t("timetips") }} {{ transferFtNeedTime }} s -->
              FT UTXO 太多，转账前建议先<a href="/tools/merge-utxo"
                >合并 UTXO</a
              >
            </p>
          </div>
          <div class="payBtn">
            <el-button
              :disabled="payToFtDisabled"
              :class="[payToFtDisabled ? 'confirmBtn' : 'allowConfirmBtn']"
              :loading="ftSendLoding"
              @click.stop="confirmSendFt"
            >
              {{ $t("confirmTransfer") }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="imgShow"
      class="imgpreview"
      center
      :append-to-body="true"
      :show-close="false"
    >
      <img
        v-if="toMySaleNftDetail"
        :src="currentSaleNftItem.nftIcon | nftImgUrl"
        alt=""
      />
      <img
        v-else
        @click="imgShow = false"
        :src="nftDetialItem.nftIcon | nftImgUrl"
        alt=""
      />
    </el-dialog>
    <el-dialog
      :visible.sync="nftDescShow"
      class="nftDescWrap"
      center
      :append-to-body="true"
      :show-close="false"
    >
      <div class="descContent">
        <span>{{ currentSaleNftItem.nftName }}</span>
        <span>{{ currentSaleNftItem.nftDesc }}</span>
        <span @click.stop="nftDescShow = false">{{ $t("determine") }}</span>
      </div>
    </el-dialog>
    <!-- <van-dialog v-model="confirmeDialog" :title='confirmeTitle'
      :message="confirmeMessage"
     show-cancel-button
     overlay
     lazyRender
     allowHtml
     @confirm='confirmePay'
     @cancel='cancelPay'
    
     >
      
    </van-dialog> -->
    <!-- <van-dialog @cancel='confirmePay(false)' @confirm='confirmePay(true)'  v-model="confirmePayDialog" title="标题" :message='dialogMsg' show-cancel-button>
     overlay:true,
              lazyRender:true,
              allowHtml:true,
    </van-dialog> -->
  </div>
</template>

<script>
import {
  getNftInfo,
  setDeadlineTime,
  newGetNftSeriresList,
  newGetFtTokenList,
  getMySaleNft,
  getNFTSeriresDetailList,
  getMVCNftSeriesList,
} from "@/api/api";
import moment from "moment";
import Decimal from "decimal.js";
import { beforeClose } from "@/common/js/confirmePay.js";
import { mapState } from "vuex";
import { avatarUrl, imageUrl } from "../../common/js/baseApi";
// import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      mySaleNftList: [],
      seriesList: [],
      currentSeries: null,
      nftListNoData: false,
      lastFlag: "",
      nftPage: 2,
      mySaleNftPage: 1,
      currentNftBase: [],
      nftPageLoading: false,
      currentNFTListShow: false,
      tokenPage: "1",
      tokenPageSize: "30",
      confirmeDialog: false,
      isConfirmePay: "",
      confirmePayDialog: false,
      calenderShow: false,
      minDate: new Date(),
      rightIcon: require("../../assets/images/list_icon_calendar@2x.png"),
      currentDate: new Date(),
      salesIntro: "",
      nftDetialItem: {},
      activeName: "myft",
      detailShow: false,
      nftDetailShow: "detail",
      saleTime: "",
      ftDetailShow: false,
      // ftAcount: 900004562.45466,
      payFtVal: "",
      payNftVal: "1",
      payNftAdress: "",
      payFtAdress: "",
      // ftlist: [],
      // nftlist: {},
      currentnftlist: [],
      currentSaleNftItem: {},
      currentName: "",
      currentMetaid: "",
      transferFtNeedTime: 0,
      imgShow: false,
      nftDescShow: false,
      mynft: null,
      myft: null,
      mySaleNft: null,
      currentItem: null,
      ftSendLoding: false,
      nftSendLoding: false,
      cancelNftLoading: false,
      tipsAddress: false,
      tipsNum: false,
      nftloading: true,
      ftloading: false,
      mySaleNftLoading: false,
      toMySaleNftDetail: false,
      tipsInput: false,
      unitIndex: 0,
      nftSellPrice: "",
      saleNftShow: false,
      nftinitstate: false,
      nftinitstatemessage: "",
      ftinitstate: false,
      ftinitstatemessage: "",
      ftTransferTimer: "",
      pickerOptions: {
        disabledDate(time) {
          const now = new Date().getTime() + 1000 * 60 * 30;
          return new Date(time).getTime() < now;
        },
      },
      units: [
        {
          unit: "SPACE",
          sats: Math.pow(10, 8),
        },
        {
          unit: "SATS",
          sats: Math.pow(10, 0),
        },
      ],
    };
  },
  filters: {
    tofixZero(val) {
      return parseInt(val).toFixed(0);
    },
    toFix(item) {
      return parseFloat(item);
    },
    ThousandSeparator(input) {
      return (
        input &&
        input
          .toString()
          .replace(/(^|\s)\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
      );
    },
    dateStr(value, format = "YYYY-MM-DD HH:mm:ss") {
      if (value === "--") {
        return value;
      } else {
        return moment(+value).format(format);
      }
    },
    toDetailUrl(tx) {
      return `${process.env.VUE_APP_MVCSCANURL}/tx/${tx}`;
    },
    ftImgUrl(ftItem) {
      const iconUrl = ftItem.ftIconUrl;
      const ftIcon = ftItem.ftIcon;
      switch (iconUrl) {
        case "mc":
          return require("../../assets/images/tokenImg/mc.png");
        case "boex":
          return require("../../assets/images/tokenImg/boex.jpeg");
        case "ovts":
          return require("../../assets/images/tokenImg/ovts.png");
        case "tsc":
          return require("../../assets/images/tokenImg/tsc.png");
        case "usdt":
          return require("../../assets/images/tokenImg/usdt.png");
        case "asc":
          return require("../../assets/images/tokenImg/asc.png");
        case "bart":
          return require("../../assets/images/tokenImg/bart.jpg");
        case "cc":
          return require("../../assets/images/tokenImg/cc.jpg");
        case "ceo":
          return require("../../assets/images/tokenImg/ceo.jpg");
        case "whst":
          return require("../../assets/images/tokenImg/whst.jpg");
        default:
      }
      if (
        ftIcon?.indexOf("metafile://") > -1 &&
        ftIcon?.indexOf("metafile://undefined") !== 1 &&
        ftIcon
      ) {
        return imageUrl() + ftIcon?.substr(11);
      } else {
        return require("../../assets/images/icon_ft_default.png");
      }
    },
    nftIssuerAvatar(avatarTxId) {
      if (avatarTxId) {
        return `${avatarUrl()}${avatarTxId}`;
      } else if (avatarTxId?.indexOf("metafile://") > -1) {
        return avatarUrl() + avatarTxId?.substr(11);
      } else {
        return require("../../assets/images/icon_nft_default.png");
      }
    },
    nftImgUrl(tx) {
      if (
        tx?.indexOf("metafile://") > -1 &&
        tx?.indexOf("metafile://undefined") !== 1 &&
        tx
      ) {
        return imageUrl() + tx?.substr(11);
      } else {
        return require("../../assets/images/icon_nft_default.png");
      }
    },
    metaidUrl(tx) {
      return avatarUrl() + tx;
    },
  },

  computed: {
    ...mapState(["userData", "saleNftList"]),
    // ...mapGetters(['getMc']),
    // saleAmountChange:{

    //   set(newVal){
    //     const min = 0.00001
    //      if (new Decimal(newVal).toNumber() <= min) {
    //      this.nftSellPrice=(newVal*min).toString()
    //   }
    //   }
    // },
    // confirmeMessage(){
    //   return `${this.$t("curPayFee")}<span style="color:#FD4426">${this.result.amount}<span>SATS`
    // },
    // confirmeTitle(){
    //    return `${this.$t("confirmeUpChain")}`
    // },
    isArticle() {
      if (this.currentItem?.nftDataStr) {
        try {
          const r = JSON.parse(this.currentItem.nftDataStr);
          if (r.classifyList) {
            if (
              r.classifyList.indexOf("rights") > -1 &&
              r.classifyList.indexOf("article") > -1
            ) {
              return true;
            }
          }
        } catch (err) {
          return false;
        }
      }
      return false;
    },
    isSaleArticle() {
      if (this.currentSaleNftItem?.nftDataStr) {
        try {
          const r = JSON.parse(this.currentSaleNftItem.nftDataStr);
          if (r.classifyList) {
            if (
              r.classifyList.indexOf("rights") > -1 &&
              r.classifyList.indexOf("article") > -1
            ) {
              return true;
            }
          }
        } catch (err) {
          return false;
        }
      }
      return false;
    },
    setShowTime() {
      return this.$t("setShowTime");
    },
    pricePH() {
      return this.$t("setPrice");
    },
    cancelSaleTime() {
      return this.$t("cancelSaleTime");
    },
    maxDate() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      return new Date(year, month, day);
    },
    isShowClose() {
      if (this.activeName === "mynft" && this.nftDetailShow !== "detail") {
        return false;
      } else return true;
    },
    price() {
      return new Decimal(Math.pow(10, 8))
        .div(this.units[this.unitIndex].sats)
        .mul(0.00001);
    },
    setDisabledDate(time) {
      const now = new Date().getTime() + 1000 * 60 * 30;
      return new Date(time).getTime() < now;
    },
    ftLabel() {
      return `${this.$t("myFt")}`;
    },
    nftLabel() {
      return `${this.$t("myNft")}`;
    },
    mySaleNftLabel() {
      return `${this.$t("mySaleNft")}`;
    },
    transferAddress() {
      return `${this.$t("transferTokenAddress")}`;
    },
    FTlist() {
      return this.$store.state.ftList;
    },
    NFTlist() {
      return this.$store.state.nftList;
    },
    saleNftList() {
      return this.$store.state.saleNftList;
    },
    payToFtDisabled() {
      if (
        this.payFtAdress &&
        this.payFtVal &&
        this.tipsAddress !== true &&
        this.tipsNum !== true &&
        this.tipsInput !== true
      ) {
        return false;
      } else {
        return true;
      }
    },
    saleBtnDisabled() {
      if (this.saleTime && this.nftSellPrice && this.salesIntro) {
        return false;
      } else {
        return true;
      }
    },
    payToNftDisabled() {
      if (
        this.payNftAdress &&
        this.tipsAddress !== true &&
        this.tipsInput !== true
      ) {
        return false;
      } else {
        return true;
      }
    },
    CurNftItem() {
      return this.$store.state.curNftItem;
    },
    CurFtItem() {
      return this.$store.state.curFtItem;
    },
  },
  watch: {
    detailShow: {
      handler(val) {
        if (!val) {
          this.payFtAdress = "";
          this.payFtVal = "";
          this.payNftAdress = "";
          this.tipsAddress = false;
          this.tipsNum = false;
          this.tipsInput = false;
          this.saleTime = "";
          this.calenderShow = false;
          this.salesIntro = "";
          this.nftSellPrice = "";
          this.nftloading = false;
          this.nftSendLoding = false;
          this.cancelNftLoading = false;
        }
      },
    },
    // ftloading: {
    //   handler(val) {
    //     console.log("ftloading", val);
    //   }
    // },
    // nftloading: {
    //   handler(val) {
    //     console.log("nftloading", val);
    //   }
    // }
    // activeName:{
    //   handler(){
    //     this.ftloading=true,
    //     this.nftloading=true
    //   }
    // }
  },
  methods: {
    // getMyNFTonSale(){
    //    if(this.nftListNoData){
    //    return
    //   }
    //   this.nftPageLoading=true
    //   let scrollTop=this.$refs.detailContainer.scrollTop
    //   let scrollHeight=this.$refs.detailContainer.scrollHeight
    //   let clientHeight=this.$refs.detailContainer.clientHeight
    //   let page=++this.mySaleNftPage
    //    if(scrollHeight-scrollTop==clientHeight){
    //     let tempNftList= this.getSaleNft(page)
    //     if(tempNftList.length){
    //      this.nftPageLoading=false
    //     //  this.currentnftlist=[...this.currentnftlist,...tempNftList]
    //     }else{
    //       this.nftListNoData=true
    //       this.nftPageLoading=false
    //     }

    //   }
    // },
    goArticleDetail(target) {
      const articleTx = JSON.parse(target.nftDataStr).contentTxId;
      window.open(
        `${process.env.VUE_APP_SHOWBUZZURL}/details/${articleTx}`,
        "_blank"
      );
    },
    async loadingMoreNft() {
      if (this.nftListNoData) {
        return;
      }
      this.nftPageLoading = true;
      let scrollTop = this.$refs.detailContainer.scrollTop;
      let scrollHeight = this.$refs.detailContainer.scrollHeight;
      let clientHeight = this.$refs.detailContainer.clientHeight;

      if (scrollHeight - scrollTop == clientHeight) {
        let tempNftList = await this.getAllSeriresNftDetail(
          this.currentSeries,
          this.lastFlag
        ).catch((e) => (this.nftPageLoading = false));
        if (tempNftList.length) {
          this.nftPage++;
          this.nftPageLoading = false;
          this.currentnftlist = [...this.currentnftlist, ...tempNftList];
        } else {
          this.nftListNoData = true;
          this.nftPageLoading = false;
        }
      }
    },
    //  async confirmePay(value){
    //    return new Promise(resovle=>{
    //      resovle(value)
    //    })
    //   },
    // cancelPay(){
    //   this.nftloading=false
    // },
    // confirmePay(){
    //     this.isConfirmePay='confirm'
    // },
    toMySaleNft() {
      this.currentNFTListShow = false;
      this.currentnftlist = [];
      this.$refs.wallet_warpBsv.style.marginLeft = "-100%";
      this.saleNftShow = true;
      this.getSaleNft();
      // this.currentnftlist = this.saleNftList;
      // this.currentName = name;
      // this.currentMetaid = metaId;
    },
    validatePrice() {
      if (
        +this.nftSellPrice < 1000 &&
        this.units[this.unitIndex].unit === "SATS"
      ) {
        this.nftSellPrice = "1000";
        this.$message.info(`${this.$t("inputSatLim")}`);
        return;
      } else if (
        +this.nftSellPrice < 0.00001 &&
        this.units[this.unitIndex].unit === "SPACE"
      ) {
        this.nftSellPrice = "0.00001";
        this.$message.info(`${this.$t("inputBSVLim")}`);
        return;
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },

    cancelChoose() {
      this.calenderShow = false;
      this.saleTime = "";
    },
    confirmDate(value) {
      console.log("zasasas", value);
      this.saleTime = value;

      this.calenderShow = false;
    },
    validateNull(e) {
      let {
        target: { value },
      } = e;
      if (value <= 0) {
        this.tipsNum = true;
      } else {
        this.tipsNum = false;
      }
    },
    validate(e) {
      let {
        target: { value },
      } = e;
      const paymailReg =
        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      const metaIdAddress = /^[A-Za-z0-9]+$/;
      let paymailInput = paymailReg.test(value);
      let metaIdInput = metaIdAddress.test(value);
      if (paymailInput === false && metaIdInput === false) {
        this.tipsInput = true;
      } else {
        this.tipsAddress = false;
        this.tipsInput = false;
      }
      // if(value===this.$store.state.metanet.rootAddress.address || value===this.$store.state.userData.paymail){
      //   this.tipsAddress=true
      // }else if(paymailInput===false && metaIdInput===false ){
      //  this.tipsInput=true
      // }else {
      //    this.tipsAddress=false
      //    this.tipsInput=false
      // }
    },
    async handleClick(tab, event) {
      this.currentItem = null;
      if (tab.index === "0" && !this.myft) {
        // this.getFTList();
      }
      if (tab.index === "1" && !this.mynft) {
        this.getNFTList();
      }
    },
    async initData() {
      if (this.activeName === "myft") {
        // this.getFTList();
      }
      if (this.activeName === "mynft") {
        this.getNFTList();
      }
    },
    async toDetail(item) {
      this.currentSeries = item;
      this.currentNFTListShow = true;
      this.lastFlag = "";
      this.$refs.wallet_warpBsv.style.marginLeft = "-100%";
      console.log(item);
      this.currentnftlist = await this.getAllSeriresNftDetail(item);
      console.log("currentnftlist", this.currentnftlist);
    },
    async getAllSeriresNftDetail(series, flag = this.lastFlag) {
      // let nftDetailList = [];
      return new Promise(async (resolve, reject) => {
        // item.map((ele) => {
        //   let tempStr = `${ele.nftCodehash}/${ele.nftGenesis}`;
        //   nftDetailList.push(tempStr);
        // });
        // console.log("zxzxasadsdaszx", nftDetailList);
        const result = await getNFTSeriresDetailList({
          flag: flag,
          pageSize: "10",
          address: this.$store.state.metanet.rootAddress.address,
          codehash: series.nftCodehash,
          genesis: series.nftGenesis,
          // CodehashGenesisList: nftDetailList,
        });
        if (result.data.code == 0) {
          const items = result.data.data.results.items;
          const lastItem = items[items.length - 1];
          this.lastFlag = lastItem.flag;
          resolve(result.data.data.results.items);
        } else {
          resolve([]);
        }
      });
    },
    toHome() {
      this.nftPage = 2;
      this.saleNftShow = false;
      this.nftListNoData = false;
      this.toMySaleNftDetail = false;
      this.$refs.wallet_warpBsv.style.marginLeft = "0";
    },
    openSaleNftDetail(item) {
      this.currentSaleNftItem = item;
      this.toMySaleNftDetail = true;
      this.detailShow = true;
    },
    openNftDetail(item) {
      this.nftDetialItem = item;
      this.nftDetailShow = "detail";
      this.currentItem = item;
      this.$store.commit("getCurNftItem", item);
      this.detailShow = true;
      this.$store.state.metanet
        .initNFTInfo({
          tokenIndex: this.currentItem.nftTokenIndex,
          codehash: this.currentItem.nftCodehash,
          genesisId: this.currentItem.nftGenesis,
          genesisTxid: this.currentItem.nftGenesisTxId,
        })
        .then(() => {
          this.nftinitstate = true;
        })
        .catch((err) => {
          this.nftinitstate = false;
          this.nftinitstatemessage = err.data?.message || err;
        });
    },
    openFtDetail(item) {
      console.log("esass", item);
      this.transferFtNeedTime = 0;
      this.ftDetailShow = true;
      this.currentItem = item;
      this.$store.commit("getCurFtItem", item);
      this.detailShow = true;
      this.$store.state.metanet
        .initFtInfo({
          //这里参数顺序不能改变
          genesisId: this.currentItem.ftGenesis,
          sensibleId: this.currentItem.ftSensibleId,
          codehash: this.currentItem.ftCodehash,
        })
        .then((res) => {
          console.log("init ft info", res);
          if (res.mergeNumber > 1) {
            this.transferFtNeedTime = res.mergeNumber * 50;
          }
          this.ftinitstate = true;
        })
        .catch((err) => {
          this.ftinitstate = false;
          this.ftSendLoding = false;
          this.$message.error(err);
          console.log("初始化ft失败", err);
          clearInterval(this.ftTransferTimer);
        });
      console.log("eason123", this.CurFtItem);
    },
    nftPayFor() {
      this.nftDetailShow = "transition";
    },
    nftSell() {
      // if(+this.getMc < ( +process.env.VUE_APP_MCLIMITED)){
      //   this.$message.error(`${this.$通证转账 ('mcLimited')}`)
      //   return
      // }
      this.nftDetailShow = "";
    },
    ftPayFor() {
      this.ftDetailShow = false;
    },
    //本地更新 nftList 时调用删除的方法
    deleteItem(obj, item) {
      for (let i in obj) {
        obj[i].info.map((ele, index) => {
          if (
            ele.nftIssuer === item.nftIssuer &&
            ele.nftGenesis === item.nftGenesis &&
            ele.nftTokenIndex === item.nftTokenIndex
          ) {
            obj[i].info.splice(index, 1);
          }
        });
        obj[i].base.map((ele, index) => {
          if (
            ele.nftIssuer === item.nftIssuer &&
            ele.nftGenesis === item.nftGenesis &&
            ele.nftTokenIndex === item.nftTokenIndex
          ) {
            obj[i].base.splice(index, 1);
          }
        });
        if (!obj[i].info.length || !obj[i].base.length) {
          delete obj[i];
        }
      }
      return obj;
    },
    updataFtList(ftList, curItem) {
      ftList.map((item) => {
        if (item == curItem) {
          item.ftBalance -= Number(this.payFtVal);
        }
      });
      return ftList;
    },
    async confirmSendFt() {
      if (this.payFtVal > +this.currentItem.ftBalance) {
        this.$message.error(this.$t("overAccount"));
        return;
      }
      try {
        this.ftSendLoding = true;
        this.$store.state.isBusying = true;
        let result;
        if (!this.ftinitstate) {
          this.ftTransferTimer = setInterval(async () => {
            if (this.ftinitstate) {
              clearInterval(this.ftTransferTimer);
              result = await this.$store.state.metanet.confirmTransterFT({
                //这里参数顺序不能改变
                genesisId: this.currentItem.ftGenesis,
                sensibleId: this.currentItem.ftSensibleId,
                codehash: this.currentItem.ftCodehash,
                receiverAddress: [
                  {
                    address: this.payFtAdress,
                    amount: BigInt(
                      (this.payFtVal *
                        Math.pow(10, +this.CurFtItem.ftDecimalNum + 1)) /
                        10
                    ).toString(),
                  },
                ],
              });
              if (result.txId) {
                this.$store.commit("getFtList", {
                  updataResult: this.updataFtList(
                    this.$store.state.ftList,
                    this.CurFtItem
                  ),
                  isInit: false,
                });
                this.detailShow = false;
                this.$message.success("转账成功");
                // this.initData()
              } else {
                this.$message.info(result);
              }
              this.$store.state.isBusying = false;
              this.ftSendLoding = false;
            }
          }, 2000);
        } else {
          result = await this.$store.state.metanet.confirmTransterFT({
            //这里参数顺序不能改变
            genesisId: this.currentItem.ftGenesis,
            sensibleId: this.currentItem.ftSensibleId,
            codehash: this.currentItem.ftCodehash,
            receiverAddress: [
              {
                address: this.payFtAdress,
                amount: BigInt(
                  (this.payFtVal *
                    Math.pow(10, +this.CurFtItem.ftDecimalNum + 1)) /
                    10
                ).toString(),
              },
            ],
          });
          if (result.txId) {
            this.$store.commit("getFtList", {
              updataResult: this.updataFtList(
                this.$store.state.ftList,
                this.CurFtItem
              ),
              isInit: false,
            });
            this.detailShow = false;
            this.$message.success("转账成功");
            // this.initData()
          } else {
            this.$message.info(result);
          }
          this.$store.state.isBusying = false;
          this.ftSendLoding = false;
        }
      } catch (err) {
        this.$store.state.isBusying = false;
        this.ftSendLoding = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${
              JSON.stringify(err.resData.body).message
            },如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        if (err.message) {
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        } else {
          return this.$message.error(`错误:${err},如有疑问请联系客服`);
        }
      }
    },
    async confirmSendNft() {
      let result;
      try {
        this.nftSendLoding = true;

        // console.log( this.currentItem)

        if (!this.nftinitstate) {
          if (this.nftinitstatemessage) {
            this.nftSendLoding = false;
            this.$message.error(this.nftinitstatemessage);
            this.nftinitstatemessage = "";
            return;
          }
          let i = 0;
          while (true) {
            if (i == 10) {
              this.nftSendLoding = false;
              this.$store.state.metanet
                .initNFTInfo({
                  tokenIndex: this.currentItem.nftTokenIndex,
                  codehash: this.currentItem.nftCodehash,
                  genesisId: this.currentItem.nftGenesis,
                  genesisTxid: this.currentItem.nftGenesisTxId,
                })
                .then(() => {
                  this.nftinitstate = true;
                })
                .catch((err) => {
                  this.nftinitstate = false;
                  this.nftinitstatemessage = err.data?.message || err;
                  console.log(err);
                });
              return this.$message.error(this.$t("neterr"));
            }
            if (this.nftinitstate) {
              break;
            } else {
              await this.$utils.sleep(1000);
              i++;
            }
          }
        }
        this.nftinitstatemessage = "";
        //     await this.$store.state.metanet.initNFTInfo({
        // metaidTag: 'metaid',
        // tokenIndex: this.currentItem.nftTokenIndex,
        // codehash: this.currentItem.nftCodehash,
        //       genesisId: this.currentItem.nftGenesis,
        //       genesisTxid: this.currentItem.nftGenesisTxId,
        // useFeeb = 1,
        //     });
        result = await this.$store.state.metanet.confirmTransferNFT({
          tokenIndex: this.currentItem.nftTokenIndex,
          receiverAddress: this.payNftAdress,
          codehash: this.currentItem.nftCodehash,
          genesisId: this.currentItem.nftGenesis,
          genesisTxid: this.currentItem.nftGenesisTxId,
        });
      } catch (err) {
        console.log(123);
        console.trace(err);
        this.nftSendLoding = false;
        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        if (err.message) {
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        } else {
          return this.$message.error(`错误:${err},如有疑问请联系客服`);
        }
      }

      if (result.txId) {
        //这里
        this.currentnftlist = this.currentnftlist.filter((ele) => {
          return (
            ele.nftCodehash + ele.nftGenesis + ele.nftTokenIndex !=
            this.currentItem.nftCodehash +
              this.currentItem.nftGenesis +
              this.currentItem.nftTokenIndex
          );
        });
        this.$store.commit("getNftList", {
          updataCache: this.deleteItem(
            this.$store.state.nftList,
            this.currentItem
          ),
          isInit: false,
        });
        this.detailShow = false;
        this.$message.success("转账成功");
        // this.initData()
      } else {
        this.$message.info(result);
      }
      this.nftSendLoding = false;
    },
    //下架 NFT
    async nftCancel(checkOnly = true) {
      let result;
      try {
        this.cancelNftLoading = true;
        result = await this.$store.state.metanet.nftCancel({
          codehash: this.currentSaleNftItem.nftCodehash,
          genesis: this.currentSaleNftItem.nftGenesis,
          tokenIndex: this.currentSaleNftItem.nftTokenIndex,
          genesisTxid: this.currentSaleNftItem.nftGenesisTxId,
          sellContractTxId: this.currentSaleNftItem.nftSellContractTxId,
          sellTxId: this.currentSaleNftItem.nftSellTxId,
          checkOnly,
          payTo: [
            {
              address: "19NeJJM6eEa3bruYnqkTA4Cp6VvdFGSepd",
              amount: 10000,
            },
          ],
        });
        console.log("asdasdasd", result);
        if (result.amount) {
          await this.$dialog
            .confirm({
              overlay: true,
              allowHtml: true,
              getContainer: () => {
                return document.querySelector(".coininfo");
              },
              lazyRender: false,
              title: `${this.$t("confirmeUpChain")}`,
              message: `${this.$t("curPayFee")}<span style="color:#FD4426">${
                result.amount
              }<span>SATS`,
              beforeClose,
            })
            .then((res) => {
              this.isConfirmePay = res;
              this.nftCancel(false);
            })
            .catch((e) => {
              console.log("zxczxczxc", e);
              this.cancelNftLoading = false;
            });
        }

        // console.log("xzczxaewweawasda", response);
        if (this.isConfirmePay === "confirm" && result && result.txid) {
          if (!checkOnly) {
            this.detailShow = false;
            this.$message.success(`${this.$t("cancelSucc")}`);
            this.$store.dispatch("setSaleNftList", {
              updataResult: this.saleNftList.filter((item) => {
                return (
                  item.nftSellContractTxId !==
                  this.currentSaleNftItem.nftSellContractTxId
                );
              }),
              isInit: false,
            });
          }

          // const response = await setDeadlineTime({
          //   codeHash: this.currentItem.nftDetailItemList[0].nftCodehash,
          //   genesis: this.currentItem.nftDetailItemList[0].nftGenesis,
          //   tokenIndex: this.currentItem.nftDetailItemList[0].nftTokenIndex,
          //   deadlineTime: new Date(this.saleTime).getTime()
          // });

          // console.log("xzczxaewweawasda", response);
          // this.initData()
        }
      } catch (err) {
        this.cancelNftLoading = false;

        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        if (err.message) {
          return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        } else {
          return this.$message.error(`错误:${err},如有疑问请联系客服`);
        }
      }
    },
    //上架 NFT
    async confirmSellNft(checkOnly = true) {
      let result;
      const stasPrice =
        this.units[this.unitIndex].unit === "SPACE"
          ? new Decimal(this.nftSellPrice).mul(10 ** 8).toNumber()
          : new Decimal(this.nftSellPrice).toNumber();
      try {
        this.nftSendLoding = true;
        console.log("22asazxczadsas", this.currentItem);

        result = await this.$store.state.metanet.nftSell({
          // codehash: this.currentItem.nftDetailItemList[0].nftCodehash,
          // genesis: this.currentItem.nftDetailItemList[0].nftGenesis,
          // tokenIndex: this.currentItem.nftDetailItemList[0].nftTokenIndex,
          // satoshisPrice: stasPrice,
          // genesisTxid: this.currentItem.nftDetailItemList[0].nftGenesisTxId,
          // sensibleId: this.currentItem.nftDetailItemList[0].nftSensibleId,
          codehash: this.currentItem.nftCodehash,
          genesis: this.currentItem.nftGenesis,
          tokenIndex: this.currentItem.nftTokenIndex,
          satoshisPrice: stasPrice,
          genesisTxid: this.currentItem.nftGenesisTxId,
          sensibleId: this.currentItem.nftSensibleId,
          sellDesc: this.salesIntro,
          checkOnly,
          payTo: [
            {
              address: "19NeJJM6eEa3bruYnqkTA4Cp6VvdFGSepd",
              amount: 10000,
            },
          ],
        });
        console.log("asdasdasd", result);
        if (result.amount) {
          await this.$dialog
            .confirm({
              overlay: true,
              allowHtml: true,
              getContainer: () => {
                return document.querySelector(".coininfo");
              },
              lazyRender: false,
              title: `${this.$t("confirmeUpChain")}`,
              message: `${this.$t("curPayFee")}<span style="color:#FD4426">${
                result.amount
              }<span>SATS`,
              beforeClose,
            })
            .then((res) => {
              this.isConfirmePay = res;
              this.confirmSellNft(false);
            })
            .catch((e) => {
              console.log("zxczxczxc", e);
              this.nftSendLoding = false;
            });
        }

        // console.log("xzczxaewweawasda", response);

        if (this.isConfirmePay === "confirm" && result && result.txid) {
          if (!checkOnly) {
            this.detailShow = false;
            this.$message.success(`${this.$t("saleSucc")}`);
            this.currentnftlist = this.currentnftlist.filter((ele) => {
              return (
                ele.nftCodehash + ele.nftGenesis + ele.nftTokenIndex !=
                this.currentItem.nftCodehash +
                  this.currentItem.nftGenesis +
                  this.currentItem.nftTokenIndex
              );
            });
            this.$store.commit("getNftList", {
              updataCache: this.deleteItem(
                this.$store.state.nftList,
                this.currentItem
              ),
              isInit: false,
            });
          }

          const response = await setDeadlineTime({
            // codeHash: this.currentItem.nftDetailItemList[0].nftCodehash,
            // genesis: this.currentItem.nftDetailItemList[0].nftGenesis,
            // tokenIndex: this.currentItem.nftDetailItemList[0].nftTokenIndex,
            codeHash: this.currentItem.nftCodehash,
            genesis: this.currentItem.nftGenesis,
            tokenIndex: this.currentItem.nftTokenIndex,
            deadlineTime: new Date(this.saleTime).getTime(),
          });

          console.log("xzczxaewweawasda", response);
          // this.initData()
        }
      } catch (err) {
        console.log("zxzxzxzx", err);
        this.nftSendLoding = false;

        if (err?.resData) {
          return this.$message.error(
            `错误:${JSON.parse(err.resData.body).message},如有疑问请联系客服`
          );
        }
        if (err?.data) {
          return this.$message.error(
            `错误:${err.data.message},如有疑问请联系客服`
          );
        }
        if (err.message) {
          // return this.$message.error(`错误:${err.message},如有疑问请联系客服`);
        } else {
          return this.$message.error(`错误:${err},如有疑问请联系客服`);
        }
      }
    },
    //聚合获取 FT 列表
    async getFTList() {
      this.ftloading = true;
      let results = [];
      setTimeout(async () => {
        let result = await newGetFtTokenList({
          page: this.tokenPage,
          pageSize: this.tokenPageSize,
          address: this.$store.state.metanet.rootAddress.address,
        });
        if (result.data.code == 0) {
          this.ftloading = false;
          results = result.data.data.results.items;
          results.map((item, index) => {
            item.ftIconUrl = this.$utils.tokenFilters(item.ftGenesis);
          });
          console.log("zxczxczxczxc", results);
          this.$store.dispatch("setFtList", {
            result: results,
            isInit: true,
          });
        } else {
          this.ftloading = false;
          return [];
        }
      }, 500);
    },
    //聚合获取我已上架 NFT
    getSaleNft() {
      this.mySaleNftLoading = true;
      let results = [];
      setTimeout(async () => {
        let result = await getMySaleNft({
          metaId: this.userData.metaId,
          address: this.$store.state.metanet.rootAddress.address,
          page: this.tokenPage,
          // pageSize:this.tokenPageSize,
          pageSize: "99999",
          timestamp: 0,
        });

        if (result.data.code == 0) {
          console.log("zxzxa222wwa", result);
          this.mySaleNftLoading = false;
          const { items } = result.data.data.results;
          if (items.length) {
            results = items;
            // this.mySaleNftList=[...this.mySaleNftList,...items]
            results = results.map((item) => {
              return this.replaceItem(item);
            });
            this.$store.dispatch("setSaleNftList", {
              result: results,
              isInit: true,
            });
            console.log("saleNftList", this.$store.state.saleNftList);
          }
        } else {
          this.mySaleNftLoading = false;
          return [];
        }
      }, 500);
    },
    async getNFTList() {
      let seriesList = [];
      const seriresListRes = await getMVCNftSeriesList({
        // page: this.tokenPage,
        // pageSize:this.tokenPageSize,
        pageSize: "999999",
        address: this.$store.state.metanet.rootAddress.address,
        isDetail: true,
        itemCount: 3,
        chain: "mvc",
      });
      if (seriresListRes.data.code == 0) {
        seriesList = seriresListRes.data.data.results.items;
      }
      this.seriesList = seriesList;
    },
    //聚合获取 NFT 列表
    async getNFTListUser() {
      this.nftloading = true;
      let result = await newGetNftSeriresList({
        // page: this.tokenPage,
        // pageSize:this.tokenPageSize,
        pageSize: "999999",
        address: this.$store.state.metanet.rootAddress.address,
        isDetail: true,
        itemCount: 3,
      });
      if (result.data.code == 0) {
        let results = result.data.data.results.items;
        this.nftloading = false;
        results = results.map((item) => {
          return this.replaceItem(item);
        });
        console.log("getNFTList", results);
        let cache = {};
        results.forEach(async (item) => {
          if (!cache[item.nftIssuer]) {
            cache[item.nftIssuer] = {};
            cache[item.nftIssuer].base = [];
            cache[item.nftIssuer].info = [];
            cache[item.nftIssuer].amount = 0;
            cache[item.nftIssuer].metaId = item.nftIssueMetaId;
            cache[item.nftIssuer].avatarTxId = item.nftIssueAvatarTxId;
          }
          let nftMyPendingCount =
            parseInt(item.nftMyPendingCount) >= 0
              ? parseInt(item.nftMyPendingCount)
              : 0;
          let nftMyCount =
            parseInt(item.nftMyCount) >= 0 ? parseInt(item.nftMyCount) : 0;
          cache[item.nftIssuer].amount += nftMyCount + nftMyPendingCount;

          //测试

          cache[item.nftIssuer].base.push(item);
          console.log("zxa222sasasa", cache[item.nftIssuer].base);
          if (cache[item.nftIssuer].info.length < 3) {
            cache[item.nftIssuer].info.push(...item.nftDetailItemList);
            // cache[item.nftIssuer].info=item.nftDetailItemList;
          }
        });
        console.log("zxzxczx", cache);
        // this.nftlist = cache;
        this.$store.dispatch("setNftList", {
          cache: cache,
          isInit: true,
        });
        console.log("nftList111", this.$store.state.nftList);
      } else {
        this.nftloading = false;
        return [];
      }
    },
    // async getNFTList() {
    //   this.nftloading = true;
    //   let result = await this.$store.state.metanet.nftList(
    //     this.$store.state.metanet.rootAddress.address
    //   );
    //   if (result.length) {
    //     this.nftloading = false;
    //   } else {
    //     this.nftloading = false;
    //   }
    //   result = result.map(item => {
    //     return this.replaceItem(item);
    //   });
    //   console.log("getNFTList", result);
    //   let cache = {};
    //   result.forEach(async item => {
    //     if (!cache[item.nftIssuer]) {
    //       cache[item.nftIssuer] = {};
    //       cache[item.nftIssuer].base = [];
    //       cache[item.nftIssuer].info = [];
    //       await getNftInfo({
    //         data: JSON.stringify({ nftGenesisTxId: item.nftGenesisTxid })
    //       }).then(res => {
    //         cache[item.nftIssuer].metaId = res.data.result.metaId;
    //         cache[item.nftIssuer].avatarTxId = res.data.result.avatarTxId;
    //       });
    //     }
    //     cache[item.nftIssuer].base.push(item);
    //     if (cache[item.nftIssuer].info.length < 3) {
    //       cache[item.nftIssuer].info.push(item);
    //     }
    //   });
    //   // this.nftlist = cache;
    //   this.$store.dispatch("setNftList", {
    //     cache: cache,
    //     isInit: true
    //   });
    //   console.log("nftList", this.$store.state.nftList);
    // },

    // async getFTList() {
    //   // let result = await this.$store.state.metanet.ftList(
    //   //   this.$store.state.metanet.rootAddress.address
    //   // );
    //   this.ftloading = true;
    //   let result = [];
    //   setTimeout(() => {
    //     this.$store.state.metanet
    //       .ftList(this.$store.state.metanet.rootAddress.address)
    //       .then(res => {
    //         this.ftloading = false;
    //         result = res;
    //         console.log("FtMgr", result);
    //         //   result=result.map(item=>{
    //         //   return this.replaceItem()
    //         // })

    //         // this.ftlist = result;
    //         this.$store.dispatch("setFtList", {
    //           result: result,
    //           isInit: true
    //         });
    //         //获取 MC 总额
    //         // result.map(ele=>{
    //         //  if(ele.ftSensibleId === '3e04f81d7fa7d4d606c3c4c8e8d3a8dcf58b5808740d40a445f3884e126bc7fd00000000'){
    //         //    this.$store.commit("getMcAmount",ele.ftBalance)
    //         //  }
    //         // })
    //       })
    //       .catch(err => {
    //         console.log("eeeeeee", err);
    //         this.ftloading = false;
    //         return result;
    //       });
    //   }, 500);

    //   // console.log("zzzzzzz",result)
    //   //  if(JSON.stringify(result)!=="[]"){
    //   //   this.ftloading=false
    //   // }else{
    //   //   setTimeout(()=>{
    //   //     this.ftloading=false
    //   //   },1000)
    //   // }

    //   // result=result.map(item=>{
    //   //   return this.replaceItem(item)
    //   // })
    //   // console.log("FtMgr", result);
    //   // // this.ftlist = result;
    //   //  this.$store.commit('getFtList',{
    //   //    result:result,
    //   //    isInit:true
    //   //  })
    // },

    handleCommand(index) {
      console.log(index);
      if (this.unitIndex === index) return;
      if (this.nftSellPrice !== "") {
        const oldSats = this.units[this.unitIndex].sats;
        const newSats = this.units[index].sats;
        this.nftSellPrice = new Decimal(oldSats)
          .div(newSats)
          .mul(this.nftSellPrice)
          .toString();
      }
      this.unitIndex = index;
    },
    replaceItem(item) {
      for (let i in item) {
        if (item[i] === "" || 0) {
          item[i] = "--";
        }
      }
      return item;
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__append {
  border: none;
  // background-color: transparent;
  padding: 0;
  padding-right: 1.142857rem /* 16/14 */;
}
::v-deep .el-datetime {
  .el-input__prefix {
    display: none !important;
  }
}
::v-deep .el-date-editor {
  width: auto !important;
}
::v-deep .el-datetime .el-input__inner {
  border: none;
  outline: none;
  padding-left: 0;
  padding-right: 0;
  &::placeholder {
    color: #bfc2cc;
  }
}
.sellPrice {
  ::v-deep .el-input__inner {
    padding-left: 0;
  }
}
.nftDescWrap {
  ::v-deep.el-dialog {
    border-radius: 1rem /* 12/12 */;
  }
  .descContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 1.333333rem /* 16/12 */;
      padding: 0.833333rem /* 10/12 */;
    }
    span:nth-child(3) {
      color: #157bee;
      cursor: pointer;
    }
  }
}
.error {
  font-size: 12px;
  color: red;
  a {
    color: #157bee;
    cursor: pointer;
  }
}
.imgpreview {
  display: flex;
  max-width: 100%;
  background-color: transparent;
  ::v-deep .el-dialog {
    background-color: transparent;
    width: unset;
    margin-top: 15vh;
    height: 70vh;
    border-radius: unset;
    box-shadow: unset;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body {
    height: 100%;
    padding: 0;
    justify-content: center;
    text-align: center;
    img {
      max-width: 100%;
      height: 100%;
    }
  }
}
.wallet {
  max-width: 405px;
  overflow: hidden;
  width: 100%;
  margin-top: 45px;
  border-radius: 23px;

  .wallet_warp {
    margin-top: 20px;
    min-height: 375px;
    width: 300%;
    display: flex;
    // margin-left: -100%;
    transition: 0.3s all ease-in-out;
    background-color: #fff;
  }
  .wallet_data {
    padding: 30px;
    display: inline-block;
    max-height: 49.166667rem /* 590/12 */;
    min-height: 49.166667rem /* 590/12 */;
    // max-width: 405px;
    // max-height: 375px;
    width: calc(100% / 3);

    // overflow: hidden;
    border-radius: 23px;
    background: rgba(255, 255, 255, 1);
    .tabWrap {
      .mySaleNft {
        width: 95%;
        text-align: center;
        background-color: #eeeff0;
        // border: 1px solid #eeeff0;
        display: flex;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        padding: 0.583333rem /* 7/12 */ 0.666667rem /* 8/12 */;

        // padding-bottom: 1.083333rem /* 13/12 */;
        span {
          font-size: 18px;
          color: #303133;
        }
      }
      // ::v-deep .el-tabs__nav{
      //   display: flex;
      //   justify-content: space-between;
      // }
      // ::v-deep .el-tabs__item{
      //   padding: 0 !important;
      // }
      .ftLoading {
        ::v-deep .el-loading-mask {
          .el-loading-spinner {
            top: 100px;
          }
        }
      }
      .nftLoading {
        ::v-deep .el-loading-mask {
          .el-loading-spinner {
            top: 100px;
          }
        }
      }
      .noRecord {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #dcdfe6;
      }
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #606266;
        border-radius: 10px;
        display: block;
      }
      &::-webkit-scrollbar-track {
        box-shadow: block;
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
        background-color: #aaa;
      }
    }
  }
  .saleNftWrap {
    padding: 30px;
    display: inline-block;
    max-width: 405px;

    width: calc(100% / 3);
    border-radius: 23px;
    background: rgba(255, 255, 255, 1);
    .detailTop {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .leftIcon {
        cursor: pointer;
        i {
          font-size: 23px;
        }
      }
      .title {
        margin: 0 auto;
        font-size: 1.5rem /* 18/12 */;
      }
    }
    .saleContainer {
      margin-top: 2.5rem /* 30/12 */;
      .card {
        margin-left: 0.25rem /* 3/12 */;
        margin-top: 9px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        .cardWrap {
          min-height: 10.75rem /* 129/12 */;
          max-height: 16.666667rem /* 200/12 */;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          margin-right: 0.714286rem /* 10/14 */;
          img {
            margin: auto;
            border-radius: 6px;
            width: 102px;
            object-fit: contain;
          }
          .compound-status {
            background: #157bee;
            text-align: center;
            line-height: 24px;
            color: #fff;
            position: absolute;
            left: 0;
            bottom: 24px;
            right: 0;
          }

          .gameName {
            margin-top: 9px;
            color: #909399;
            text-align: center;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .noRecord {
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #dcdfe6;
      }
    }
  }
  .nftDetailWrap {
    padding: 2.5rem /* 30/12 */ 2.5rem /* 30/12 */;
    display: inline-block;
    max-width: 405px;
    position: relative;
    box-sizing: border-box;
    width: calc(100% / 3);
    border-radius: 23px;
    background: rgba(255, 255, 255, 1);

    .detailTop {
      display: flex;
      align-items: center;
      .leftIcon {
        flex: 1;
        i {
          font-size: 23px;
        }
      }
      .content {
        flex: 10;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 28px;
          height: 28px;
          border-radius: 28px;
          display: inline-block;

          margin-right: 9px;
        }
        span {
          font-size: 18px;
          color: #303133;
        }
      }
    }
    .blank {
      z-index: 9999;
      width: 100%;
      height: 1.666667rem /* 20/12 */;
    }
    .nftDetailListWrap {
      .noData {
        padding-top: 1.25rem /* 15/12 */;
        color: #c1c2c7;
        font-size: 1.333333rem /* 16/12 */;
        text-align: center;
      }
    }

    .detailContainer {
      min-height: 40rem /* 480/12 */;
      max-height: 40rem /* 480/12 */;

      overflow-y: scroll;
      // display: flex;
      // padding-top: 20px;
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
      grid-gap: 20px 0;
      // grid-template-rows:  33.3% 33.3% 33.3%;

      // flex-wrap: wrap;
      // justify-content: flex-start;
      // align-items: center;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #606266;
        border-radius: 10px;
        display: block;
      }
      &::-webkit-scrollbar-track {
        box-shadow: block;
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
        background-color: #aaa;
      }
      .detailItem {
        // margin-top: 20px;
        // margin-right: 12px;
        max-height: 16.666667rem /* 200/12 */;
        min-height: 10.75rem /* 129/12 */;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .nftAcount {
          color: #5173b9;
          margin-top: 3px;
        }
        img {
          margin: auto;
          display: block;
          width: 102px;
          object-fit: fill;
          border-radius: 6px;
        }
        span {
          display: block;
          text-align: center;
          font-size: 12px;
          color: #909399;
          line-height: 1em;
          margin-top: 9px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
        }
        .compound-status {
          background: #157bee;
          color: #fff;
          position: absolute;
          margin: 0 5px;
          height: 24px;
          line-height: 24px;
          left: 0;
          bottom: 24px;
          right: 0;
          z-index: 10;
          text-align: center;
        }
      }
    }
  }
}
::v-deep .el-dialog__header {
  padding-top: 0px;
  .el-dialog__headerbtn {
    top: 2.357143rem /* 33/14 */;
    i {
      color: #909399;
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}
.blue {
  color: #157bee !important;
}
.cursorP {
  cursor: pointer;
}
.coinlist {
  li {
    display: flex;
    justify-content: space-between;
    padding: 13px 13px 13px 0;
    .contain {
      display: flex;
    }
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: #ccc;
      overflow: hidden;
      align-self: center;
    }
    .info {
      display: flex;
      margin-left: 13px;
      flex-direction: column;
      justify-content: center;
      h5 {
        font-size: 17px;
      }
      p {
        font-size: 15px;
        color: rgba(81, 115, 185, 1);
      }
    }
    i {
      align-self: center;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 0.833333rem /* 10/12 */;
  .game {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }
        > div {
          font-size: 16px;
          margin-left: 5px;
          overflow: hiden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .right {
        color: #909399;
        padding-right: 8px;
        span {
          font-size: 16px;
        }
        i {
          font-size: 12px;
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    .card {
      margin-top: 9px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      cursor: pointer;

      .cardWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 0.714286rem /* 10/14 */;
        min-height: 10.75rem /* 129/12 */;
        max-height: 17.666667rem /* 200/12 */;
        position: relative;
        img {
          border-radius: 6px;
          width: 102px;
          object-fit: contain;
          margin: auto;
        }
        .gameName {
          margin-top: 9px;
          color: #909399;
          text-align: center;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .compound-status {
          background: #157bee;
          color: #fff;
          position: absolute;
          width: 100%;
          height: 24px;
          line-height: 24px;
          left: 0;
          bottom: 24px;
          right: 0;
          z-index: 10;
          text-align: center;
        }
      }
    }
    .splitLine {
      height: 1px;
      border: 1px solid #f7fafc;
      margin-top: 15px;
    }
  }
}

.coininfo {
  ::v-deep .el-dialog {
    width: 90%;
    max-width: 405px;
    border-radius: 23px;
    ::v-deep .el-dialog__header {
      padding-top: 0px;
    }
    .isMySaleNft {
      .nftSaleContentWrap {
        display: flex;
        flex-direction: column;
        .nftSaleContenTop {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 1.785714rem /* 25/14 */;
          img {
            display: block;
            width: 90px;
            object-fit: contain;
          }
          .cardName {
            display: block;
            margin-top: 15px;
            color: #0d111a;
            font-size: 15px;
          }
          .cardCount {
            display: block;
            margin-top: 5px;
            color: #5173b9;
            font-size: 12px;
          }
        }
        .nftSaleContentCenter {
          margin-top: 23px;
          display: flex;
          flex-direction: column;
          .nftSaleContentItem {
            margin-bottom: 11px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
              display: block;
              color: #909399;
              font-size: 13px;
            }
            .rightBtn {
              color: #157bee;
              cursor: pointer;
            }
            .right {
              display: flex;
              flex-direction: row;
              color: #303133;
              font-size: 12px;
              align-items: center;
              img {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                margin-right: 8px;
                span {
                  display: block;
                }
              }
            }
          }
        }
        .nftSaleContenfooter {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          .confirmBtn {
            width: 105px;
            height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            opacity: 0.5;
            filter: alpha(opacity=50);
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
          }
          .allowConfirmBtn {
            width: 105px;
            height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            opacity: 1;
            filter: alpha(opacity=100);
            -moz-opacity: 1;
            -khtml-opacity: 1;
          }
        }
      }
    }
    .isMyNft {
      .nftContentWrap {
        display: flex;
        flex-direction: column;
        .nftContenTop {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 1.785714rem /* 25/14 */;
          img {
            display: block;
            width: 90px;
            object-fit: contain;
          }
          .cardName {
            display: block;
            margin-top: 15px;
            color: #0d111a;
            font-size: 15px;
          }
          .cardCount {
            display: block;
            margin-top: 5px;
            color: #5173b9;
            font-size: 12px;
          }
        }
        .nftContentCenter {
          margin-top: 23px;
          display: flex;
          flex-direction: column;
          .nftContentItem {
            margin-bottom: 11px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
              display: block;
              color: #909399;
              font-size: 13px;
            }
            .right {
              display: flex;
              flex-direction: row;
              color: #303133;
              font-size: 12px;
              align-items: center;
              img {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                margin-right: 8px;
                span {
                  display: block;
                }
              }
            }
          }
        }
        .nftContenfooter {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          .confirmBtn {
            width: 105px;
            height: 41px;
            line-height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
          .sellbtn {
            margin-left: 20px;
          }
        }
      }
      .transitionWrap {
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: #303133;
          font-size: 18px;
          .fallback {
            font-size: 1.428571rem /* 20/14 */;
          }
        }
        .nftContent {
          margin-top: 29px;
          .payAcount {
            div {
              font-size: 12px;
              color: #606366;
              margin-bottom: 8px;
            }
            input {
              width: 100%;
              height: 41px;
              line-height: 41px;
              border: none;
              border-bottom: 1px solid #edeff2;
              font-size: 12px;
              &:focus {
                outline: none;
              }
            }
            .tips {
              color: red;
            }
          }
          .nftName {
            margin-top: 23px;
            .saleTitle {
              padding: 0.714286rem /* 10/14 */ 0;
            }
            .van-cell {
              background-color: #f5f7f9;
              font-size: 1.142857rem /* 16/14 */;
              line-height: 1rem /* 14/14 */;

              .van-field__body .van-field__control {
                max-height: 7.142857rem /* 100/14 */;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.5em;
              }

              .van-field__word-limit {
                display: flex;
                justify-content: flex-end;
              }
            }
            .saleDate {
              margin-top: 1.428571rem /* 20/14 */;
            }
            .datePicker {
              overflow: hidden;
              .van-picker-column {
                height: 100%;
              }
            }
            div:nth-of-type(1) {
              font-size: 1rem /* 14/14 */;
              color: #606366;
            }
            div:nth-of-type(2) {
              // padding-bottom: 5px;
              // display: flex;
              // margin-top: 8px;
              // flex-direction: row;
              // align-items: center;
              // border-bottom: 1px solid #edeff2;
              img {
                display: block;
                width: 27px;
                height: 27px;
                margin-right: 8px;
              }
              span {
                display: block;
                color: #303133;
              }
            }
          }
          .nftTotal {
            margin-top: 23px;
            .top {
              font-size: 12px;
              color: #606366;
            }
            .foot {
              margin-top: 8px;
              padding-bottom: 5px;
              color: #303133;
              input {
                width: 100%;
                height: 41px;
                line-height: 41px;
                border: none;
                background-color: #f5f7f9;
                padding-left: 0.714286rem /* 10/14 */;
                // color: #F5F7F9;
                // border-bottom: 1px solid #edeff2;
                font-size: 1rem /* 14/14 */;
                &:focus {
                  outline: none;
                }
                &::-webkit-input-placeholder {
                  color: #bfc2cc !important;
                }
                &::-moz-input-placeholder {
                  color: #bfc2cc;
                }
              }
            }
          }
        }
        .payBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 33px;
          .confirmBtn {
            width: 105px;
            height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            opacity: 0.5;
            filter: alpha(opacity=50);
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
          }
          .allowConfirmBtn {
            width: 105px;
            height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            opacity: 1;
            filter: alpha(opacity=100);
            -moz-opacity: 1;
            -khtml-opacity: 1;
          }
        }
      }
    }
    .isMyFt {
      .ftContentWrap {
        .ftContenTop {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
          }
          span {
            margin-top: 8px;
            font-size: 15px;
            color: #303133;
          }
        }
        .ftAcountDetail {
          margin-top: 23px;
          width: 100%;
          height: 86px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #f7fafc;
          border-radius: 23px;
          .top {
            color: #5173b9;
            span:nth-of-type(1) {
              font-size: 20px;
            }
            span:nth-of-type(2) {
              font-size: 13px;
            }
          }
          .bottom {
            font-size: 11px;
            color: #909399;
          }
        }
        .ftContentCenter {
          margin-top: 23px;
          display: flex;
          flex-direction: column;
          .ftContentItem {
            margin-bottom: 11px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
              display: block;
              color: #909399;
              font-size: 13px;
            }
            .right {
              display: flex;
              flex-direction: row;
              color: #303133;
              font-size: 12px;
              img {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 8px;
                margin-right: 8px;
                span {
                  display: block;
                }
              }
            }
          }
        }
        .ftContenfooter {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          .confirmBtn {
            width: 105px;
            height: 41px;
            line-height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
        }
      }
      .transitionWrap {
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #303133;
          font-size: 18px;
        }
        .ftContent {
          margin-top: 29px;
          .payAcount {
            div {
              font-size: 12px;
              color: #606366;
              margin-bottom: 8px;
            }
            input {
              width: 100%;
              height: 41px;
              line-height: 41px;
              border: none;
              border-bottom: 1px solid #edeff2;
              font-size: 12px;
              &:focus {
                outline: none;
              }
            }
            .tips {
              color: red;
            }
          }
          .ftName {
            margin-top: 23px;

            div:nth-of-type(1) {
              font-size: 12px;
              color: #606366;
            }
            div:nth-of-type(2) {
              padding-bottom: 5px;
              display: flex;
              margin-top: 8px;
              flex-direction: row;
              align-items: center;
              border-bottom: 1px solid #edeff2;

              img {
                border-radius: 18px;
                display: block;
                width: 27px;
                height: 27px;
                margin-right: 8px;
              }
              span {
                display: block;
                color: #303133;
              }
            }
          }
          .ftTotal {
            margin-top: 23px;
            .top {
              font-size: 12px;
              color: #606366;
            }
            .foot {
              margin-top: 8px;
              padding-bottom: 5px;
              color: #303133;
              input {
                color: #5173b9;
                width: 100%;
                height: 41px;
                line-height: 41px;
                border: none;
                border-bottom: 1px solid #edeff2;
                font-size: 12px;
                &:focus {
                  outline: none;
                }
              }
              .tipsNum {
                color: red;
              }
            }
          }
        }
        .payBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 33px;
          .confirmBtn {
            width: 105px;
            height: 41px;
            background-color: #eab336;
            padding: unset;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            opacity: 0.5;
            filter: alpha(opacity=50);
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
          }
          .allowConfirmBtn {
            width: 105px;
            height: 41px;
            background-color: #eab336;
            border-radius: 23px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            opacity: 1;
            filter: alpha(opacity=100);
            -moz-opacity: 1;
            -khtml-opacity: 1;
          }
        }
      }
    }
  }
}
::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}
::v-deep .el-tabs__item.is-active {
  color: rgba(48, 49, 51, 1);
}
::v-deep .el-tabs__item {
  font-size: 18px;
  color: rgba(144, 147, 153, 1);
}
@media only screen and (max-width: 768px) {
  .wallet_box {
    padding: 10px;
  }
  .wallet_box .wallet_data {
    padding: 15px;
    border-radius: 15px;
    width: calc(100% / 3);
  }
  .wallet_box .wallet_receive {
    width: calc(100% / 3);
  }
}
@media (max-width: 768px) {
  .wallet {
    margin: 0 auto;
    margin-top: 45px;
    .nftDetailWrap {
      padding: 20px;
      display: inline-block;
      max-width: 100%;
      // min-height: 375px;
      width: calc(100% / 3);
      border-radius: 23px;
      background: rgba(255, 255, 255, 1);
      .detailTop {
        display: flex;
        align-items: center;
        .leftIcon {
          i {
            font-size: 23px;
          }
        }
        .content {
          img {
            width: 28px;
            height: 28px;
            border: 50%;
            display: inline-block;
            margin-right: 9px;
          }
          span {
            font-size: 18px;
            color: #303133;
          }
        }
      }
      .detailContainer {
        // display: flex;
        // width: 100%;
        // flex-wrap: wrap;
        // justify-content: flex-start;
        // align-items: center;
        .detailItem {
          // margin-top: 32px;
          // margin-right: 9px;
          img {
            display: block;
            // width: 90px;
            // height: 90px;
            // object-fit: fill;
            // border-radius: 23px;
          }
          span {
            display: block;
            font-size: 12px;
            color: #909399;
            line-height: 1em;
            margin-top: 9px;
          }
        }
      }
    }
  }

  .container {
    .game {
      .card {
        .cardWrap {
          margin-right: 10px;
          img {
            border-radius: 6px;
            width: 90px;
            object-fit: contain;
          }
        }
      }
    }
  }

  ::v-deep .el-dialog {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .cardWrap {
    margin-right: 10px;
    img {
      border-radius: 6px;
      width: 95px;
      object-fit: contain;
    }
  }
}
</style>
