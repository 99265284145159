var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wallet" },
    [
      _c(
        "div",
        {
          ref: "wallet_warpBsv",
          staticClass: "wallet_warp",
          staticStyle: { "margin-top": "0" },
        },
        [
          _c(
            "div",
            {
              staticClass: "wallet_receive",
              class: _vm.boxData.symbol ? _vm.boxData.symbol : "",
            },
            [
              _c("div", { staticClass: "receive" }, [
                _c("h4", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("receivablesAddress")) +
                      "\n        "
                  ),
                ]),
                _c("img", { attrs: { src: _vm.receiveEVM, alt: "" } }),
                _c("p", [_vm._v(_vm._s(_vm.myAddress))]),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.copyAdd()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("copyAddress")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c("i", {
                staticClass: "iconfont icon-icon-arrow-left2",
                on: {
                  click: function ($event) {
                    return _vm.transitionBoxBsv("-100%")
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "wallet_data" }, [
            _c(
              "div",
              {
                staticClass: "wallet_show",
                class: _vm.boxData.symbol ? _vm.boxData.symbol : "",
              },
              [
                _vm.amountShow
                  ? _c(
                      "div",
                      {
                        staticClass: "wallet_text",
                        style: {
                          background:
                            "url(https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/ic_" +
                            _vm.boxData.symbol +
                            "%402x.png) no-repeat",
                          backgroundSize: "98px 103px",
                        },
                      },
                      [
                        _c("div", [
                          _c("h4", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$utils.getAmount(
                                    (_vm.boxData.balance.amount / _vm.SATS) *
                                      Number(_vm.boxData.toBSV),
                                    4
                                  )
                                ) +
                                "\n              " +
                                _vm._s(_vm.$utils.getCurrency()) +
                                "\n            "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.cutNumberNoZero(
                                    _vm.boxData.balance.amount / _vm.SATS,
                                    8
                                  )
                                ) +
                                "\n              " +
                                _vm._s(_vm.boxData.symbol) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "currency" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.boxData.symbol) + " "),
                            _c("s", [
                              _vm._v("(" + _vm._s(_vm.boxData.cardName) + ")"),
                            ]),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "iconfont_box",
                              on: {
                                click: function ($event) {
                                  _vm.operationShow = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "iconfont icon-shangxiayidongjiantou",
                                class: _vm.boxData.symbol
                                  ? _vm.boxData.symbol
                                  : "",
                              }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "wallet_btn" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.transitionBoxBsv(0)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-jia2" }),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("receivables")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.transitionBoxBsv("-200%")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-youjiantou" }),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("Transfer")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "wallet_receive",
              class: _vm.boxData.symbol ? _vm.boxData.symbol : "",
            },
            [
              _c("i", {
                staticClass: "iconfont icon-you",
                on: {
                  click: function ($event) {
                    return _vm.transitionBoxBsv("-100%")
                  },
                },
              }),
              _c("div", { staticClass: "receive" }, [
                _c("h4", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("Transfer")) + "\n        "
                  ),
                ]),
                _c("div", { staticClass: "transfer" }, [
                  _c(
                    "div",
                    { staticClass: "add", class: { addShow: _vm.addShow } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.add,
                            expression: "add",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("PleaseAddress"),
                        },
                        domProps: { value: _vm.add },
                        on: {
                          focus: function ($event) {
                            _vm.addShow = true
                          },
                          blur: function ($event) {
                            _vm.addShow = false
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.add = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "money", class: { addShow: _vm.moneyShow } },
                    [
                      _c("input", {
                        directives: [
                          { name: "enter-number2", rawName: "v-enter-number2" },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.money,
                            expression: "money",
                          },
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t("TransferAmount"),
                        },
                        domProps: { value: _vm.money },
                        on: {
                          focus: function ($event) {
                            _vm.moneyShow = true
                          },
                          blur: function ($event) {
                            _vm.moneyShow = false
                            _vm.setAmount()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.money = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticClass: "rewardSelect",
                          model: {
                            value: _vm.rewardSelect,
                            callback: function ($$v) {
                              _vm.rewardSelect = $$v
                            },
                            expression: "rewardSelect",
                          },
                        },
                        _vm._l(_vm.rewardOptions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.submitShow,
                          expression: "submitShow",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.submitPay()
                        },
                      },
                    },
                    [_vm._v("转账")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "centerDialogVisible",
          staticStyle: { "margin-top": "15vh" },
          attrs: {
            title: _vm.$t("ConfirmTransfer"),
            visible: _vm.payShow,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.payShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "payShow" }, [
            _c("h4", [
              _vm._v("\n        " + _vm._s(_vm.$t("amount")) + "\n      "),
            ]),
            _c("p", [
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutNumberNoZero(_vm.amount, 9)) +
                    _vm._s(this.boxData.symbol) +
                    "\n        "
                ),
              ]),
            ]),
            _c("h4", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("transferAddress")) + "\n      "
              ),
            ]),
            _c("p", [_vm._v("\n        " + _vm._s(_vm.add) + "\n      ")]),
            _c("h4", [
              _vm._v("\n        " + _vm._s(_vm.$t("minerFee")) + "\n      "),
            ]),
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.fee) + " " + _vm._s(this.boxData.symbol) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "queding" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.payShow = false
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("close")) + "\n      ")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.transactionShow,
                    expression: "transactionShow",
                  },
                ],
                staticClass: "pay",
                attrs: { disabled: _vm.transactionShow },
                on: {
                  click: function ($event) {
                    return _vm.goPay()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("determine")) + "\n      ")]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "centerDialogVisible",
          staticStyle: { "margin-top": "15vh" },
          attrs: {
            title: _vm.$t("Transfer"),
            visible: _vm.success,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.success = $event
            },
          },
        },
        [
          _c("h2", { staticClass: "success" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("successfulTransfer")) + "\n    "
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "queding",
              on: {
                click: function ($event) {
                  _vm.success = false
                  _vm.add = ""
                  _vm.money = 0
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("determine")) + "\n    ")]
          ),
        ]
      ),
      _vm.operationShow
        ? _c(
            "div",
            {
              staticClass: "currency-dialong",
              on: {
                click: function ($event) {
                  _vm.operationShow = false
                },
              },
            },
            [
              _c("div", { staticClass: "currency_warp" }, [
                _c(
                  "div",
                  {
                    staticClass: "currency_box",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.operationShow = true
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("i", {
                        staticClass: "iconfont icon-x",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.operationShow = false
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "currency_top" }, [
                      _c(
                        "h4",
                        {
                          class: _vm.h4Show === 1 ? "h4Show" : "",
                          on: {
                            click: function ($event) {
                              _vm.h4Show = 1
                            },
                          },
                        },
                        [
                          _c("i"),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.boxData.symbol) +
                                " " +
                                _vm._s(_vm.$t("receiveMoney")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "h4",
                        {
                          class: _vm.h4Show === 2 ? "h4Show" : "",
                          on: {
                            click: function ($event) {
                              _vm.h4Show = 2
                            },
                          },
                        },
                        [
                          _c("i"),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.boxData.symbol) +
                                " " +
                                _vm._s(_vm.$t("withdraw")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "h4",
                        {
                          class: _vm.h4Show === 3 ? "h4Show" : "",
                          on: {
                            click: function ($event) {
                              _vm.h4Show = 3
                              _vm.getTokenHistory()
                            },
                          },
                        },
                        [
                          _c("i"),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("receivRecord")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "currency_body" }, [
                      _vm.h4Show === 1
                        ? _c("div", { staticClass: "recharge" }, [
                            _c("img", {
                              attrs: { src: _vm.rechargeImg, alt: "" },
                            }),
                            _c("p", [_vm._v(_vm._s(_vm.rechargeAddress))]),
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.$copyText(_vm.rechargeAddress)
                                    _vm.$message({
                                      type: "success",
                                      message: _vm.$t("coypSuccess"),
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("coypSuccess")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "recharge_text" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("reminder")) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t("reminderTips1")
                                        .replace("BTC", _vm.boxData.symbol)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("reminderTips2")) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t("reminderTips3")
                                        .replace("BTC", _vm.boxData.symbol)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("reminderTips4")) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("reminderTips5")) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.h4Show === 2
                        ? _c("div", { staticClass: "extraction" }, [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "input_box",
                                  class: {
                                    borderR: _vm.extractionAddressShow === 3,
                                    borderY: _vm.extractionAddressShow === 2,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("withdrawAddress")) +
                                        "\n                "
                                    ),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.extractionAddress,
                                        expression: "extractionAddress",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("inputAddress"),
                                    },
                                    domProps: { value: _vm.extractionAddress },
                                    on: {
                                      focus: function ($event) {
                                        _vm.extractionAddressShow = 2
                                      },
                                      blur: function ($event) {
                                        _vm.extractionAddress
                                          ? (_vm.extractionAddressShow = 1)
                                          : (_vm.extractionAddressShow = 3)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.extractionAddress =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "input_box",
                                  class: {
                                    borderR: _vm.extractionAmountShow === 3,
                                    borderY: _vm.extractionAmountShow === 2,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("withdrawAmount")) +
                                        "\n                "
                                    ),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.extractionAmount,
                                        expression: "extractionAmount",
                                      },
                                      {
                                        name: "enter-number2",
                                        rawName: "v-enter-number2",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      placeholder: _vm.$t("TransferAmount"),
                                    },
                                    domProps: { value: _vm.extractionAmount },
                                    on: {
                                      focus: function ($event) {
                                        _vm.extractionAmountShow = 2
                                      },
                                      blur: function ($event) {
                                        _vm.extractionAmount
                                          ? (_vm.extractionAmountShow = 1)
                                          : (_vm.extractionAmountShow = 3)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.extractionAmount =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "button",
                              {
                                class:
                                  _vm.extractionAmount && _vm.extractionAddress
                                    ? "buttonY"
                                    : "",
                                attrs: { disabled: _vm.extractionBShow },
                                on: {
                                  click: function ($event) {
                                    return _vm.extractionBTN()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("ConfirmTransfer")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "extraction_text" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("riskWarning")) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("warningTips")) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.h4Show === 3
                        ? _c("div", { staticClass: "record" }, [
                            _vm.TokenHistory.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.TokenHistory,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "record_item",
                                        },
                                        [
                                          _c("div", [
                                            _c("h2", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    item.type === "withdraw"
                                                      ? _vm.$t("withdraw")
                                                      : _vm.$t("receiveMoney")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]),
                                            _c(
                                              "h2",
                                              {
                                                class:
                                                  item.type === "withdraw"
                                                    ? "h2red"
                                                    : "h2blue",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      item.type === "withdraw"
                                                        ? "-"
                                                        : "+"
                                                    ) +
                                                    "\n                    " +
                                                    _vm._s(
                                                      _vm.cutNumberNoZero(
                                                        +item.amount /
                                                          _vm.bsvrate.SATS,
                                                        8
                                                      )
                                                    ) +
                                                    "\n                    " +
                                                    _vm._s(item.symbol) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("div", [
                                            _c("s", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(item.updateTime)
                                                      .format(
                                                        "YYYY-MM-DD HH:MM"
                                                      )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.TokenHistory.length === 0
                              ? _c("div", { staticClass: "noTokenHistory" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("noRecord")) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }