<template>
  <div>
    <div
      :class="{
        item_R: !itemData.type,
        item_B: itemData.type,
        item_0: itemData.tokenShow || itemData.cochain,
      }"
    >
      <div class="item">
        <div class="bsvContainer">
          <div class="left">
            <h5>
              <i></i>
              <u>
                {{ itemData.createTime === 0 ? "Unconfirmed" : "" }}
              </u>
            </h5>
          </div>
          <div class="right">
            <p style="text-align: right">
              <!-- {{ $utils.getAmount(itemData.Value / SATS, 4) }} -->
              --
              <s>
                {{ $utils.getCurrency() }}
              </s>
            </p>
            <span> {{ itemData.Value | changeUnit }} </span>
          </div>
          <!-- <a :href="'https://'+isTestnet+'whatsonchain.com/tx/' + item.txId" target="_blank" class="tx" >#tx</a> -->
        </div>
      </div>
      <div class="foot">
        <div class="data" v-if="itemData.blockTime">
          <span> {{ $t("data") }}</span
          >&nbsp;{{ $moment(itemData.blockTime).format("YYYY-MM-DD HH:mm:ss") }}
        </div>
        <div class="data" v-else-if="itemData.time">
          <span>{{ $t("data") }}</span>
          &nbsp;
          {{ itemData.time }}
        </div>
        <div class="data" v-else>
          <span> {{ $t("data") }}</span
          >&nbsp;{{ $t("unconfirmed") }}
        </div>
        <div class="tx">
          <a :href="txBrowserHost + itemData.txId" target="_blank">#tx</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js-light";
import { imageUrl } from "../../common/js/baseApi";
export default {
  props: {
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      SATS: 100000000,
      isTestnet: process.env.VUE_APP_NETWORK === "testnet" ? "test." : "",
    };
  },
  filters: {
    changeUnit(value) {
      // if (+value >= 1000000 || +value <= -1000000) {
      //   return ` ${new Decimal(value).div(Math.pow(10, 8))} ${
      //     this.$store.state.chain === "BSV" ? "BSV" : "SPACE"
      //   }`;
      // }
      return ` ${value} SATS`;
    },
    tofixed4(result) {
      if (Number(result) % 1 === 0) {
        return result;
      } else {
        return Number(result).toFixed(5).replace(/0+$/g, "");
      }
    },
    cutZero(oldValue) {
      let newValue = oldValue;
      let leng = oldValue.length - oldValue.indexOf(".") - 1;
      if (oldValue.indexOf(".") > -1) {
        for (let i = leng; i > 0; i--) {
          if (
            newValue.lastIndexOf("0") > -1 &&
            newValue.substr(newValue.length - 1, 1) == 0
          ) {
            let j = newValue.lastIndexOf("0");
            if (newValue.charAt(j - 1) == ".") {
              return newValue.substring(0, j - 1);
            } else {
              newValue = newValue.substring(0, j);
            }
          } else {
            return newValue;
          }
        }
      }
      return oldValue;
    },
    ftImgUrl(iconUrl) {
      switch (iconUrl) {
        case "mc":
          return require("../../assets/images/tokenImg/mc.png");
        case "boex":
          return require("../../assets/images/tokenImg/boex.jpeg");
        case "ovts":
          return require("../../assets/images/tokenImg/ovts.png");
        case "tsc":
          return require("../../assets/images/tokenImg/tsc.png");
        case "usdt":
          return require("../../assets/images/tokenImg/usdt.png");
        case "asc":
          return require("../../assets/images/tokenImg/asc.png");
        case "bart":
          return require("../../assets/images/tokenImg/bart.jpg");
        case "cc":
          return require("../../assets/images/tokenImg/cc.jpg");
        case "ceo":
          return require("../../assets/images/tokenImg/ceo.jpg");
        case "whst":
          return require("../../assets/images/tokenImg/whst.jpg");
        default:
          return require("../../assets/images/tokenImg/empty-token.png");
      }
    },
    nftImgUrl(tx) {
      if (
        tx?.indexOf("metafile://") > -1 &&
        tx?.indexOf("metafile://undefined") !== 1 &&
        tx
      ) {
        return imageUrl() + tx?.substr(11);
      } else {
        return require("../../assets/images/icon_nft_default.png");
      }
    },
  },
  computed: {
    txBrowserHost() {
      if (this.$store.state.chain === "BSV") {
        return "https://whatsonchain.com/tx/";
      }

      return "https://mvcscan.com/tx/";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  background: transparent;
  border: none;
  color: #fff;
  width: 135px;
  text-align: center;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #eab300;
}
.recordList {
  max-height: 1000px;
  overflow: auto;
  padding-right: 10px;
  .noTokenRecord {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #ffffff;
  }
  .foot {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .data {
      color: #303133;
      font-size: 13px !important;
      font-family: PingFangSC-Semibold, sans-serif !important;
      font-weight: 500;
    }
    .tx {
      text-align: center;
      width: 38px;
      height: 23px;
      line-height: 23px;
      background: rgba(220, 235, 252, 1);
      border-radius: 3px;
      color: #157bee;
      cursor: pointer;
      font-weight: bold;
      a {
        color: #157bee;
      }
    }
  }
  .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 31.5px;
        height: 31.5px;
        object-fit: cover;
        // border-radius: 21px;
        margin-right: 7.5px;
      }
      span {
        color: #303133;
        font-size: 14px !important;
      }
    }
  }
  .bsvContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .data {
      color: #303133;
      font-size: 13px !important;
      font-family: PingFangSC-Semibold, sans-serif !important;
      font-weight: 500;
    }
    .tx {
      text-align: center;
      width: 38px;
      height: 23px;
      line-height: 23px;
      background: rgba(220, 235, 252, 1);
      border-radius: 3px;
      color: #157bee;
      cursor: pointer;
      font-weight: bold;
      a {
        color: #157bee;
      }
    }
  }
  &.metasvList {
    .is_loading {
      padding: 15px;
      background: #fff;
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      height: 70px;
      color: #fff;
      border-radius: 11px;
    }
    .item_B .item,
    .item_R .item {
      position: relative;
      border-bottom: none;
      padding-bottom: 0;
    }
    .tx {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 38px;
      height: 23px;
      line-height: 23px;
      background: rgba(220, 235, 252, 1);
      border-radius: 3px;
      color: #157bee;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .items_time {
    color: #fff;
    margin: 25px 15px 10px;
    font-size: 12px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #606266;
    border-radius: 10px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    box-shadow: block;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
  > h2 {
    padding-left: 15px;
    font-size: 12px;
    color: #fff;
    margin-top: 20px;
    font-weight: normal;
  }
  .item_R,
  .item_B {
    padding: 15px;
    background: #fff;
    border-radius: 11px;
    margin-top: 10px;
    transition: 0.2s all ease-in-out;
    animation: mymove 2s 1;
    .item {
      border-bottom: 1px solid #ffe3df;
      padding-bottom: 25px;
      display: flex;
      justify-content: space-between;
      h5 {
        display: flex;
        height: 20px;
        align-items: center;
        i {
          width: 3px;
          height: 12px;
          border-radius: 5px;
          background: #fd4426;
        }
        u {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      p {
        color: #fd4426;
        font-size: 24px;
        font-weight: bold;
      }
      s {
        font-size: 12px;
        font-weight: bold;
        color: #fd4426;
      }
      span {
        float: right;
        color: #303133;
        font-size: 13px !important;
        font-family: PingFangSC-Semibold, sans-serif !important;
        font-weight: Semibold !important;
      }
    }
    h4 {
      color: #606266;
      margin-top: 10px;
      font-weight: normal;
      transition: 0.2s all ease-in-out;
    }
    .BSVh4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .BSVaddress {
        width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        white-space: nowrap;
        align-items: center;
        display: flex;
        > span {
          width: 35px;
        }
        > div {
          img {
            margin-left: 0;
          }
        }
        .bsv_user {
          display: flex;
          align-items: center;
        }
        img {
          width: 23px;
          height: 23px;
          margin-left: 10px;
          object-fit: contain;
          margin-right: 10px;
          border-radius: 50%;
        }
      }
      .tx {
        text-align: center;
        width: 38px;
        height: 23px;
        line-height: 23px;
        background: rgba(220, 235, 252, 1);
        border-radius: 3px;
        color: #157bee;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .show_data {
      padding: 0 30px;
      transition: 0.2s all ease-in-out;
      .time {
        min-height: 60px;
        border-bottom: 1px solid #ffe3df;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6 {
          font-size: 14px;
          color: #303133;
        }
        .tx {
          text-align: center;
          width: 38px;
          height: 23px;
          line-height: 23px;
          background: rgba(220, 235, 252, 1);
          border-radius: 3px;
          color: #157bee;
          cursor: pointer;
          font-weight: bold;
        }
      }
      .icon-icon-arrow-top2 {
        text-align: center;
        display: block;
        color: #bfc2cc;
        margin: 20px 0;
        cursor: pointer;
      }
    }
    .from,
    .to {
      min-height: 60px;
      border-bottom: 1px solid #ffe3df;
      display: flex;
      flex-wrap: wrap;
      h6 {
        width: 45px;
        font-size: 14px;
        line-height: 60px;
        color: #909399;
      }
      > div {
        width: calc(100% - 45px);
      }
      .data {
        display: flex;
        line-height: 60px;
        justify-content: space-between;
        align-items: center;
      }
      p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 23px;
          height: 23px;
          margin-left: 10px;
          object-fit: cover;
          margin-right: 10px;
          border-radius: 50%;
        }
        s {
          max-width: 120px;
          width: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          color: #303133;
        }
      }
      span {
        color: #fd4426;
        font-size: 14px;
      }
    }
    .to {
      line-height: 60px;
      .data {
        span {
          color: #157bee;
        }
      }
    }
  }
  .item_B {
    .item {
      border-bottom: 1px solid #dcebfc;
      h5 {
        i {
          background: #157bee;
        }
        img {
          display: inline-block;
          width: 31px;
          height: 31px;
          border-radius: 16px;
          margin-right: 8px;
        }
        span {
          display: inline-block;
          font-size: 12px;
          color: #303133;
          height: 14px;
          line-height: 14px;
        }
      }
      p {
        color: #157bee;
      }
      s {
        color: #157bee;
      }
      span {
        color: #303133;
        font-size: 14px;
        font-family: PingFangSC-Semibold, sans-serif;
        font-weight: Semibold;
      }
    }
    .show_data .time {
      border-bottom: 1px solid #dcebfc;
    }
    .from,
    .to {
      border-bottom: 1px solid #dcebfc;
    }
  }
  .item_0 {
    .item {
      h5 {
        i {
          background: #606266;
        }
      }
      p {
        color: #606266;
      }
      s {
        color: #606266;
      }
      span {
        color: #606266;
      }
    }
  }
}
.el-select-dropdown__item.selected {
  color: #eab300;
}
@media only screen and (max-width: 768px) {
  .record {
    padding: 15px;
    margin: 45px auto;
  }
  .recordList {
    .item_R {
      .from,
      .to {
        > div {
          width: 100%;
        }
      }
    }
    .item_B .item,
    .item_R .item {
      p {
        color: #fd4426;
        font-size: 18px;
        font-weight: bold;
        text-align: right;
      }
      s {
        font-size: 12px;
        font-weight: bold;
        color: #fd4426;
      }
      .itemContainer {
        .left {
          width: 50%;
          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            // border-radius: 21px;
            margin-right: 5px;
          }
          span {
            font-size: 12px !important;
          }
        }
      }
    }
    .item_B {
      .item {
        p {
          color: #157bee;
        }
        s {
          color: #157bee;
        }
      }
    }
  }
  .recordList .item_R .from h6,
  .recordList .item_R .to h6,
  .recordList .item_B .from h6,
  .recordList .item_B .to h6 {
    line-height: 32px;
  }
  .recordList .item_R .from .data,
  .recordList .item_R .to .data,
  .recordList .item_B .from .data,
  .recordList .item_B .to .data {
    line-height: 32px;
    flex-wrap: wrap;
  }
  .recordList .item_R .from p,
  .recordList .item_R .to p,
  .recordList .item_B .from p,
  .recordList .item_B .to p {
    width: 100%;
  }
  .recordList .item_R .from p s,
  .recordList .item_R .to p s,
  .recordList .item_B .from p s,
  .recordList .item_B .to p s {
    max-width: 80%;
  }
  .recordList .item_R .from span,
  .recordList .item_R .to span,
  .recordList .item_B .from span,
  .recordList .item_B .to span {
    width: 100%;
    text-align: right;
  }
  .recordList .item_R .show_data .icon-icon-arrow-top2,
  .recordList .item_B .show_data .icon-icon-arrow-top2 {
    margin: 10px 0;
  }
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
::v-deep .el-loading-mask {
  background-color: rgba($color: #606266, $alpha: 0.9);
}
::v-deep .el-loading-spinner {
  top: 0;
  margin-top: 0;
}
.metaShow {
  margin-top: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  > span {
    margin-right: 5px;
    font-size: 12px;
  }
}
@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
