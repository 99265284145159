<template>
  <div class="index_box">
    <el-row class="index">
      <el-col :xs="24" :sm="11" class="wallet_box">
        <h2>
          <span>
            <i></i>
          </span>
          <!-- 我的钱包 -->
          {{ $t("myWallet") }}
        </h2>
        <!-- BSV 钱包 -->
        <bsvBox></bsvBox>
        <h2 class="nfttitle">
          <span>
            <i></i>
          </span>
          <!-- 我的通证 -->
          {{ $t("myNFT") }}
        </h2>
        <nftBox></nftBox>
        <addWalletBox
          v-for="(item, index) in myTokenList"
          :key="index"
          :boxData="item"
          :boxIndex="index"
        ></addWalletBox>
        <!-- <div class="add_currency">
          <img
            src="../../assets/images/icon_add_wallet@2x.png"
            alt=""
            @click="gotoUrl('user')"
          />
          <span @click="gotoUrl('user')">
        
            {{ $t("addCard") }}
          </span>
        </div> -->
      </el-col>
      <el-col :xs="24" :sm="11" class="record_box">
        <h2>
          <span>
            <i></i>
          </span>
          <!-- 交易记录 -->
          {{ $t("transactionRecord") }}
        </h2>
        <record></record>
      </el-col>
    </el-row>
    <span style="display: none">v1.0.6</span>
    <!-- <iframe src="https://192.168.168.154:8080/" frameborder="0" id="iframe"></iframe> -->
  </div>
</template>

<script>
import store from "@/store";
import bsvBox from "./BSVwallet";
import nftBox from "./NFTwallet";
import record from "./record";
import addWalletBox from "./addWalletBox";
// import { addShowId } from '@/api/api'
import { getuserinfo, getOwnShowAccount } from "@/api/api";
import { gettokenpage } from "../../api/api";
export default {
  data() {
    return {
      tokenizedList: "",
      walletToken: "",
      myTokenList: "",
      metaTipt: false,
      userData: store.state.userData,
    };
  },
  components: {
    bsvBox,
    record,
    nftBox,
    addWalletBox,
  },
  watch: {
    "$store.state.myTokenList"() {
      this.myTokenList = this.$store.state.myTokenList;
    },
  },
  methods: {
    getTokenpage() {
      let params = {
        userType: this.$store.state.userData.phone ? "phone" : "email",
      };
      params.userType === "phone"
        ? (params.phone = this.userData.phone)
        : (params.email = this.userData.email);
      this.$utils.gettokenpageData().then((r) => {
        if (!r || !r.isSaveMem) {
          if (
            this.$store.state?.balance?.bsv &&
            this.$store.state.balance.bsv > 0.1
          ) {
            this.$store.dispatch("showMem", true);
          }
        }
      });
    },
    getUserInfo() {
      if (!this.userData) return;
      let params = {
        // type: 2
      };
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      console.log(this.userData);
      if (
        re.test(
          this.userData.register == "email"
            ? this.userData.email
            : this.userData.phone
        )
      ) {
        params.userType = "email";
        params.email =
          this.userData.register == "email" ? this.userData.email : null;
      } else {
        params.userType = "phone";
        params.phone =
          this.userData.register == "phone" ? this.userData.phone : null;
      }
      const that = this;
      const wallet = store.state.wallet;
      // 获取用户信息头像用户名等
      getuserinfo(params)
        .then((res) => {
          // 转账开始进行 metanet
          this.$store.state.userData.name = res.userName;
          this.$store.state.userData.email = res.email;
          this.$store.state.userData.phone = res.phone;
          this.$store.state.userData.paymail = res.paymail[0];
          console.log(this.$store.state.userData);
          if (res.metaId) {
            this.$store.state.userData.metaId = res.metaId;
          }
          // let data = {
          //   address: store.state.metanet.rootAddress.address,
          //   xpub: store.state.wallet.getXpub()
          // }
          // console.log(userData)
          // console.log(store.state.userData)
          let metaparams = {
            showId: this.$store.state.userData.metaId,
          };
          getOwnShowAccount(JSON.stringify(metaparams)).then((res) => {
            console.log(res);
            // console.log(store.state.userMeta)
            if (res.showId) {
              let result = res;
              this.$store.state.metaShow = false;
              this.$store.state.userMeta = result;
              this.$store.state.userData.name = result.name;
              that.$utils.getShowIDUserData();
              if (this.$store.state.userMeta.headUrl) {
                // console.log('我进来了')
                let str = this.$store.state.userMeta.headUrl;
                if (!str) {
                  return new Uint8Array();
                }
                var a = [];
                for (let i = 0, len = str.length; i < len; i += 2) {
                  a.push(parseInt(str.substr(i, 2), 16));
                }
                var binary = "";
                var bytes = new Uint8Array(a);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
                }
                var a = [];
                for (let i = 0, len = str.length; i < len; i += 2) {
                  a.push(parseInt(str.substr(i, 2), 16));
                }
                var binary = "";
                var bytes = new Uint8Array(a);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
                }
                let sty = window.btoa(binary);
                let type = "image/jpeg";
                store.state.userData.headUrl =
                  "data:" + type + ";base64," + sty;
                console.log("userData2", store.state.userData);
                that.$forceUpdate();
                localStorage.setItem(
                  "localuserData",
                  JSON.stringify(store.state.userData)
                );
              }
            }
          });
          that.$utils.getWalletList();
          // 获取转账地址
          this.$store.state.tokenList = wallet.tokenized.tokenizedList.filter(
            (item) => {
              return item.symbol !== "USD";
            }
          );
          // resolve()
        })
        .catch((err) => {
          return that.$message.error(that.$t("Error") + err.message);
        });
    },
    outLogin() {
      console.log("删除密码");
      this.$store.state.userData = "";
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
      // this.gotoUrl('login')
    },
    createMeta() {
      // this.$utils.getShowIDUserData()
      console.log("userData", this.$store.userData);
      this.$store.state.metanet
        .initMetaId({
          isMetasv: true,
          onSuccess: (res) => {
            console.log("initMetaId res", res);
            this.$store.state.userData.metaId = res.metaId;
            this.$store.state.userData.name = res.name;
            this.$store.state.userData.infoTxId = res.infoTxId;
            this.$store.state.userData.protocolTxId = res.protocolTxId;

            this.$store.state.userMeta = res;
            this.$store.state.infoTxId = res.infoTxId;
            console.log("userData", this.$store.state.userData);
            localStorage.setItem(
              "localuserData",
              JSON.stringify(this.$store.state.userData)
              // JSON.stringify(res)
            );
            const referrerId = localStorage.getItem("referrerId");
            const referCode = localStorage.getItem("referCode");
            this.sendReferrerInfo(referrerId, referCode, res.utxos);
            this.fullLoading.close();
          },
          onError: (error) => {
            this.fullLoading.close();
            if (error.data && error.data.message) {
              this.$message.error(error.data.message);
            }
          },
        })
        .catch((error) => {
          this.fullLoading.close();
          this.$message.error(this.$t("Error") + error.data.message);
          console.log("删除密码");
          // localStorage.clear();
          // sessionStorage.clear();
        });
    },
    sendReferrerInfo(referrerId, referCode, utxos) {
      referrerId = referrerId || process.env.VUE_APP_DEFAULT_REFERRERID;
      referCode = referCode || process.env.VUE_APP_DEFAULT_REFERCODE;
      const referParams = {
        nodeName: "ShowReferrer",
        metaIdTag: process.env.VUE_APP_IDtags,
        brfcId: "463c81d8015a",
        payCurrency: "BSV",
        payTo: [],
        dataType: "applicaition/json",
        path: "/Protocols/ShowReferrer",
        nodeKey: "0",
        data: JSON.stringify({
          referrerID: referrerId,
          referCode: referCode,
          sourceType: 2,
        }),
        needConfirm: false,
      };
      if (utxos) {
        referParams.utxos = utxos;
      }
      try {
        console.log("推荐信息上链", referParams);
        this.$store.state.metanet.createProtocolNode(referParams);
      } catch (error) {
        console.log("推荐信息上链失败");
      }
    },
  },
  created() {
    this.$utils
      .getMoneyRate()
      .then((res) => {
        this.$store.state.moneyRate = res.price;
      })
      .catch((err) => {
        return this.$message.error(this.$t("Error") + err);
      });
    if (!this.$store.state.userData?.paymail) {
      this.getUserInfo();
    }
    this.getTokenpage();
    this.myTokenList = this.$store.state.myTokenList;
    // console.log(this.$store.state.wallet.getTokenReceiveAddress(), '获取钱包地址')
    // console.log(this.myTokenList)
    // this.tokenizedList = this.$store.state.wallet.tokenized.tokenizedList
    // this.$store.state.wallet.getTokenHistory(this.tokenizedList[0]).then(res => {
    // })
  },
  mounted() {
    console.log("SDK2", this.$store.state.metanet);
    const metanetSdk = this.$store.state.metanet;
    if (
      metanetSdk &&
      metanetSdk.isGotMetaIdInfo &&
      !metanetSdk.isMetaIdCompleted
    ) {
      console.log({
        IdInfo: metanetSdk.metaIdInfo,
        isGot: metanetSdk.isGotMetaIdInfo,
        isCompleted: metanetSdk.isMetaIdCompleted,
        why: metanetSdk.metaIdInfo.name,
        // metanetSdk.metaIdInfo.infoTxId &&
        // metanetSdk.metaIdInfo.protocolTxId &&
        // metanetSdk.metaIdInfo.name,
      });
      this.createMeta();
      this.fullLoading = this.$loading({
        lock: true,
        text: "初始化 MetaID 中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.index_box {
  background: url("../../assets/images/emb_line.png") no-repeat;
  background-color: #303133;
  background-position: 0 200px;
  width: 100vw;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 68px);
}
.index {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  padding-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wallet_box {
  > h2 {
    color: #fff;
    display: flex;
    font-size: 24px;
    align-items: center;
    span {
      width: 21px;
      margin-right: 10px;
      height: 21px;
      border-radius: 50%;
      background: rgba(234, 179, 0, 0.5);
      i {
        width: 11px;
        display: block;
        height: 11px;
        border-radius: 50%;
        background: rgba(234, 179, 0, 1);
        margin: 5px auto;
      }
    }
  }
}
.add_currency {
  margin-top: 45px;
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  img {
    width: 60px;
    height: 60px;
  }
  span {
    margin-left: 14px;
    color: #fff;
  }
}
.record_box {
  > h2 {
    color: #fff;
    display: flex;
    font-size: 24px;
    align-items: center;
    span {
      width: 21px;
      margin-right: 10px;
      height: 21px;
      border-radius: 50%;
      background: rgba($color: #157bee, $alpha: 0.5);
      i {
        width: 11px;
        display: block;
        height: 11px;
        border-radius: 50%;
        background: rgba($color: #157bee, $alpha: 1);
        margin: 5px auto;
      }
    }
  }
}
.nfttitle {
  margin-top: 45px;
  span {
    background: rgba(47, 199, 140, 0.5) !important;
    i {
      background: rgba(47, 199, 140, 1) !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .wallet_box,
  .record_box {
    padding: 10px;
  }
  .wallet_box .wallet_data,
  .record {
    padding: 15px;
    border-radius: 15px;
    width: calc(100% / 3);
  }
  .wallet_box .wallet_receive {
    width: calc(100% / 3);
  }
  .wallet_box .wallet_receive {
    width: calc(100% / 3);
  }
}
</style>
