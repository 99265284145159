<template>
  <div class="record_box">
    <div class="record">
      <div class="record_top">
        <span @click="test()">
          <!-- 选择需要查看记录的钱包 -->
          {{ $t("selectWallet") }}
        </span>
        <el-select
          class="rewardSelect"
          v-model="walletSelect"
          @change="walletChange(walletSelect)"
        >
          <el-option
            v-for="(item, index) in walletOptions"
            :key="index"
            :label="$t(item.label)"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!--MetasvBSV钱包列表样式-->
      <div
        class="recordList"
        v-loading="tokenShow"
        v-show="walletSelect === 'BSV'"
      >
        <div class="" v-for="(items, index) in tokenRecordBsv" :key="items.key">
          <p v-if="items.type === 'date'" class="items_time">
            {{ items.value }}
          </p>
          <record-bsv-item v-else :itemData="items"></record-bsv-item>
        </div>
        <div v-if="isLoading" class="is_loading">
          <div v-loading="isLoading"></div>
        </div>
      </div>
      <!--BSV钱包列表样式-->
      <div
        class="recordList"
        v-loading="tokenShow"
        v-show="walletSelect === 'myToken'"
      >
        <!-- <span class="metaShow">
          <span>
            {{$t('Feetransactions')}}
          </span>
          <el-checkbox v-model="recordShow"></el-checkbox>
        </span> -->
        <template v-if="JSON.stringify(newTokenRecordList) !== '{}'">
          <div
            class=""
            v-for="(items, index) in newTokenRecordList"
            :key="index"
          >
            <p class="items_time">{{ index }}</p>
            <div v-for="item in items" :key="item.txId">
              <!-- <div :class="{item_R: item.nodeName==='FtIssue', item_B: item.nodeName==='FtTransfer',item_0: item.nodeName==='NftIssue' || item.nodeName==='NftTransfer'}"> -->
              <div
                :class="[
                  item.to !== $store.state.userData.metaId
                    ? 'item_R'
                    : 'item_B',
                ]"
              >
                <div class="item">
                  <div class="itemContainer">
                    <div class="left">
                      <img
                        v-if="
                          item.nodeName === 'NftIssue' ||
                          item.nodeName === 'NftTransfer'
                        "
                        :src="item.icon | nftImgUrl"
                        alt=""
                      />
                      <img v-else :src="item | ftImgUrl" alt="" />
                      <span>{{ item.memo }}</span>
                    </div>
                    <div class="right">
                      <p>
                        {{ item.to !== $store.state.userData.metaId ? "-" : "+"
                        }}{{ item.amountStr | cutZero | tofixed4 }}
                        <s>
                          {{ item.symbol }}
                        </s>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="foot">
                  <div class="data" v-if="item.timestamp || item.time">
                    {{ $t("data") }}&nbsp;{{
                      $moment(
                        item.timestamp ? item.timestamp : item.time
                      ).format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </div>
                  <div class="data" v-else>
                    {{ $t("unconfirmed") }}
                  </div>
                  <div class="tx">
                    <a
                      :href="
                        'https://' +
                        isTestnet +
                        'whatsonchain.com/tx/' +
                        item.txId
                      "
                      target="_blank"
                      >#tx</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="noTokenRecord">
          {{ $t("noRecord") }}
        </div>
        <div v-if="isLoading" class="is_loading">
          <div v-loading="isLoading"></div>
        </div>
      </div>
      <div v-loading="tokenShow" v-show="!haschild" class="noTokenList">
        {{ $t("noRecord") }}
      </div>
    </div>
  </div>
</template>

<script>
import RecordBsvItem from "./recordBsvItem";
import { getSensibleTransferList, callMetaSVApi } from "@/api/api";
import { mapState } from "vuex";
import { imageUrl, useTestNet } from "../../common/js/baseApi";
export default {
  data() {
    return {
      flagBlockTime: 0,
      walletSelect: "BSV",
      walletOptions: [
        {
          value: "BSV",
          label: "wallet",
        },
        // {
        //   value: "myToken",
        //   label: "myNFT",
        // },
      ],
      bsvIndex: 5,
      SATS: 100000000,
      myTokenList: [],
      nowToken: "",
      rate: 0,
      moneyRate: "",
      tokenShow: false,
      times: "",
      membsvtx: [],
      memTokenTx: [],
      tokenRecordBsv: [
        // {
        //   createTime: "2021-05-07",
        //   list: [
        //     {
        //       txId:
        //         "244f3beba507be612ebdd6486a924f1b7658ee8571193fa33033edf290186d39",
        //       type: true,
        //       show: false,
        //       cochain: false,
        //       createTime: 1620349756899,
        //       MetaID:
        //         "f21f4fc0328e2398e0978d6171d970620c6827976bd77345188ae7a1ae85b5dd",
        //       UserAddress: "16xN11wyQmUTS3qFwaJYbwHbjHaFkibxWo",
        //       Value: 2000,
        //       vins: [
        //         {
        //           MetaID:
        //             "33f666a9851cc6cc8eda6c11006e1f1bb95c081a01cc130448ad7a64f974291f",
        //           User: {
        //             HeadUrl:
        //               "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png",
        //             User: "Dangdang"
        //           },
        //           address: "139384ZpLETx5P585qmtUxvvjuaWwTfPS5",
        //           outTxId:
        //             "f9c17ac5d638f1088867f44c1db37a6126f092e7a35abdf599385367fef559ae"
        //         }
        //       ],
        //       vouts: [
        //         {
        //           IsMine: true,
        //           MetaID:
        //             "f21f4fc0328e2398e0978d6171d970620c6827976bd77345188ae7a1ae85b5dd",
        //           User: {
        //             HeadUrl:
        //               "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png",
        //             User: " EasonL "
        //           },
        //           address: "16xN11wyQmUTS3qFwaJYbwHbjHaFkibxWo",
        //           txId:
        //             "244f3beba507be612ebdd6486a924f1b7658ee8571193fa33033edf290186d39",
        //           type: "P2PKH",
        //           value: 2000
        //         }
        //       ]
        //     }
        //   ]
        // }
      ],
      realBsvList: [],
      pingTime: null,
      pingShow: false,
      imgUrl:
        "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png",
      listShow: true,
      nub: 0,
      recordShow: false,
      isTestnet: useTestNet() ? "test." : "",
      BSVPage: 1,
      isLoading: true,
      flag: "",
      ismetasv: false,
      createTimeList: [],
      lastTokenTime: 0,
      newTokenRecordList: {
        // "2020-10-10": [
        //   {
        //     from:
        //       "c31e59cd9c604ed2cf83d92ef51ead1cd7f9a5a1c840aa7f971a74c1c543a2c8",
        //     to:
        //       "ea914b911c694bb52978d9bb93fde262c7c9f5feac057b6bce2ec0559e716049",
        //     type: 32,
        //     nodeName: "FtTransfer",
        //     protocolTxId:
        //       "d25afcfc054a8812423b701de83eb33056a451eaca029e3b5873a38e782e8ebc",
        //     subProtocolTxId:
        //       "07356e16022c6131cb9ef17f7e8b04444dea93e3300add46b69adce0d6bc9142",
        //     txId:
        //       "7caaa5d81e063a7bf13ff9e94eeaeee4ffd947943c97e97ae96a6bad668c7055",
        //     data: {
        //       fromAddress: "12ZPugwC99pedCbfB8etVc5GM7MbqR6mv5",
        //       receivers: [
        //         [
        //           {
        //             Key: "address",
        //             Value: "1hUEnfYvhuGP8KvLGYxVQ1PZVfwLwFVnk"
        //           },
        //           { Key: "amount", Value: 726364757205 }
        //         ]
        //       ],
        //       sensibleId:
        //         "3e04f81d7fa7d4d606c3c4c8e8d3a8dcf58b5808740d40a445f3884e126bc7fd00000000",
        //       type: "sensible"
        //     },
        //     encrypt: "0",
        //     dataType: "text/plain",
        //     encoding: "UTF-8",
        //     memo: "MetaCoin",
        //     amount: 0,
        //     amountStr: "15.42502172",
        //     icon:
        //       "metafile://2faa46d7e41ebf2740aac0069136f0fc8f587024750e6a2175948fc5de46d63b",
        //     symbol: "MC",
        //     timestamp: 1628944974227
        //   }
        // ]
      },
      realTokenList: [],
      tokenPage: 1,
      bsvListTimer: null,
      tokenListTimer: null,
    };
  },
  components: {
    RecordBsvItem,
  },
  computed: {
    ...mapState(["wallet", "ftList", "nftList"]),
    zeroAddress() {
      return this.wallet.token_address_0;
    },
    newTransaction() {
      return this.$t("newTransaction");
    },
    haschild() {
      if (this.walletSelect === "myToken") {
        if (this.newTokenRecordList !== {}) {
          return true;
        }
      }
      if (this.walletSelect === "BSV") {
        if (this.tokenRecordBsv.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    walletSelect() {
      this.tokenShow = true;
    },
  },
  filters: {
    tofixed4(result) {
      if (Number(result) % 1 === 0) {
        return result;
      } else {
        return Number(result).toFixed(5).replace(/0+$/g, "");
      }
    },
    cutZero(oldValue) {
      let newValue = oldValue;
      let leng = oldValue.length - oldValue.indexOf(".") - 1;
      if (oldValue.indexOf(".") > -1) {
        for (let i = leng; i > 0; i--) {
          if (
            newValue.lastIndexOf("0") > -1 &&
            newValue.substr(newValue.length - 1, 1) == 0
          ) {
            let j = newValue.lastIndexOf("0");
            if (newValue.charAt(j - 1) == ".") {
              return newValue.substring(0, j - 1);
            } else {
              newValue = newValue.substring(0, j);
            }
          } else {
            return newValue;
          }
        }
      }
      return oldValue;
    },
    ftImgUrl(ftItem) {
      const iconUrl = ftItem.iconUrl;
      const ftIcon = ftItem.icon;
      if (
        ftIcon?.indexOf("metafile://") > -1 &&
        ftIcon?.indexOf("metafile://undefined") !== 1 &&
        ftIcon
      ) {
        return imageUrl() + ftIcon?.substr(11);
      } else {
        return iconUrl;
      }
    },
    nftImgUrl(tx) {
      if (
        tx?.indexOf("metafile://") > -1 &&
        tx?.indexOf("metafile://undefined") !== 1 &&
        tx
      ) {
        return imageUrl() + tx?.substr(11);
      } else {
        return require("../../assets/images/icon_nft_default.png");
      }
    },
  },
  methods: {
    recordListScroll() {
      const that = this;
      const indexBox = document.getElementsByClassName("recordList")[0];
      const indexBox1 = document.getElementsByClassName("recordList")[1];
      indexBox.onscroll = function () {
        const bottomH =
          indexBox.scrollHeight - (indexBox.offsetHeight + indexBox.scrollTop);
        console.log(bottomH - 300 < 100);
        if (bottomH - 300 < 100 && that.walletSelect === "BSV") {
          console.log("调用BSV数据");
          that.debounce(that.getBSVList, 1000, true);
        } else if (bottomH - 300 < 100 && that.walletSelect === "myToken") {
          console.log("调用myToken数据");
          that.debounce(that.getTokenHistory, 1000, true);
        }
      };
      indexBox1.onscroll = function () {
        const bottomH =
          indexBox.scrollHeight - (indexBox.offsetHeight + indexBox.scrollTop);
        console.log(bottomH - 300 < 100);
        if (bottomH - 300 < 100 && that.walletSelect === "BSV") {
          console.log("调用BSV数据");
          that.debounce(that.getBSVList, 1000, true);
        } else if (bottomH - 300 < 100 && that.walletSelect === "myToken") {
          console.log("调用myToken数据");
          that.debounce(that.getTokenHistory, 1000, true);
        }
      };
    },
    test() {
      console.log(123456);
      // this.debounce(this.newList, 500, false)
    },
    timeTitileShow(item) {
      let list = item.list.filter((data) => {
        // eslint-disable-next-line no-mixed-operators

        return (!data.tokenShow && !data.cochain) || this.recordShow;
      });
      console.log(list.length > 0);
      return list.length > 0;
    },
    deepClone(obj) {
      let _obj = JSON.stringify(obj);
      let objClone = JSON.parse(_obj);
      return objClone;
    },
    // 钱包发生改变时获取不同的列表
    walletChange() {
      this.flag = "";
      this.lastTokenTime = 0;
      if (this.walletSelect === "BSV") {
        this.getBSVList(true);
      } else {
        this.getTokenHistory(true);
      }
    },
    getTokenHistory(isnew = false) {
      let startTime = this.flagBlockTime;
      let endTime = 0;
      let page = 1;
      let pageSize = 30;
      if (isnew) {
        endTime = 0;
      } else {
        this.isLoading = true;
        endTime = this.lastTokenTime;
        startTime = 0;
      }
      getSensibleTransferList({
        metaid: this.$store.state.userData.metaId,
        startTime,
        endTime,
        page,
        pageSize,
      }).then((result) => {
        console.log("getSensibleTransferList", result.data);
        const res = result.data;
        this.tokenShow = false;
        let newitem = [];
        let obj = {};
        res.forEach((item) => {
          if (this.memTokenTx.indexOf(item.txId + item.to) < 0) {
            this.memTokenTx.push(item.txId + item.to);
            newitem.push(item);
          }
        });
        if (isnew) {
          this.realTokenList.unshift(...newitem);
        } else {
          this.realTokenList.push(...newitem);
        }
        let data = [...this.realTokenList];
        data = data.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
        data.forEach((item) => {
          const time = this.$moment(item.timestamp).format("YYYY-MM-DD");
          if (!obj[time]) {
            obj[time] = [];
            obj[time].push(item);
          } else {
            obj[time].push(item);
          }
        });
        this.$nextTick(() => {
          this.newTokenRecordList = obj;
          setTimeout(() => {
            this.recordListScroll();
          }, 500);
        });
        if (this.realTokenList.length > 0) {
          this.flagBlockTime = this.realTokenList[0].timestamp;
          this.lastTokenTime =
            this.realTokenList[this.realTokenList.length - 1].timestamp;
        }
      });
    },
    // 获取0/0地址交易记录
    async getAddressHistory(flag) {
      const unConfirmedRes =
        (await callMetaSVApi(`/address/${this.zeroAddress}/tx`, {
          confirmed: false,
          flag: flag,
        })) || [];
      const confirmedRes =
        (await callMetaSVApi(`/address/${this.zeroAddress}/tx`, {
          flag: flag,
        })) || [];
      const histories = [...unConfirmedRes, ...confirmedRes];
      return histories;
    },
    // 获取BSV记录
    async getBSVList(isnew) {
      let params = {
        Xpub: this.$store.state.wallet.getXpub(),
        Page: this.wsPage || this.BSVPage,
        PageSize: 1,
        Time: 0,
      };
      this.tokenShow = false;
      if (!isnew) {
        this.isLoading = true;
        params.flag = this.flag;
      }
      let res = [];
      try {
        res = await this.getAddressHistory(this.flag);
        // 日期处理
        res = res.map((item) => {
          if (item.height > 0) {
            item.blockTime = item.time;
          }
          return item;
        });
      } catch (error) {
        console.log("请求交易记录出错");

        this.isLoading = false;
        return;
      }
      // this.allBsvList=res

      this.ismetasv = true;
      let NewData = [];
      let newitem = [];
      let timeSet = new Set();
      res.forEach((item) => {
        item.blockTime = item.time;
        if (this.membsvtx.indexOf(item.txid) < 0) {
          this.membsvtx.push(item.txid);
          newitem.push(item);
        }
      });
      if (isnew) {
        this.realBsvList.unshift(...newitem);
      } else {
        this.realBsvList.push(...res);
      }
      let data = [...this.realBsvList];
      data = data.sort((a, b) => {
        return b.blockTime - a.blockTime;
      });
      for (let item of data) {
        let time = this.$moment(Number(item.blockTime)).format("YYYY-MM-DD");
        if (!timeSet.has(time)) {
          timeSet.add(time);
          NewData.push({
            createTime: this.$moment(Number(item.blockTime)).format(
              "YYYY-MM-DD"
            ),
            list: [],
            timeNub: this.$moment(time).valueOf(),
          });
        }
        item.txId = item.txid;
        item.Value = item.income - item.outcome;
        item.Value > 0 ? (item.type = true) : (item.type = false);
        for (let j = 0; j < NewData.length; j++) {
          if (
            Number(item.blockTime) !== 0 &&
            NewData[j].createTime ===
              this.$moment(Number(item.blockTime)).format("YYYY-MM-DD")
          ) {
            item.time = this.$moment(Number(item.blockTime)).format("MM-DD");
            NewData[j].list.push(item);
          }
        }
      }
      let realBsvList = [];
      for (let ndata of NewData) {
        realBsvList.push({
          type: "date",
          value: ndata.createTime,
          key: ndata.createTime,
        });
        for (let item of ndata.list) {
          realBsvList.push({ ...item, key: item.txid });
        }
      }
      this.$nextTick(() => {
        this.tokenRecordBsv = realBsvList;
        setTimeout(() => {
          this.recordListScroll();
        }, 500);
      });
      if (res.length > 0) {
        if (!isnew) {
          this.flag = res[res.length - 1].flag;
        }
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.getBSVList();
    this.$nextTick(() => {
      this.bsvListTimer = setInterval(() => {
        if (!this.$store.state.isBusying) {
          if (this.walletSelect === "BSV") {
            this.getBSVList(true);
          } else {
            this.getTokenHistory(true);
          }
        }
      }, 10000);
    });
  },

  destroyed() {
    // clearInterval(this.tokenListTimer)
    clearInterval(this.bsvListTimer);
  },
};
</script>

<style lang="scss" scoped>
.record {
  width: 100%;
  max-width: 563px;
  background: #606266;
  min-height: 375px;
  border-radius: 23px;
  margin-top: 45px;
  padding: 30px;
  padding-right: 20px;
  padding-top: 15px;
}
.record_top {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.noTokenList {
  color: #fff;
  font-size: 26px;
  text-align: center;
  margin-top: 30px;
  height: 20rem;
  line-height: 18rem;
}
::v-deep .el-input__inner {
  background: transparent;
  border: none;
  color: #fff;
  width: 135px;
  text-align: center;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #eab300;
}
.is_loading {
  padding: 15px;
  background: #fff;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  height: 70px;
  color: #fff;
  border-radius: 11px;
}
.recordList {
  max-height: 1000px;
  overflow: auto;
  padding-right: 10px;
  position: relative;
  .noTokenRecord {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #ffffff;
  }
  .foot {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .data {
      color: #303133;
      font-size: 13px !important;
      font-family: PingFangSC-Semibold, sans-serif !important;
      font-weight: 500;
    }
    .tx {
      text-align: center;
      width: 38px;
      height: 23px;
      line-height: 23px;
      background: rgba(220, 235, 252, 1);
      border-radius: 3px;
      color: #157bee;
      cursor: pointer;
      font-weight: bold;
      a {
        color: #157bee;
      }
    }
  }
  .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 31.5px;
        height: 31.5px;
        object-fit: cover;
        // border-radius: 21px;
        margin-right: 7.5px;
      }
      span {
        color: #303133;
        font-size: 14px !important;
      }
    }
  }
  .bsvContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .data {
      color: #303133;
      font-size: 13px !important;
      font-family: PingFangSC-Semibold, sans-serif !important;
      font-weight: 500;
    }
    .tx {
      text-align: center;
      width: 38px;
      height: 23px;
      line-height: 23px;
      background: rgba(220, 235, 252, 1);
      border-radius: 3px;
      color: #157bee;
      cursor: pointer;
      font-weight: bold;
      a {
        color: #157bee;
      }
    }
  }
  &.metasvList {
    .is_loading {
      padding: 15px;
      background: #fff;
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      height: 70px;
      color: #fff;
      border-radius: 11px;
    }
    .item_B .item,
    .item_R .item {
      position: relative;
      border-bottom: none;
      padding-bottom: 0;
    }
    .tx {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 38px;
      height: 23px;
      line-height: 23px;
      background: rgba(220, 235, 252, 1);
      border-radius: 3px;
      color: #157bee;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .items_time {
    color: #fff;
    margin: 25px 15px 10px;
    font-size: 12px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #606266;
    border-radius: 10px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    box-shadow: block;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
  > h2 {
    padding-left: 15px;
    font-size: 12px;
    color: #fff;
    margin-top: 20px;
    font-weight: normal;
  }
  .item_R,
  .item_B {
    padding: 15px;
    background: #fff;
    border-radius: 11px;
    margin-top: 10px;
    transition: 0.2s all ease-in-out;
    animation: mymove 2s 1;
    .item {
      border-bottom: 1px solid #ffe3df;
      padding-bottom: 25px;
      display: flex;
      justify-content: space-between;
      h5 {
        display: flex;
        height: 20px;
        align-items: center;
        i {
          width: 3px;
          height: 12px;
          border-radius: 5px;
          background: #fd4426;
        }
        u {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      p {
        color: #fd4426;
        font-size: 24px;
        font-weight: bold;
      }
      s {
        font-size: 12px;
        font-weight: bold;
        color: #fd4426;
      }
      span {
        color: #303133;
        font-size: 13px !important;
        font-family: PingFangSC-Semibold, sans-serif !important;
        font-weight: Semibold !important;
      }
    }
    h4 {
      color: #606266;
      margin-top: 10px;
      font-weight: normal;
      transition: 0.2s all ease-in-out;
    }
    .BSVh4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .BSVaddress {
        width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        white-space: nowrap;
        align-items: center;
        display: flex;
        > span {
          width: 35px;
        }
        > div {
          img {
            margin-left: 0;
          }
        }
        .bsv_user {
          display: flex;
          align-items: center;
        }
        img {
          width: 23px;
          height: 23px;
          margin-left: 10px;
          object-fit: contain;
          margin-right: 10px;
          border-radius: 50%;
        }
      }
      .tx {
        text-align: center;
        width: 38px;
        height: 23px;
        line-height: 23px;
        background: rgba(220, 235, 252, 1);
        border-radius: 3px;
        color: #157bee;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .show_data {
      padding: 0 30px;
      transition: 0.2s all ease-in-out;
      .time {
        min-height: 60px;
        border-bottom: 1px solid #ffe3df;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6 {
          font-size: 14px;
          color: #303133;
        }
        .tx {
          text-align: center;
          width: 38px;
          height: 23px;
          line-height: 23px;
          background: rgba(220, 235, 252, 1);
          border-radius: 3px;
          color: #157bee;
          cursor: pointer;
          font-weight: bold;
        }
      }
      .icon-icon-arrow-top2 {
        text-align: center;
        display: block;
        color: #bfc2cc;
        margin: 20px 0;
        cursor: pointer;
      }
    }
    .from,
    .to {
      min-height: 60px;
      border-bottom: 1px solid #ffe3df;
      display: flex;
      flex-wrap: wrap;
      h6 {
        width: 45px;
        font-size: 14px;
        line-height: 60px;
        color: #909399;
      }
      > div {
        width: calc(100% - 45px);
      }
      .data {
        display: flex;
        line-height: 60px;
        justify-content: space-between;
        align-items: center;
      }
      p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 23px;
          height: 23px;
          margin-left: 10px;
          object-fit: cover;
          margin-right: 10px;
          border-radius: 50%;
        }
        s {
          max-width: 120px;
          width: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          color: #303133;
        }
      }
      span {
        color: #fd4426;
        font-size: 14px;
      }
    }
    .to {
      line-height: 60px;
      .data {
        span {
          color: #157bee;
        }
      }
    }
  }
  .item_B {
    .item {
      border-bottom: 1px solid #dcebfc;
      h5 {
        i {
          background: #157bee;
        }
        img {
          display: inline-block;
          width: 31px;
          height: 31px;
          border-radius: 16px;
          margin-right: 8px;
        }
        span {
          display: inline-block;
          font-size: 12px;
          color: #303133;
          height: 14px;
          line-height: 14px;
        }
      }
      p {
        color: #157bee;
      }
      s {
        color: #157bee;
      }
      span {
        color: #303133;
        font-size: 14px;
        font-family: PingFangSC-Semibold, sans-serif;
        font-weight: Semibold;
      }
    }
    .show_data .time {
      border-bottom: 1px solid #dcebfc;
    }
    .from,
    .to {
      border-bottom: 1px solid #dcebfc;
    }
  }
  .item_0 {
    .item {
      h5 {
        i {
          background: #606266;
        }
      }
      p {
        color: #606266;
      }
      s {
        color: #606266;
      }
      span {
        color: #606266;
      }
    }
  }
}
.el-select-dropdown__item.selected {
  color: #eab300;
}
@media only screen and (max-width: 768px) {
  .record {
    padding: 15px;
    margin: 45px auto;
  }
  .recordList {
    .item_R {
      .from,
      .to {
        > div {
          width: 100%;
        }
      }
    }
    .item_B .item,
    .item_R .item {
      p {
        color: #fd4426;
        font-size: 18px;
        font-weight: bold;
        text-align: right;
      }
      s {
        font-size: 12px;
        font-weight: bold;
        color: #fd4426;
      }
      .itemContainer {
        .left {
          width: 50%;
          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            // border-radius: 21px;
            margin-right: 5px;
          }
          span {
            font-size: 12px !important;
          }
        }
      }
    }
    .item_B {
      .item {
        p {
          color: #157bee;
        }
        s {
          color: #157bee;
        }
      }
    }
  }
  .recordList .item_R .from h6,
  .recordList .item_R .to h6,
  .recordList .item_B .from h6,
  .recordList .item_B .to h6 {
    line-height: 32px;
  }
  .recordList .item_R .from .data,
  .recordList .item_R .to .data,
  .recordList .item_B .from .data,
  .recordList .item_B .to .data {
    line-height: 32px;
    flex-wrap: wrap;
  }
  .recordList .item_R .from p,
  .recordList .item_R .to p,
  .recordList .item_B .from p,
  .recordList .item_B .to p {
    width: 100%;
  }
  .recordList .item_R .from p s,
  .recordList .item_R .to p s,
  .recordList .item_B .from p s,
  .recordList .item_B .to p s {
    max-width: 80%;
  }
  .recordList .item_R .from span,
  .recordList .item_R .to span,
  .recordList .item_B .from span,
  .recordList .item_B .to span {
    width: 100%;
    text-align: right;
  }
  .recordList .item_R .show_data .icon-icon-arrow-top2,
  .recordList .item_B .show_data .icon-icon-arrow-top2 {
    margin: 10px 0;
  }
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
::v-deep .el-loading-mask {
  background-color: rgba($color: #606266, $alpha: 0.9);
}
::v-deep .el-loading-spinner {
  top: 0;
  margin-top: 0;
}
.metaShow {
  margin-top: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  > span {
    margin-right: 5px;
    font-size: 12px;
  }
}
@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
