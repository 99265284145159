var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index_box" },
    [
      _c(
        "el-row",
        { staticClass: "index" },
        [
          _c(
            "el-col",
            { staticClass: "wallet_box", attrs: { xs: 24, sm: 11 } },
            [
              _c("h2", [
                _c("span", [_c("i")]),
                _vm._v("\n        " + _vm._s(_vm.$t("myWallet")) + "\n      "),
              ]),
              _c("bsvBox"),
              _c("h2", { staticClass: "nfttitle" }, [
                _c("span", [_c("i")]),
                _vm._v("\n        " + _vm._s(_vm.$t("myNFT")) + "\n      "),
              ]),
              _c("nftBox"),
              _vm._l(_vm.myTokenList, function (item, index) {
                return _c("addWalletBox", {
                  key: index,
                  attrs: { boxData: item, boxIndex: index },
                })
              }),
            ],
            2
          ),
          _c(
            "el-col",
            { staticClass: "record_box", attrs: { xs: 24, sm: 11 } },
            [
              _c("h2", [
                _c("span", [_c("i")]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("transactionRecord")) +
                    "\n      "
                ),
              ]),
              _c("record"),
            ],
            1
          ),
        ],
        1
      ),
      _c("span", { staticStyle: { display: "none" } }, [_vm._v("v1.0.6")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }