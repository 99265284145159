<template>
  <div class="wallet">
    <div class="wallet_warp" style="margin-top: 0" ref="wallet_warpBsv">
      <div class="wallet_receive" :class="boxData.symbol ? boxData.symbol : ''">
        <div class="receive">
          <h4>
            <!-- 你的收款地址 -->
            {{ $t("receivablesAddress") }}
          </h4>
          <img :src="receiveEVM" alt />
          <p>{{ myAddress }}</p>
          <button @click="copyAdd()">
            <!-- 复制地址 -->
            {{ $t("copyAddress") }}
          </button>
        </div>
        <i
          class="iconfont icon-icon-arrow-left2"
          @click="transitionBoxBsv('-100%')"
        >
        </i>
      </div>
      <div class="wallet_data">
        <div class="wallet_show" :class="boxData.symbol ? boxData.symbol : ''">
          <div
            class="wallet_text"
            :style="{
              background:
                'url(https://showjob.oss-cn-hangzhou.aliyuncs.com/showMoney/ic_' +
                boxData.symbol +
                '%402x.png) no-repeat',
              backgroundSize: '98px 103px',
            }"
            v-if="amountShow"
          >
            <div>
              <h4>
                {{
                  $utils.getAmount(
                    (boxData.balance.amount / SATS) * Number(boxData.toBSV),
                    4
                  )
                }}
                {{ $utils.getCurrency() }}
              </h4>
              <p>
                {{ cutNumberNoZero(boxData.balance.amount / SATS, 8) }}
                {{ boxData.symbol }}
              </p>
            </div>
            <div class="currency">
              <span
                >{{ boxData.symbol }} <s>({{ boxData.cardName }})</s></span
              >
              <span class="iconfont_box" @click="operationShow = true"
                ><i
                  class="iconfont icon-shangxiayidongjiantou"
                  :class="boxData.symbol ? boxData.symbol : ''"
                ></i
              ></span>
            </div>
          </div>
        </div>
        <div class="wallet_btn">
          <button @click="transitionBoxBsv(0)">
            <i class="iconfont icon-jia2"></i>
            <span>
              <!-- 收款 -->
              {{ $t("receivables") }}
            </span>
          </button>
          <button @click="transitionBoxBsv('-200%')">
            <i class="iconfont icon-youjiantou"></i>
            <span>
              <!-- 转账 -->
              {{ $t("Transfer") }}
            </span>
          </button>
        </div>
        <!-- <p>
          1 {{ boxData.symbol }} ≈
          {{ $utils.getAmount(Number(boxData.toBSV), 4) }}
          {{ $utils.getCurrency() }}
        </p> -->
      </div>
      <div class="wallet_receive" :class="boxData.symbol ? boxData.symbol : ''">
        <i class="iconfont icon-you" @click="transitionBoxBsv('-100%')"></i>
        <div class="receive">
          <h4>
            <!-- 转账 -->
            {{ $t("Transfer") }}
          </h4>
          <div class="transfer">
            <div class="add" :class="{ addShow: addShow }">
              <input
                type="text"
                v-model="add"
                :placeholder="$t('PleaseAddress')"
                @focus="addShow = true"
                @blur="addShow = false"
              />
            </div>
            <div class="money" :class="{ addShow: moneyShow }">
              <input
                type="number"
                v-enter-number2
                v-model="money"
                :placeholder="$t('TransferAmount')"
                @focus="moneyShow = true"
                @blur="
                  moneyShow = false;
                  setAmount();
                "
              />
              <el-select class="rewardSelect" v-model="rewardSelect">
                <el-option
                  v-for="(item, index) in rewardOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- <span v-if="Number(money) && rewardSelect !== this.boxData.symbol">
              {{ money ? money : 0 }} {{ rewardSelect }} =
              {{ cutNumberNoZero(amount, 9) }}{{ this.boxData.symbol }}
              <br />
              ≈ {{ $utils.getAmount(amount * Number(boxData.toBSV), 4) }}
              {{ $utils.getCurrency() }}
            </span> -->
            <button @click="submitPay()" v-loading="submitShow">转账</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('ConfirmTransfer')"
      style="margin-top: 15vh"
      :visible.sync="payShow"
      class="centerDialogVisible"
      center
    >
      <div class="payShow">
        <!-- 登录后才可以进行此操作 -->
        <h4>
          <!-- 金额: -->
          {{ $t("amount") }}
        </h4>
        <p>
          <span>
            {{ cutNumberNoZero(amount, 9) }}{{ this.boxData.symbol }}
          </span>
          <!-- <s>
            ≈ {{ $utils.getAmount(amount * Number(boxData.toBSV), 4) }}
            {{ $utils.getCurrency() }}
          </s> -->
        </p>
        <h4>
          <!-- 转账地址 -->
          {{ $t("transferAddress") }}
        </h4>
        <p>
          {{ add }}
        </p>
        <h4>
          <!-- 矿工费 -->
          {{ $t("minerFee") }}
        </h4>
        <span> {{ fee }} {{ this.boxData.symbol }} </span>
      </div>
      <div class="queding">
        <button @click="payShow = false">
          <!-- 关闭 -->
          {{ $t("close") }}
        </button>
        <button
          class="pay"
          @click="goPay()"
          v-loading="transactionShow"
          :disabled="transactionShow"
        >
          <!-- 确定 -->
          {{ $t("determine") }}
        </button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('Transfer')"
      style="margin-top: 15vh"
      :visible.sync="success"
      class="centerDialogVisible"
      center
    >
      <h2 class="success">
        <!-- 转账成功 -->
        {{ $t("successfulTransfer") }}
      </h2>
      <div
        class="queding"
        @click="
          success = false;
          add = '';
          money = 0;
        "
      >
        <!-- 确定 -->
        {{ $t("determine") }}
      </div>
    </el-dialog>
    <div
      class="currency-dialong"
      v-if="operationShow"
      @click="operationShow = false"
    >
      <div class="currency_warp">
        <div class="currency_box" @click.stop="operationShow = true">
          <div class="icon">
            <i class="iconfont icon-x" @click.stop="operationShow = false"></i>
          </div>
          <div class="currency_top">
            <h4 :class="h4Show === 1 ? 'h4Show' : ''" @click="h4Show = 1">
              <i></i>
              <span>
                <!-- 充值 -->
                {{ boxData.symbol }} {{ $t("receiveMoney") }}
              </span>
            </h4>
            <h4 :class="h4Show === 2 ? 'h4Show' : ''" @click="h4Show = 2">
              <i></i>
              <span>
                <!-- 提现 -->
                {{ boxData.symbol }} {{ $t("withdraw") }}
              </span>
            </h4>
            <h4
              :class="h4Show === 3 ? 'h4Show' : ''"
              @click="
                h4Show = 3;
                getTokenHistory();
              "
            >
              <i></i>
              <span>
                <!-- 充提记录 -->
                {{ $t("receivRecord") }}
              </span>
            </h4>
          </div>
          <div class="currency_body">
            <div class="recharge" v-if="h4Show === 1">
              <img :src="rechargeImg" alt="" />
              <p>{{ rechargeAddress }}</p>
              <button
                @click="
                  $copyText(rechargeAddress);
                  $message({ type: 'success', message: $t('coypSuccess') });
                "
              >
                <!-- 复制地址 -->
                {{ $t("coypSuccess") }}
              </button>
              <div class="recharge_text">
                <p>
                  <!-- 温馨提示 -->
                  {{ $t("reminder") }}
                </p>
                <p>
                  <!-- • 请勿向上述地址充值任何非 {{boxData.symbol}} 资产，否则资产将不可找回。 -->
                  {{ $t("reminderTips1").replace("BTC", boxData.symbol) }}
                </p>
                <p>
                  <!-- • 您充值至上述地址后，需要整个网络节点的确认，1次网络确认后到账，2次网络确认后可提币。 -->
                  {{ $t("reminderTips2") }}
                </p>
                <p>
                  <!-- • 最小充值金额：0.001 {{boxData.symbol}}，小于最小金额的充值将不会上账且无法退回。 -->
                  {{ $t("reminderTips3").replace("BTC", boxData.symbol) }}
                </p>
                <p>
                  <!-- • 您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。 -->
                  {{ $t("reminderTips4") }}
                </p>
                <p>
                  <!-- • 请务必确认电脑及浏览器安全，防止信息被篡改或泄露。 -->
                  {{ $t("reminderTips5") }}
                </p>
              </div>
            </div>
            <div class="extraction" v-if="h4Show === 2">
              <div>
                <div
                  class="input_box"
                  :class="{
                    borderR: extractionAddressShow === 3,
                    borderY: extractionAddressShow === 2,
                  }"
                >
                  <span>
                    <!-- 提现地址 -->
                    {{ $t("withdrawAddress") }}
                  </span>
                  <input
                    type="text"
                    v-model="extractionAddress"
                    :placeholder="$t('inputAddress')"
                    @focus="extractionAddressShow = 2"
                    @blur="
                      extractionAddress
                        ? (extractionAddressShow = 1)
                        : (extractionAddressShow = 3)
                    "
                  />
                </div>
                <div
                  class="input_box"
                  :class="{
                    borderR: extractionAmountShow === 3,
                    borderY: extractionAmountShow === 2,
                  }"
                >
                  <span>
                    <!-- 提现金额 -->
                    {{ $t("withdrawAmount") }}
                  </span>
                  <input
                    type="number"
                    v-model="extractionAmount"
                    v-enter-number2
                    @focus="extractionAmountShow = 2"
                    @blur="
                      extractionAmount
                        ? (extractionAmountShow = 1)
                        : (extractionAmountShow = 3)
                    "
                    :placeholder="$t('TransferAmount')"
                  />
                </div>
                <!-- <p>
                  {{ extractionAmount ? extractionAmount : 0 }}
                  {{ boxData.symbol }} ≈
                  {{
                    $utils.getAmount(
                      extractionAmount * Number(boxData.toBSV),
                      4
                    )
                  }}
                  {{ $utils.getCurrency() }}
                </p> -->
              </div>
              <button
                :class="extractionAmount && extractionAddress ? 'buttonY' : ''"
                :disabled="extractionBShow"
                @click="extractionBTN()"
              >
                <!-- 确认转账 -->
                {{ $t("ConfirmTransfer") }}
              </button>
              <div class="extraction_text">
                <p>
                  <!-- 风险提示 -->
                  {{ $t("riskWarning") }}
                </p>
                <p>
                  <!-- 您的提币操作一旦完成，对应的资产所有权将由您变更为目标地址所对应的账户所有人享有，请您务必在提币操作前，仔细核对提币地址信息，确保提币属于自愿行为，并确认不涉及任何传销、非法集资、诈骗等违法情形，谨防上当受骗，避免造成不必要的财产损失。 -->
                  {{ $t("warningTips") }}
                </p>
              </div>
            </div>
            <div class="record" v-if="h4Show === 3">
              <div v-if="TokenHistory.length > 0">
                <div
                  class="record_item"
                  v-for="(item, index) in TokenHistory"
                  :key="index"
                >
                  <div>
                    <h2>
                      {{
                        item.type === "withdraw"
                          ? $t("withdraw")
                          : $t("receiveMoney")
                      }}
                    </h2>
                    <h2 :class="item.type === 'withdraw' ? 'h2red' : 'h2blue'">
                      {{ item.type === "withdraw" ? "-" : "+" }}
                      {{ cutNumberNoZero(+item.amount / bsvrate.SATS, 8) }}
                      {{ item.symbol }}
                    </h2>
                  </div>
                  <div>
                    <s>
                      {{ $moment(item.updateTime).format("YYYY-MM-DD HH:MM") }}
                    </s>
                    <!-- <s>
                      ≈{{
                        $utils.getAmount(
                          (+item.amount / bsvrate.SATS) * Number(boxData.toBSV),
                          4
                        )
                      }}
                      {{ $utils.getCurrency() }}
                    </s> -->
                  </div>
                </div>
              </div>
              <div class="noTokenHistory" v-if="TokenHistory.length === 0">
                <!-- 暂无记录 -->
                {{ $t("noRecord") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addUserAddress, sendRawTx } from "@/api/api";
import jrQrcode from "jr-qrcode";
export default {
  props: ["boxData", "boxIndex"],
  data() {
    return {
      receiveEVM: "",
      myAddress: "",
      // 转账
      add: "",
      addShow: false,
      money: undefined,
      moneyShow: false,
      payShow: false,
      transactionShow: false,
      submitShow: false,
      balance: {
        bsv: 0,
        satoshis: 0,
      },
      SATS: 100000000,
      moneyRate: "",
      bsvrate: {
        BITS: 1000000,
        SATS: 100000000,
      },
      fee: 0,
      rewardOptions: "",
      rewardSelect: "",
      timer: null,
      amount: 0,
      tx: "",
      success: false,
      walletToken: "",
      myTokenList: "",
      amountShow: false,
      operationShow: false,
      h4Show: 1,
      rechargeAddress: "",
      extractionAmount: "",
      extractionAmountShow: 1,
      extractionAddress: "",
      extractionAddressShow: 1,
      rechargeImg: "",
      extractionBShow: false,
      TokenHistory: "",
      balanceTime: "",
      TokenHistoryShow: false,
    };
  },
  mounted() {
    this.transitionBoxBsv = function (nub) {
      this.$refs.wallet_warpBsv.style.marginLeft = nub;
    };
    // console.log(this.$refs.wallet_warpBsv.style)
    this.transitionBoxBsv2 = function (nub, index) {
      this.$refs["wallet_warpBsv" + index][0].style.marginLeft = nub;
    };
  },
  methods: {
    getfocus(type) {
      this[type] = 2;
    },
    getBlur(type) {
      if (!this[type]) {
        this[type + "Show"] = 3;
      } else {
        this[type + "Show"] = 1;
      }
    },
    setAmount() {
      if (!this.money && this.money !== 0) {
        return;
      }
      if (this.money <= 0.0001 * this.bsvrate[this.rewardSelect]) {
        this.money = 0.0001 * this.bsvrate[this.rewardSelect];
        if (this.rewardSelect === "BSV") {
          this.$message({
            message: this.$t("unitTips1").replace("BSV", this.boxData.symbol),
            type: "warning",
          });
        }
        if (this.rewardSelect === "BITS") {
          this.$message({
            message: this.$t("unitTips2"),
            type: "warning",
          });
        }
        if (this.rewardSelect === "SATS") {
          this.$message({
            message: this.$t("unitTips3"),
            type: "warning",
          });
        }
      }
    },
    // 获取矿工费并且弹窗
    submitPay() {
      if (this.submitShow) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.submitShow = true;
      }
      // if (typeof this.money === '')
      if (this.add && this.money) {
        // this.balance = this.$utils.getBalance()
        // if (this.balance.bsv < this.amount) {
        //   this.submitShow = false
        //   return this.$message.error('可转账余额不足')
        // }
        let option = {
          address: this.add,
          amount: Math.ceil(this.amount * this.bsvrate.SATS),
          symbolId: this.boxData.symbolId,
          opReturn: process.env.VUE_APP_OPRETURN,
        };
        // console.log(option, 'option')
        this.$utils
          .getTokenFee(option)
          .then((res) => {
            // console.log(res, '获取res')
            this.fee = res.fee;
            this.fee = this.fee / this.bsvrate.SATS;
            this.tx = res.tx;
            this.submitShow = false;
            this.payShow = true;
          })
          .catch((err) => {
            this.submitShow = false;
            // console.log(err)
            return this.$message.error("VisionMoney:" + err);
          });
      } else {
        this.submitShow = false;
        return this.$message.error(this.$t("submitTips"));
      }
    },
    // 提现
    extractionBTN() {
      if (this.extractionBShow) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.extractionBShow = true;
        let walletService = this.$store.state.wallet;
        let that = this;
        walletService.tokenized
          .getUserTokenBalance({
            symbol: this.boxData.symbol,
            symbolId: this.boxData.symbolId,
            userAddress: this.$store.state.wallet.getTokenReceiveAddress(),
          })
          .then((res) => {
            // console.log(res)
            that.extractionBShow = false;
            if (
              res.result.amount <
              Number(that.extractionAmount) * that.bsvrate.SATS
            ) {
              that.extractionBShow = false;
              return that.$message.error(this.$t("withdrawTips"));
            }
            let option = {
              toAddress: that.extractionAddress,
              amount: Number(that.extractionAmount) * that.bsvrate.SATS,
            };
            // console.log(option, that.boxData)
            walletService
              .sendTokenToServer(option, that.boxData)
              .then((res) => {
                // console.log(res)
                if (res.code === 0) {
                  that.success = true;
                  that.extractionAddress = "";
                  that.extractionAmount = "";
                } else {
                  return that.$message.error(this.$t("Error") + res.error);
                }
              })
              .catch((err) => {
                // console.log(err)
                return this.$message.error(this.$t("Error") + err);
              });
          })
          .catch((err) => {
            return this.$message.error(this.$t("Error") + err);
          });
      }
    },
    // 查询余额
    getTokenBalance() {
      this.$store.state.wallet.tokenized
        .getUserTokenBalance({
          symbol: this.boxData.symbol,
          symbolId: this.boxData.symbolId,
          userAddress: this.$store.state.wallet.getTokenReceiveAddress(),
        })
        .then((res) => {
          // console.log(res, 456465)
          if (res.code === 200) {
            this.$forceUpdate();
            this.boxData.balance = res.result;
          } else {
            if (!navigator.onLine) return;
            if (res.msg) {
              return this.$message.error(this.$t("Error") + res.msg);
            }
          }
        })
        .catch((err) => {
          if (!navigator.onLine) return;
          return this.$message.error(this.$t("Error") + err);
        });
    },
    // 支付转账
    goPay() {
      if (this.transactionShow) {
        return this.$message.error(this.$t("discussWarning"));
      } else {
        this.transactionShow = true;
      }
      let walletService = this.$store.state.wallet;
      let that = this;
      // console.log(that.tx)
      walletService
        .send(that.tx.toString("hex"))
        .then((res) => {
          // console.log(res, '广播成功', this.$moment().format('YYYY-MM-DD HH:MM:SS'))
          if (res.code === 0) {
            sendRawTx(that.tx.toString("hex"));
            that.tx = "";
            that.payShow = false;
            // that.balance = that.$utils.getBalance()
            that.success = true;
            setTimeout(() => {
              that.transactionShow = false;
            }, 500);
          } else {
            setTimeout(() => {
              that.transactionShow = false;
            }, 500);
            that.payShow = false;
            that.tx = "";
            return that.$notify.error(this.$t("Error") + res.error);
          }
        })
        .catch((err) => {
          // console.log(err)
          setTimeout(() => {
            that.transactionShow = false;
          }, 500);
          return that.$message.error(this.$t("Error") + err.error);
        });
    },
    // 复制
    copyAdd() {
      this.$copyText(this.myAddress);
      this.$message({
        type: "success",
        message: this.$t("coypSuccess"),
      });
    },
    setAddUserAddress() {
      let userData = this.$store.state.userData;
      const wallet = this.$store.state.wallet;
      let params = {
        userType: this.$store.state.userData.phone ? "phone" : "email",
        address: wallet.getTokenReceiveAddress(),
        xpub: wallet.getXpub(),
        m: 0,
        n: 0,
      };
      params.userType === "phone"
        ? (params.phone = userData.phone)
        : (params.email = userData.email);
      addUserAddress(params).then();
    },
    // 将收款地址转二维码
    getEVM() {
      this.myAddress = this.$store.state.wallet.getTokenReceiveAddress();
      // console.log(this.$store.state.wallet.getReceiveAddress(), 'BSV')
      this.receiveEVM = jrQrcode.getQrBase64(this.myAddress);
    },
    // 充值地址
    getrechargeAddress() {
      // console.log(this.$store.state.wallet.getTokenChargeAddress(this.boxData.symbol), '充值地址')
      this.rechargeAddress = this.$store.state.wallet.getTokenChargeAddress(
        this.boxData.symbol
      );
      this.rechargeImg = jrQrcode.getQrBase64(this.rechargeAddress);
    },
    // 获取充提记录
    getTokenHistory() {
      // this.$store.state.wallet.getTokenHistory(this.boxData).then(res => {
      // console.log(res, '列表')
      //   this.TokenHistory = res
      // }).catch(err => {
      // console.log(err)
      // })
      this.TokenHistoryShow = true;
      this.$store.state.wallet
        .getRechargeAndWithdrawList(this.boxData.symbol)
        .then((res) => {
          // console.log(res, '列表')
          if (res.code === 0) {
            this.TokenHistory = res.data;
            this.TokenHistoryShow = false;
          } else {
            this.TokenHistoryShow = false;
            return this.$message.error(this.$t("Error") + res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.TokenHistoryShow = false;
          return this.$message.error(this.$t("Error") + err);
        });
    },
  },
  watch: {
    rewardSelect() {
      this.setAmount();
      if (this.money) {
        this.amount = Number(this.money) / this.bsvrate[this.rewardSelect];
      }
    },
    "$store.state.myTokenList"() {
      this.myTokenList = this.$store.state.myTokenList;
    },
    money() {
      // console.log(this.bsvrate)
      this.amount = Number(this.money) / this.bsvrate[this.rewardSelect];
    },
    "$store.state.balance": function () {
      this.balance = this.$store.state.balance;
      this.getEVM();
    },
    "$store.state.dealIn"() {
      // this.setWallet()
      this.getTokenBalance();
      // console.log('有钱进来了')
    },
    payShow() {
      if (this.tx && !this.payShow) {
        let walletService = this.$store.state.wallet;
        walletService.removeChangeUtxo(this.tx);
      }
    },
    // '$store.state.moneyRate': function () {
    //   this.rate = this.$store.state.moneyRate
    // }
  },
  destroyed() {
    clearInterval(this.balanceTime);
  },
  created() {
    // console.log(this.boxData, 1231231)
    this.getrechargeAddress();
    // this.getTokenHistory()
    this.rewardOptions = [
      {
        value: this.boxData.symbol,
        label: this.boxData.symbol,
      },
      {
        value: "BITS",
        label: "BITS",
      },
      {
        value: "SATS",
        label: "SATS",
      },
    ];
    this.bsvrate[this.boxData.symbol] = 1;
    this.rewardSelect = this.boxData.symbol;
    // this.balance = this.$utils.getBalance()
    setTimeout(() => {
      this.amountShow = true;
    }, 500);
    // console.log(this.$store.state.wallet, 45646546)
    this.getEVM();
    this.setAddUserAddress();
    this.balanceTime = setInterval(() => {
      if (navigator.onLine) {
        this.getTokenBalance();
      }
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  max-width: 405px;
  overflow: hidden;
  width: 100%;
  margin-top: 45px;
  border-radius: 23px;
  .wallet_warp {
    margin-top: 20px;
    height: 375px;
    width: 300%;
    display: flex;
    margin-left: -100%;
    transition: 0.3s all ease-in-out;
  }
  .wallet_data {
    padding: 30px;
    display: inline-block;
    max-width: 405px;
    height: 375px;
    width: calc(100% / 3);
    overflow: hidden;
    border-radius: 23px;
    background: rgba(255, 255, 255, 1);
    > p {
      margin-top: 24px;
      font-size: 12px;
      color: #606266;
      text-align: right;
    }
    .wallet_show {
      width: 100%;
      height: 188px;
      background: linear-gradient(
        -30deg,
        rgba(234, 179, 0, 1),
        rgba(234, 198, 0, 1)
      );
      border-radius: 11px;
    }
    .BTC {
      background: linear-gradient(
        -30deg,
        rgba(77, 77, 77, 1),
        rgba(142, 142, 142, 1)
      );
    }
    .BCH {
      background: linear-gradient(
        -30deg,
        rgba(50, 189, 83, 1),
        rgba(45, 184, 111, 1)
      );
    }
    .wallet_text {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      background: url("../../assets/images/ic_BSV@2x.png") no-repeat;
      background-size: 98px 103px;
      color: #fff;
      text-align: right;
      h4 {
        font-size: 36px;
        line-height: 1;
        font-weight: bold;
      }
      p {
        font-size: 16px;
      }
      span {
        font-size: 26px;
        font-family: "DIN";
        font-weight: bold;
      }
      .currency {
        display: flex;
        justify-content: space-between;
        .iconfont_box {
          width: 30px;
          height: 30px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-weight: bold;
          border-radius: 3px;
        }
        s {
          font-size: 16px;
          opacity: 0.6;
        }
        .BCH {
          color: #32bd53;
          background: unset;
        }
        .BTC {
          background: unset;
          color: #4d4d4d;
        }
      }
    }
    .wallet_btn {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .icon-jia2 {
        font-size: 20px;
      }
      button {
        width: 45%;
        font-size: 16px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeff0;
        border-radius: 9px;
        .icon-jia1 {
          font-size: 22px;
        }
        i {
          margin-right: 5px;
        }
        .icon-youjiantou {
          font-size: 22px;
        }
      }
    }
  }
  .wallet_receive {
    max-width: 405px;
    width: calc(100% / 3);
    display: inline-block;
    height: 375px;
    display: inline-flex;
    overflow: hidden;
    border-radius: 23px;
    background: #eab300;
    .receive {
      width: calc(100% - 60px);
      height: 100%;
      padding: 23px;
      background: #fff;
      border-radius: 23px;
      text-align: center;
      h4 {
        font-size: 26px;
      }
      img {
        width: 180px;
        height: 180px;
        margin: 15px 0;
      }
      p {
        font-size: 12px;
      }
      button {
        width: 120px;
        margin-top: 20px;
        height: 38px;
        background: rgba(234, 179, 0, 1);
        border-radius: 19px;
        color: #fff;
        line-height: 38px;
      }
    }
    i {
      color: #fff;
      line-height: 375px;
      width: 60px;
      text-align: center;
    }
  }
  .BTC {
    background: linear-gradient(
      -30deg,
      rgba(77, 77, 77, 1),
      rgba(142, 142, 142, 1)
    );
  }
  .BCH {
    background: linear-gradient(
      -30deg,
      rgba(50, 189, 83, 1),
      rgba(45, 184, 111, 1)
    );
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 50px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  input[type="number"] {
    appearance: textfield;
  }
  .yzm {
    width: unse t;
  }
  button {
    font-size: 15px;
    color: #f9f9f9;
    width: 96px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
  .yzmShow {
    background: #eab300;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
  span {
    color: #f56c6c;
  }
  input {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
}
.transfer {
  background: #fff;
  padding: 2rem;
  border-radius: 2rem;
  text-align: center;
  span {
    display: block;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    text-align: right;
    color: #909399;
  }
  button {
    min-width: 10.42rem;
    color: #fff;
    line-height: 3.75rem;
    height: 3.75rem;
    margin-top: 4rem;
    background: rgba(234, 179, 0, 1);
    border-radius: 2rem;
  }
  h6 {
    font-size: 1.4rem;
    font-weight: normal;
    color: #909399;
  }
  h4 {
    margin-top: 3.2rem;
    font-size: 3.4rem;
    color: #303133;
  }
}
.add,
.money {
  height: 5.21rem;
  border-bottom: 1px solid #f2f2f2;
  line-height: 5.21rem;
  transition: 0.3s all ease-in-out;
  text-align: left;
  input {
    border: none;
    outline: none;
    font-size: 1.4rem;
    height: 5rem;
    width: 100%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.money {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.addShow {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #eab300;
}
::v-deep .el-input__inner {
  border: none;
}
::v-deep .el-dialog {
  border-radius: 1rem;
  width: 50%;
  max-width: 450px;
  overflow: hidden;
  .el-dialog__body {
    padding: 0;
  }
}
.centerDialogVisible {
  ::v-deep .el-dialog__header {
    padding-top: 30px;
  }
  ::v-deep .el-dialog__title {
    font-weight: bold;
  }
  .centerDialog {
    padding: 0 2rem;
  }
  .payShow {
    display: block;
    margin: 0 auto;
    color: #303133;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: 0 2rem;
    border-radius: 2.1rem;
    h4 {
      font-size: 1.4rem;
      margin-top: 2rem;
      color: #bfc2cc;
      text-align: left;
      font-weight: normal;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p {
      margin-top: 1rem;
      display: flex;
      text-align: left;
      font-size: 1.3rem;
      justify-content: space-between;
      i {
        color: #bfc2cc;
      }
    }
    > span {
      display: block;
      margin-top: 1rem;
      font-size: 1.3rem;
      text-align: left;
    }
  }
  h2 {
    max-height: 25.5rem;
    padding: 0 2rem;
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: 500;
    overflow-x: auto;
    color: #909399;
  }
  .success {
    color: #303133;
    text-align: center;
  }
  h2::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 10px;
  }
  h2::-webkit-scrollbar-track {
    box-shadow: none;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  h2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
  .checked {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .queding {
    height: 5.2rem;
    line-height: 5.2rem;
    text-align: center;
    color: #303133;
    font-size: 1.7rem;
    margin-top: 3rem;
    font-weight: 500;
    border-top: 1px solid #e9e9e9;
    button {
      width: calc(50% - 1px);
      border-right: 1px solid #e9e9e9;
      line-height: 5.2rem;
      text-align: center;
      background: #fff;
      outline: none;
      color: #909399;
      cursor: pointer;
      font-size: 1.7rem;
      &:nth-last-child(1) {
        border-right: none;
      }
    }
    .pay {
      color: #303133;
      font-weight: bold;
    }
    .blue {
      color: rgb(42, 153, 246);
    }
  }
}
@media (max-width: 768px) {
  ::v-deep .el-dialog {
    width: 70%;
  }
}
.currency-dialong {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.2);
  .currency_warp {
    background: #fff;
    padding: 25px;
    border-radius: 20px;
  }
  .currency_box {
    width: 600px;
    max-height: 600px;
    overflow: auto;
    background: #fff;
    border-radius: 20px;
    padding-top: 15px;
  }
  .icon {
    text-align: right;
  }
  .icon-x {
    display: inline-block;
    cursor: pointer;
    font-size: 26px;
    margin: 0px 0 10px;
  }
  .currency_box::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 10px;
    display: none;
  }
  .currency_box::-webkit-scrollbar-track {
    box-shadow: none;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .currency_box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
  .currency_top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 50px;
    h4 {
      font-size: 26px;
      color: #bfc2cc;
      font-weight: normal;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        width: 10px;
        margin-right: 5px;
        height: 10px;
        border-radius: 50%;
      }
    }
    .h4Show {
      color: #303133;
      i {
        background: #eab300;
      }
    }
  }
  .currency_body {
    padding-top: 30px;
  }
  .recharge {
    img {
      width: 180px;
      height: 180px;
      display: block;
      margin: 0 auto;
    }
    p {
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
    }
    button {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
      width: 120px;
      height: 38px;
      background: rgba(234, 179, 0, 1);
      border-radius: 19px;
      text-align: center;
      color: #fff;
      line-height: 38px;
    }
    .recharge_text {
      width: 100%;
      margin-top: 10px;
      background: #f9f9f9;
      border-radius: 11px;
      padding: 25px 20px;
      p {
        line-height: 1.2;
        text-align: left;
        margin-top: 0;
      }
    }
  }
  .rewardSelect {
    ::v-deep .el-input__inner {
      text-align: right;
    }
  }
  .extraction {
    .input_box {
      margin: 0 75px;
      width: unset;
      display: block;
      span {
        width: 70px;
        color: #909399;
      }
      input {
        display: inline-block;
        width: calc(100% - 75px);
        color: #303133;
      }
    }
    p {
      margin: 0 75px;
      font-size: 14px;
      margin-top: 10px;
      text-align: right;
    }
    .borderR {
      transition: 0.3s all ease-in-out;
      border-bottom: 1px solid #f56c6c;
      span {
        color: #f56c6c;
      }
      input {
        &::-webkit-input-placeholder {
          color: #f56c6c;
        }
        &:-moz-placeholder {
          color: #f56c6c;
        }
        &::-moz-placeholder {
          color: #f56c6c;
        }
        &:-ms-input-placeholder {
          color: #f56c6c;
        }
      }
    }
    button {
      width: 120px;
      height: 38px;
      margin: 0 auto;
      margin-top: 35px;
      display: block;
      background: rgba(191, 194, 204, 1);
      border-radius: 19px;
      text-align: center;
      color: #fff;
      line-height: 38px;
      transition: 0.2s all ease-in-out;
    }
    .buttonY {
      transition: 0.2s all ease-in-out;
      background: #eab300;
    }
    .extraction_text {
      width: 100%;
      margin-top: 40px;
      background: #f9f9f9;
      border-radius: 11px;
      padding: 25px 20px;
      p {
        line-height: 1.2;
        text-align: left;
        margin: 0;
        margin-top: 0;
      }
    }
  }
  .record {
    padding: 0 50px;
    max-height: 400px;
    overflow: auto;
  }
  .record_item {
    background: rgba(249, 249, 249, 1);
    border-radius: 11px;
    margin-top: 10px;
    padding: 24px 30px;
    div {
      display: flex;
      justify-content: space-between;
      align-content: center;
      h2 {
        color: #303133;
        font-size: 21px;
        font-weight: normal;
      }
      .h2blue {
        color: #157bee;
      }
      .h2red {
        color: #fd4426;
      }
      s {
        color: #909399;
        font-size: 12px;
      }
    }
  }
  .record::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 10px;
    display: block;
  }
  .record::-webkit-scrollbar-track {
    box-shadow: block;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .record::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #aaa;
  }
}
.noTokenHistory {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 30px;
}
@media only screen and (max-width: 767px) {
  .wallet {
    margin: 0 auto;
    margin-top: 45px;
  }
  .wallet_box {
    padding: 10px;
  }
  .wallet_box .wallet_data {
    padding: 15px;
    border-radius: 15px;
    width: calc(100% / 3);
  }
  .wallet_box .wallet_receive {
    width: calc(100% / 3);
  }
  .currency-dialong {
    .currency_warp {
      width: 80%;
    }
    .currency_box {
      width: 100%;
      border-radius: 10px;
    }
    .currency_top {
      padding: 0;
      h4 {
        font-size: 14px;
        i {
          width: 5px;
          height: 5px;
        }
      }
    }
    .currency_top2 {
      display: block;
    }
  }
  .icon-x {
    margin-right: -10px;
  }
  .currency-dialong .extraction .input_box {
    margin: 0;
  }
  .currency-dialong .extraction p {
    margin: 0;
  }
  .currency-dialong .record {
    padding: 0;
  }
  .currency-dialong .record_item {
    padding: 20px 10px;
  }
  .currency-dialong .record_item div h2 {
    font-size: 14px;
  }
  .currency-dialong .record_item div s {
    font-size: 12px;
  }
}
</style>
