var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wallet" },
    [
      _c(
        "div",
        {
          ref: "wallet_warpBsv",
          staticClass: "wallet_warp",
          staticStyle: { "margin-top": "0" },
        },
        [
          _c("div", { staticClass: "wallet_receive" }, [
            _c("div", { staticClass: "receive" }, [
              _c("h4", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("receivablesAddress")) +
                    "\n        "
                ),
              ]),
              _c("img", { attrs: { src: _vm.receiveEVM, alt: "" } }),
              _c("p", [_vm._v(_vm._s(_vm.myAddress))]),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.copyAdd()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("copyAddress")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _c(
              "i",
              {
                staticClass: "iconfont icon-icon-arrow-left2",
                on: {
                  click: function ($event) {
                    return _vm.transitionBoxBsv("-100%")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  style: { fontSize: "20px", fontWeight: "bold" },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "wallet_data" }, [
            _c("div", { staticClass: "wallet_show" }, [
              _c(
                "div",
                {
                  staticClass: "wallet_text",
                  class: [_vm.$store.state.chain === "BSV" ? "bsv-bg" : ""],
                },
                [
                  _c("div", [
                    _c("h4", [
                      _vm._v(
                        "\n              --\n              " +
                          _vm._s(_vm.$utils.getCurrency()) +
                          "\n            "
                      ),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.balance.bsv) + " SPACE")]),
                  ]),
                  _c("div", { staticClass: "currency" }, [
                    _c("span", [_vm._v("MVC")]),
                    _c("s", [
                      _vm._v("(" + _vm._s(_vm.$store.state.BSVname) + ")"),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "wallet_btn" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.transitionBoxBsv(0)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-jia2" }),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("receivables")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.transitionBoxBsv("-200%")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-youjiantou" }),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("Transfer")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "wallet_receive" }, [
            _c(
              "i",
              {
                staticClass: "iconfont icon-you",
                on: {
                  click: function ($event) {
                    return _vm.transitionBoxBsv("-100%")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-right",
                  style: { fontSize: "20px", fontWeight: "bold" },
                }),
              ]
            ),
            _c("div", { staticClass: "receive" }, [
              _c("h4", [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("Transfer")) + "\n        "
                ),
              ]),
              _c("div", { staticClass: "transfer" }, [
                _c(
                  "div",
                  { staticClass: "add", class: { addShow: _vm.addShow } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.add,
                          expression: "add",
                        },
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("PleaseAddressBSV"),
                      },
                      domProps: { value: _vm.add },
                      on: {
                        focus: function ($event) {
                          _vm.addShow = true
                        },
                        blur: function ($event) {
                          _vm.addShow = false
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.add = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "money", class: { addShow: _vm.moneyShow } },
                  [
                    _c("input", {
                      directives: [
                        { name: "enter-number2", rawName: "v-enter-number2" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.money,
                          expression: "money",
                        },
                      ],
                      attrs: {
                        type: "number",
                        min: "0.0001",
                        placeholder: _vm.$t("TransferAmount"),
                      },
                      domProps: { value: _vm.money },
                      on: {
                        focus: function ($event) {
                          _vm.moneyShow = true
                        },
                        blur: function ($event) {
                          _vm.moneyShow = false
                          _vm.setAmount()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.money = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "el-select",
                      {
                        staticClass: "rewardSelect",
                        model: {
                          value: _vm.rewardSelect,
                          callback: function ($$v) {
                            _vm.rewardSelect = $$v
                          },
                          expression: "rewardSelect",
                        },
                      },
                      _vm._l(_vm.rewardOptions, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.submitShow,
                        expression: "submitShow",
                      },
                    ],
                    staticClass: "transfer_btn",
                    on: {
                      click: function ($event) {
                        return _vm.submitPay()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("Transfer")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "centerDialogVisible",
          staticStyle: { "margin-top": "15vh" },
          attrs: {
            title: _vm.$t("ConfirmTransfer"),
            visible: _vm.payShow,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.payShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "payShow" }, [
            _c("h4", [
              _vm._v("\n        " + _vm._s(_vm.$t("amount")) + ":\n      "),
            ]),
            _c("p", [
              _c("span", [
                _vm._v(" " + _vm._s(_vm._f("changeUnit")(_vm.amount)) + " "),
              ]),
            ]),
            _c("h4", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("transferAddress")) + "\n      "
              ),
            ]),
            _c("p", [_vm._v("\n        " + _vm._s(_vm.add) + "\n      ")]),
            _c("h4", [
              _vm._v("\n        " + _vm._s(_vm.$t("minerFee")) + "\n      "),
            ]),
            _c("span", [
              _vm._v(" " + _vm._s(_vm._f("changeUnit")(_vm.fee)) + " "),
            ]),
          ]),
          _c("div", { staticClass: "queding" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.payShow = false
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("close")) + "\n      ")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.transactionShow,
                    expression: "transactionShow",
                  },
                ],
                staticClass: "pay",
                on: {
                  click: function ($event) {
                    return _vm.goPay()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("determine")) + "\n      ")]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "centerDialogVisible",
          staticStyle: { "margin-top": "15vh" },
          attrs: {
            title: _vm.$t("Transfer"),
            visible: _vm.success,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.success = $event
            },
          },
        },
        [
          _c("h2", { staticClass: "success" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("successfulTransfer")) + "\n    "
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "queding",
              on: {
                click: function ($event) {
                  _vm.success = false
                  _vm.add = ""
                  _vm.money = 0
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("determine")) + "\n    ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }