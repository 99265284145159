var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "record_box" }, [
    _c("div", { staticClass: "record" }, [
      _c(
        "div",
        { staticClass: "record_top" },
        [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.test()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("selectWallet")) + "\n      ")]
          ),
          _c(
            "el-select",
            {
              staticClass: "rewardSelect",
              on: {
                change: function ($event) {
                  return _vm.walletChange(_vm.walletSelect)
                },
              },
              model: {
                value: _vm.walletSelect,
                callback: function ($$v) {
                  _vm.walletSelect = $$v
                },
                expression: "walletSelect",
              },
            },
            _vm._l(_vm.walletOptions, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: _vm.$t(item.label), value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tokenShow,
              expression: "tokenShow",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.walletSelect === "BSV",
              expression: "walletSelect === 'BSV'",
            },
          ],
          staticClass: "recordList",
        },
        [
          _vm._l(_vm.tokenRecordBsv, function (items, index) {
            return _c(
              "div",
              { key: items.key },
              [
                items.type === "date"
                  ? _c("p", { staticClass: "items_time" }, [
                      _vm._v(
                        "\n          " + _vm._s(items.value) + "\n        "
                      ),
                    ])
                  : _c("record-bsv-item", { attrs: { itemData: items } }),
              ],
              1
            )
          }),
          _vm.isLoading
            ? _c("div", { staticClass: "is_loading" }, [
                _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                }),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tokenShow,
              expression: "tokenShow",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.walletSelect === "myToken",
              expression: "walletSelect === 'myToken'",
            },
          ],
          staticClass: "recordList",
        },
        [
          JSON.stringify(_vm.newTokenRecordList) !== "{}"
            ? _vm._l(_vm.newTokenRecordList, function (items, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("p", { staticClass: "items_time" }, [
                      _vm._v(_vm._s(index)),
                    ]),
                    _vm._l(items, function (item) {
                      return _c("div", { key: item.txId }, [
                        _c(
                          "div",
                          {
                            class: [
                              item.to !== _vm.$store.state.userData.metaId
                                ? "item_R"
                                : "item_B",
                            ],
                          },
                          [
                            _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "itemContainer" }, [
                                _c("div", { staticClass: "left" }, [
                                  item.nodeName === "NftIssue" ||
                                  item.nodeName === "NftTransfer"
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm._f("nftImgUrl")(item.icon),
                                          alt: "",
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: _vm._f("ftImgUrl")(item),
                                          alt: "",
                                        },
                                      }),
                                  _c("span", [_vm._v(_vm._s(item.memo))]),
                                ]),
                                _c("div", { staticClass: "right" }, [
                                  _c("p", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          item.to !==
                                            _vm.$store.state.userData.metaId
                                            ? "-"
                                            : "+"
                                        ) +
                                        _vm._s(
                                          _vm._f("tofixed4")(
                                            _vm._f("cutZero")(item.amountStr)
                                          )
                                        ) +
                                        "\n                      "
                                    ),
                                    _c("s", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(item.symbol) +
                                          "\n                      "
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "foot" }, [
                              item.timestamp || item.time
                                ? _c("div", { staticClass: "data" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("data")) +
                                        " " +
                                        _vm._s(
                                          _vm
                                            .$moment(
                                              item.timestamp
                                                ? item.timestamp
                                                : item.time
                                            )
                                            .format("YYYY-MM-DD HH:mm:ss")
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : _c("div", { staticClass: "data" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("unconfirmed")) +
                                        "\n                "
                                    ),
                                  ]),
                              _c("div", { staticClass: "tx" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://" +
                                        _vm.isTestnet +
                                        "whatsonchain.com/tx/" +
                                        item.txId,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("#tx")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              })
            : _c("div", { staticClass: "noTokenRecord" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("noRecord")) + "\n      "),
              ]),
          _vm.isLoading
            ? _c("div", { staticClass: "is_loading" }, [
                _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                }),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tokenShow,
              expression: "tokenShow",
            },
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.haschild,
              expression: "!haschild",
            },
          ],
          staticClass: "noTokenList",
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("noRecord")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }